<template>
  <ul class="nav nav-admin justify-content-center">
    <li class="nav-item">
      <a class="nav-link" :class="('player' === $route.name) ? 'active' : ''" aria-current="page" href="player">{{$t('nav.nav_player.home')}}</a>
    </li>
    <li class="nav-item" v-if="-1 !== user.modules.indexOf('craftspeoples')">
      <a class="nav-link" :class="('player-craftspeople' === $route.name) ? 'active' : ''" href="player-craftspeople">{{$t('nav.nav_player.craftspeople')}}</a>
    </li>
    <li class="nav-item" v-if="-1 !== user.modules.indexOf('cabine') && false">
      <a class="nav-link inactive" :class="('player-cabine' === $route.name) ? 'active' : ''" href="player-cabine">{{$t('nav.nav_player.cabine')}}</a>
    </li>
    <li class="nav-item" v-if="-1 !== user.modules.indexOf('contact')">
      <a class="nav-link" :class="('player-contact' === $route.name) ? 'active' : ''" href="player-contact">{{$t('nav.nav_player.contact')}}</a>
    </li>
    <li class="nav-item" v-if="-1 !== user.modules.indexOf('products')">
      <a class="nav-link" :class="('player-products' === $route.name) ? 'active' : ''" href="player-products">{{$t('nav.nav_player.products')}}</a>
    </li>
    <li class="nav-item" v-if="-1 !== user.modules.indexOf('translates')">
      <a class="nav-link" :class="('player-translate' === $route.name) ? 'active' : ''" href="player-translate">{{$t('nav.nav_player.translates')}}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationPlayer',
  data() {
    return {
      user: this.$store.state.users.user
    }
  },
}
</script>

<style>
.nav-admin {
  margin-top: 20px;
  border-bottom: 2px solid #4091c9;
  padding-bottom: 10px;
}
.nav-admin .nav-link {
  color: dimgrey!important;
  text-transform: uppercase;
}
.nav-admin .nav-link.active {
  color: #4091c9!important;
}
.nav-admin .nav-link.inactive {
  color: red!important;
}
</style>
