<template>
  <div class="content container-fluid admin-university">
    <navigation-admin></navigation-admin>

    <div class="accordion accordion-flush" id="univeristyAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCategories" aria-expanded="false" aria-controls="flush-collapseCategories">
          Catégories
        </h2>
        <div id="flush-collapseCategories" class="accordion-collapse collapse" data-bs-parent="#univeristyAccordion">
          <div class="accordion-body">
            <university-categories></university-categories>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseWays" aria-expanded="false" aria-controls="flush-collapseWays">
          Voie d'études
        </h2>
        <div id="flush-collapseWays" class="accordion-collapse collapse" data-bs-parent="#univeristyAccordion">
          <div class="accordion-body">
            <university-ways></university-ways>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseLessons" aria-expanded="false" aria-controls="flush-collapseLessons">
          Matières
        </h2>
        <div id="flush-collapseLessons" class="accordion-collapse collapse" data-bs-parent="#univeristyAccordion">
          <div class="accordion-body">
            <university-lessons></university-lessons>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseBooks" aria-expanded="false" aria-controls="flush-collapseBooks">
          Livres
        </h2>
        <div id="flush-collapseBooks" class="accordion-collapse collapse" data-bs-parent="#univeristyAccordion">
          <div class="accordion-body">
            <university-books></university-books>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import UniversityWays from "@/Admin/University/components/UniversityWays";
import UniversityCategories from "@/Admin/University/components/UniversityCategories";
import UniversityLessons from "@/Admin/University/components/UniversityLessons";
import UniversityBooks from "@/Admin/University/components/UniversityBooks";

export default {
  name: 'UniversityView',
  components: {
    NavigationAdmin,
    UniversityBooks,
    UniversityCategories,
    UniversityLessons,
    UniversityWays
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-university {
  width: 95%!important;
}
.admin-university .accordion {
  margin-top: 20px;
}
.admin-university .accordion h2.accordion-header {
  color: #4091c9;
  text-transform: uppercase;
}
.admin-university .accordion h2.accordion-header.accordion-button,
.admin-university .accordion h2.accordion-header.accordion-button.collapsed {
  background-color: transparent;
}
</style>
