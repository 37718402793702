<template>
  <div class="content container-fluid admin">
    <navigation-player></navigation-player>

    <contact-list></contact-list>
  </div>
</template>

<script>
import NavigationPlayer from "@/Player/NavigationPlayer";
import ContactList from "@/Player/Contact/components/ContactList";

export default {
  name: 'ContactView',
  components: {
    NavigationPlayer,
    ContactList
  }
}
</script>
