import apiUser from "@/System/store/apiUser";

const actions = {
  getCategories: ({commit}) => {
    apiUser.get('/close-api/products/categories')
      .then(function (response) {
        commit('setCategories', response.data);
      });
  },
  getCraftspeoples: ({commit}) => {
    apiUser.get('/close-api/craftspeoples')
      .then(function (response) {
        commit('setCraftspeoples', response.data);
      });
  },
  searchProducts: ({commit}, payload) => {
    apiUser.post('/close-api/products/search', payload)
      .then(function (response) {
        commit('setProducts', response.data);
      });
  },
  addProduct: ({commit}, payload) => {
    apiUser.post('/close-api/products', payload)
      .then(function () {
        apiUser.get('/close-api/products')
          .then(function (response) {
            commit('setProducts', response.data);
          });
      });
  },
  editProduct: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      name: datas.name,
      plural: datas.plural,
      slug: datas.slug,
      category: datas.category,
      priceMin: datas.priceMin,
      priceMax: datas.priceMax,
      weight: datas.weight,
      icon: datas.icon,
      image: datas.image,
      wiki: datas.wiki,
      description: datas.description
    };

    apiUser.put('/close-api/product/'+uuid, payload)
      .then(function () {
        let payload = {
          name: datas.searchName,
          category: datas.searchCategory,
          craftspeople: datas.searchCraftspeople
        };
        apiUser.post('/close-api/products/search', payload)
          .then(function (response) {
            commit('setProducts', response.data);
          });
      });
  },
  deleteProduct: ({commit}, datas) => {
    apiUser.delete('/close-api/product/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/products')
          .then(function (response) {
            commit('setProducts', response.data);
          });
      });
  },
  publishProduct: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/product/'+uuid+'/publish', payload)
      .then(function () {
        let payload = {
          name: datas.name,
          category: datas.category,
          craftspeople: datas.craftspeople
        };
        apiUser.post('/close-api/products/search', payload)
          .then(function (response) {
            commit('setProducts', response.data);
          });
      });
  },
}

export default actions;
