<template>
  <div class="contact-list card">
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(contact, index) in contacts" :key="index" class="list-group-item">
          {{date(contact.dateOpen)}} - {{ $t(subjects(contact.subject)) }}
          <span class="actions">
            <font-awesome-icon icon="fa-solid fa-check-to-slot" :class="contact.status ? 'online' : 'offline'"/>&nbsp;&nbsp;&nbsp;
            <font-awesome-icon icon="fa-solid fa-eye" @click="see(contact)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalSeeContact" />
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal fade" id="modalSeeContact" tabindex="-1" aria-labelledby="modalForm" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{$t(subjects(details.subject))}}</h1>
        </div>
        <contact-details :details="details"></contact-details>
      </div>
    </div>
  </div>
</template>

<script>
import ContactDetails from "@/Player/Contact/components/element/ContactDetails";
import { mapState } from 'vuex'
import moment from "moment";

export default {
  name: "ContactList",
  components: {
    ContactDetails
  },
  computed: {
    ...mapState('adminContacts', {
        contacts: 'contacts'
      }
    )
  },
  data() {
    return {
      details: {},
    }
  },
  mounted() {
    this.$store.dispatch('adminContacts/getContacts');
  },
  methods: {
    see(contact) {
      this.details = contact;
    },
    date(date) {
      return moment(date).format("DD-MM-YY");
    },
    subjects(subject) {
      switch (subject) {
        case 'informations':
          return "contact.options.info"
        case 'suggest':
          return "contact.options.suggest"
        case 'fix':
          return "contact.options.fix"
        default:
          return "contact.options.other"
      }
    }
  }
};
</script>

<style>
.contact-list {
  margin-top: 20px;
  border-radius: 0!important;
  border: none!important;
}
.contact-list .list-group {
  border-radius: 0!important;
}
.contact-list .list-group .actions {
  float: right;
  color: #4091c9;
}
.contact-list .list-group .actions .modal-icon-action {
  cursor: pointer;
}
.contact-list .actions .online{
  color: #008000;
}
.contact-list .actions .offline {
  color: #ff0000;
}
#modalSeeContact .modal-header {
  padding: 5px
}
#modalSeeContact .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
