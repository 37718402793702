import { createApp } from 'vue'
import App from './App.vue'
import router from './System/router'
import store from './System/store'
import i18n from './i18n'
import Ckeditor from '@ckeditor/ckeditor5-vue';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import { faPersonDotsFromLine } from '@fortawesome/free-solid-svg-icons'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faChess } from '@fortawesome/free-solid-svg-icons'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { faCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

library.add(
  faPenToSquare,
  faSquarePlus,
  faTrashAlt,
  faPersonDigging,
  faPersonDotsFromLine,
  faCalculator,
  faBook,
  faChess,
  faScrewdriverWrench,
  faHandPointUp,
  faBriefcase,
  faEnvelope,
  faPowerOff,
  faCircleUp,
  faEye,
  faCheckToSlot,
  faLock,
  faLockOpen,
  faCircleCheck,
  faRightToBracket,
  faUserPlus,
  faRightFromBracket
);


createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(i18n)
  .use(store)
  .use(router)
  .use(Ckeditor)
  .mount('#app')
