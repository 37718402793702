<template>
  <div class="admin-home-deploy card">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormDeploy" />
      </span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(deploy, index) in deploys" :key="index" class="list-group-item">
          {{ deploy.title[$i18n.locale] }}
          <span class="actions">
              <font-awesome-icon icon="fa-solid fa-circle-up" :class="deploy.newPublish ? 'online' : 'offline'" @click="newPublish(!deploy.newPublish, deploy.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-solid fa-power-off" :class="deploy.publish ? 'online' : 'offline'" @click="publish(!deploy.publish, deploy.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', deploy)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormDeploy" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-trash-alt" @click="form('delete', deploy.uuid)" class="modal-icon-action" />
            </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal fade" id="modalFormDeploy" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ labelModal }}</h1>
        </div>
        <div class="modal-body">
          <deploy-form v-if="'add' === mode || 'edit' === mode" :deploy="deploy" :mode="mode"></deploy-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DeployForm from "@/Admin/Home/components/form/DeployForm";

export default {
  name: "HomeAdminDeploy",
  components: {
    DeployForm,
  },
  computed: {
    ...mapState('adminHome', {
        deploys: 'deploys'
      }
    )
  },
  data() {
    return {
      mode: null,
      labelModal: '',
      deploy: {},
      enabled: true,
      dragging: false
    }
  },
  mounted() {
    this.$store.dispatch('adminHome/getDeploys');
  },
  methods: {
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.deploy = {};
          this.labelModal = 'Ajouter un déploiement';
          break;
        case 'edit':
          this.deploy = datas;
          this.labelModal = 'Modifier un déploiement';
          break;
        case 'delete':
          this.$store.dispatch('adminHome/deleteDeploy', {
            uuid: datas,
          });
          break;
      }
    },
    publish(state, uuid) {
      this.$store.dispatch('adminHome/publishDeploy', {
        uuid: uuid,
        state: state,
      });
    },
    newPublish(state, uuid) {
      this.$store.dispatch('adminHome/newPublishDeploy', {
        uuid: uuid,
        state: state,
      });
    }
  }
};
</script>

<style>
.admin-home-deploy.card {
  border-radius: 0!important;
  border: none!important;
}
.admin-home-deploy .card-header {
  background-color: transparent;
}
.admin-home-deploy .card-header .actions {
  float: right;
  color: #4091c9;
}
.admin-home-deploy .list-group {
  border-radius: 0!important;
}
.admin-home-deploy .actions {
  float: right;
  color: #4091c9;
}
.admin-home-deploy .actions .modal-icon-action {
  cursor: pointer;
}
.admin-home-deploy .actions .modal-icon-action.online{
  color: #008000;
}
.admin-home-deploy .actions .modal-icon-action.offline {
  color: #ff0000;
}
#modalFormDeploy .modal-header {
  padding: 5px
}
#modalFormDeploy .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
