<template>
  <div class="sign-in-form col-6">
    <h2>{{$t('user.sign_in')}}</h2>

    <div>
      <p v-if="success" class="alert alert-success">{{$t('user.status.success')}}</p>
      <p v-if="error" class="alert alert-danger">{{$t('user.status.error')}}</p>
    </div>

    <form>
      <div class="form-group">
        <label>Identifiant</label>
        <input type="text" v-model="login" class="form-control" autocomplete="off">
      </div>
      <div class="form-group">
        <label>Mot de passe</label>
        <input type="password" v-model="password" class="form-control" autocomplete="off">
      </div>
      <div class="form-group">
        <label>Confirmation mot de passe</label>
        <input type="password" v-model="confirmPassword" class="form-control" autocomplete="off">
      </div>
      <input type="hidden" name="date" v-model="date">
    </form>
    <div class="button">
      <button @click="save();track();">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UserInscription',
  data() {
    return {
      login: null,
      password: null,
      confirmPassword: null,
      date: null,
      success: false,
      error: false,
    }
  },
  methods: {
    save() {
      let payload = {
        login: this.login,
        password: this.password,
        confirmPassword: this.confirmPassword,
        date: this.date
      };

      this.$store.dispatch('users/createAccount', payload);

      setTimeout(() => {
        this.login = null;
        this.password = null;
        this.confirmPassword = null;
        this.date = null;

        this.success = this.$store.state.users.success;
        this.error = this.$store.state.users.error;
      }, 1000);
    },
    track() {
      this.$store.dispatch('users/tracking', {
        page: 'open-users',
        component: 'inscription',
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  },
  watch: {
    success() {
      setTimeout(() => {
        this.$router.push('/')
      }, 2000);
    },
  }
}
</script>

<style>
.sign-in-form {
  margin-top: 100px!important;
  border-right: 1px solid #4091c9;
}
.sign-in-form h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}
.sign-in-form form {
  margin-top: 20px;
}
.sign-in-form .form-group label {
  display: block;
  text-align: center;
  font-weight: 500;
}
.sign-in-form input {
  text-align: center;
}
.sign-in-form .button {
  text-align: center;
}
.sign-in-form button {
  margin-top: 20px;
  width: 200px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
</style>
