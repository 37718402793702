<template>
  <div class="modal-body contact-detail">
    <div class="row">
      <div class="col-6">
        <p class="contact-label"><span>Nom : </span>{{contact.login}}</p>
        <p class="contact-label"><span>Réponse : </span>{{response(contact.modeResponse)}}</p>
        <p class="contact-label"><span>Répondu le : </span>{{date(contact.dateClose)}}</p>
      </div>
      <div class="col-6">
        <p class="contact-label"><span>Email : </span>{{contact.email}}</p>
        <p class="contact-label"><span>Date de réception : </span>{{date(contact.dateOpen)}}</p>
        <p class="contact-label">
          <span>Actions : </span>
          <font-awesome-icon v-if="false === contact.status" @click="close(!contact.status, contact.uuid)" icon="fa-solid fa-lock-open" class="open" />&nbsp;&nbsp;&nbsp;
          <font-awesome-icon v-if="true === contact.status" icon="fa-solid fa-lock" class="close" />
        </p>
      </div>
    </div>
    <hr>
    <div class="row">
      <p class="contact-label"><span>Message : </span></p>
      <div class="col-10 offset-1 contact-message">
        {{contact.message}}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from 'vuex'

export default {
  name: "ContactDetails",
  computed: {
    ...mapState('adminContacts', {
          stateContact: 'contact'
        }
    )
  },
  props: {
    details: Object
  },
  data() {
    return {
      contact: {}
    }
  },
  methods: {
    response(response) {
      switch (response) {
        case 'ig':
          return 'IG';
        case 'email':
          return 'Email';
        default:
          return 'Pas de réponse'
      }
    },
    date(date) {
      if(null === date) {
        return null;
      }
      return moment(date).format("DD-MM-YY");
    },
    close(state, uuid) {
      let payload = {
        uuid: uuid,
        state: state
      };
      this.$store.dispatch('adminContacts/closeContact', payload);
    }
  },
  watch: {
    details() {
      this.contact = this.details;
    },
    stateContact() {
      this.contact = this.stateContact;
    }
  }
};
</script>

<style>
.contact-detail .contact-label span {
  font-weight: 600;
}
.contact-detail .contact-message {
  margin-bottom: 20px;
}
.contact-detail .open {
  cursor: pointer;
  color: #ff0000;
}
.contact-detail .close {
  color: #008000;
}
</style>
