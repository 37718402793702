import api from "@/System/store/api";

const actions = {
  getActualities: ({commit}) => {
    api.get('/api/actualities/publish')
      .then(function (response) {
        commit('setActualities', response.data);
      });
  },
  getDeploys: ({commit}) => {
    api.get('/api/deploys/publish')
      .then(function (response) {
        commit('setDeploys', response.data);
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
