<template>
  <form class="user-form">
    <div class="row">
      <div class="col-6">
        <p class="user-label"><span>Nom : </span>{{user.login}}</p>
        <p class="user-label"><span>Note : </span>{{user.note}}</p>
      </div>
      <div class="col-6">
        <p class="user-label"><span>Créé le : </span>{{date(user.created)}}</p>
        <p class="user-label"><span>Supprimé le : </span>{{date(user.deleted)}}</p>
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-4">Note</span>
        <input type="text" v-model="note" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Role</span>
          <select v-model="role" class="form-control">
            <option value="ROLE_USER">user</option>
            <option value="ROLE_CONTRIBUTOR">contributeur</option>
            <option value="ROLE_ADMIN">admin</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Langues</span>
          <Multiselect v-model="languages" mode="tags" :options="optionsLanguages" :search="true" class="form-select"/>
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Modules</span>
          <Multiselect v-model="modules" mode="tags" :options="optionsModules" :search="true" class="form-select"/>
        </div>
      </div>
    </div>
  </form>
  <div class="faq-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from '@vueform/multiselect'

export default {
  name: 'UserForm',
  components: {
    Multiselect
  },
  props: {
    user: Object,
  },
  data() {
    return {
      optionsLanguages: {
        fr: 'Français (fr)',
        en: 'Anglais (en)',
        it: 'Italien (it)'
      },
      optionsModules: {
        cabine: 'cabine',
        craftspeoples: 'craftspeoples',
        products: 'products',
        translates: 'translates'
      },
      note: null,
      role: null,
      languages: null,
      modules: null,
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.note = undefined !== this.user.note ? this.user.note : null;
      this.role = undefined !== this.user.roles ? this.user.roles : null;
      this.languages = undefined !== this.user.languages ? this.user.languages : null;
      this.modules = undefined !== this.user.modules ? this.user.modules : null;
    },
    save() {
      this.$store.dispatch('adminUsers/editUser', {
        uuid: this.user.uuid,
        note: this.note,
        role: this.role,
        languages: 0 !== this.languages.length ? this.languages : null,
        modules: 0 !== this.modules.length ? this.modules : null,
      });
    },
    date(date) {
      if(null === date) {
        return null;
      }
      return moment(date).format("DD-MM-YY");
    },
  },
  watch: {
    user() {
      this.getDatas();
    }
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.user-form .user-label span {
  font-weight: 600;
}
.user-form .multiselect-tag {
  background-color: #4091c9;
}
.faq-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.faq-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
