<template>
  <form class="faq-form">
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Titre</span>
          <input type="text" v-model="title" class="form-control" id="name">
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Catégories</span>
          <select v-model="category" class="form-control">
            <option></option>
            <option v-for="(category, index) in categories" :key="index" :value="category.uuid">{{category.name[$i18n.locale]}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Prérequis</span>
      <ckeditor :editor="editor" v-model="content" :config="editorConfig" class="form-control col-10" id="prerequis" ></ckeditor>
    </div>
  </form>
  <div class="faq-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapState } from 'vuex'

export default {
  name: 'FaqForm',
  props: {
    faq: Object,
    mode: String,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      title: null,
      content: '',
      category: null,
    }
  },
  computed: {
    ...mapState('adminFaqs', {
          categories: 'categories'
        }
    )
  },
  mounted() {
    this.$store.dispatch('adminFaqs/getCategories');
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.title = undefined !== this.faq.title ? this.faq.title[this.$i18n.locale] : null;
      this.content = undefined !== this.faq.content && null !== this.faq.content ? this.faq.content[this.$i18n.locale] : '';
      this.category = undefined !== this.faq.category ? this.faq.category.uuid : null;
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminFaqs/addFaq', {
          title: this.title,
          content: this.content,
          category: this.category,
        });
      } else {
        this.$store.dispatch('adminFaqs/editFaq', {
          uuid: this.faq.uuid,
          title: this.title,
          content: this.content,
          category: this.category,
        });
      }
    }
  },
  watch: {
    faq() {
      this.getDatas();
    }
  },
}
</script>
<style>
.faq-form .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.faq-form .ck-editor__editable {
  min-height: 300px;
}
.faq-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.faq-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
