const mutations = {
  setContacts: function (state, contacts) {
    state.contacts = contacts;
  },
  setContact: function (state, contact) {
    state.contact = contact;
  },
}

export default mutations;

