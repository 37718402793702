<template>
  ACCOUNT
</template>

<script>
export default {
  name: 'AccountView',
}
</script>

<style>

</style>
