<template>
  <div class="card home-deploy">
    <div class="card-header">
      {{ $t('home.deploy') }}
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(deploy, index) in deploys" :key="index" @click="load(deploy)" class="list-group-item" data-bs-toggle="modal" data-bs-target="#deployModal">
          <span :class="deploy.newPublish ? 'date-new' : ''">{{date(deploy.created)}}</span> - {{ deploy.title[$i18n.locale] }}
          <font-awesome-icon icon="fa-solid fa-hand-point-up" class="modal-icon-action"/>
        </li>
      </ul>
    </div>
    <div class="modal fade" id="deployModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="lessonModalLabel">{{deploy.title?.[$i18n.locale]}}</h1>
          </div>
          <div class="modal-body" v-html="deploy.content?.[$i18n.locale]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'HomeDeploy',
  computed: {
    ...mapState('home', {
          deploys: 'deploys'
        }
    )
  },
  data() {
    return {
      deploy: {}
    }
  },
  mounted() {
    this.$store.dispatch('home/getDeploys');
  },
  methods: {
    load(deploy) {
      this.deploy = deploy;
      this.$store.dispatch('home/tracking', {
        page: 'open-home',
        component: 'deploy|uuid:'+deploy.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    },
    date(date) {
      return moment(date).format("DD-MM-YY");
    }
  }
}

</script>

<style>
.home-deploy.card {
  border: none;
}
.home-deploy .card-header {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
}
.home-deploy .card-body {
  text-align: justify;
}
.home-deploy .card-body .list-group {
  cursor: pointer
}
.home-deploy .card-body .list-group .date-new {
  color: #4091c9;
}
.home-deploy .card-body .list-group .modal-icon-action {
  float: right;
  color: #4091c9;
}
.home-deploy .modal-header {
  padding: 5px
}
.home-deploy .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
.home-deploy .modal-body a {
  text-decoration: underline;
  color: #4091c9;
}
</style>
