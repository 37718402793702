<template>
  <select v-model="selected">
    <option v-for="n in arrayOfValues(min, max)" :key="n" :value="n">{{ n }}</option>
  </select>
</template>

<script>

export default {
  name: 'SelectMinMax',
  props: {
    min: Number,
    max: Number
  },
  data() {
    return {
      selected: 0,
    }
  },
  mounted() {
    this.selected = this.min;
  },
  methods: {
    arrayOfValues(min, max) {
      let values = [];
      for (let i = min; i <= max; i++) {
        values.push(i);
      }
      return values;
    },
  },
  watch: {
    min() {
      this.selected = this.min;
    }
  }
}

</script>

<style>
</style>
