<template>
  <div class="content container-fluid admin">
    <navigation-player></navigation-player>

    Vêtements - 3D

  </div>
</template>

<script>
import NavigationPlayer from "@/Player/NavigationPlayer";

export default {
  name: 'CabineView',
  components: {
    NavigationPlayer
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
