<template>
  <div class="content container-fluid admin-cabine">
    <navigation-admin></navigation-admin>

    Vêtements - 3D
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";

export default {
  name: 'CabineView',
  components: {
    NavigationAdmin
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-cabine {
  width: 95%;
}
</style>
