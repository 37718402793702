const mutations = {
  setAuthors: function (state, authors) {
    state.authors = authors;
  },
  setBooks: function (state, books) {
    state.books = books;
  },
  setCategories: function (state, categories) {
    state.categories = categories;
  },
  setLanguages: function (state, languages) {
    state.languages = languages;
  },
  setLessons: function (state, lessons) {
    state.lessons = lessons;
  },
  setProvinces: function (state, provinces) {
    state.provinces = provinces;
  },
  setWays: function (state, ways) {
    state.ways = ways;
  },
}

export default mutations;
