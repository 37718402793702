<template>
  <div class="content container-fluid admin-craftspeople">
    <navigation-admin></navigation-admin>

    <craftspeople-productions></craftspeople-productions>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import CraftspeopleProductions from "@/Admin/Craftspeople/components/CraftspeopleProductions";

export default {
  name: 'CraftspeopleView',
  components: {
    NavigationAdmin,
    CraftspeopleProductions,
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-craftspeople {
  width: 95%!important;
}
</style>
