<template>
  <div class="content container-fluid admin-home">
    <navigation-admin></navigation-admin>

    <div class="accordion accordion-flush" id="homeAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseActuality" aria-expanded="false" aria-controls="flush-collapseActuality">
          Actualités
        </h2>
        <div id="flush-collapseActuality" class="accordion-collapse collapse" data-bs-parent="#homeAccordion">
          <div class="accordion-body">
            <home-actuality></home-actuality>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDeploy" aria-expanded="false" aria-controls="flush-collapseDeploy">
          Deploiements
        </h2>
        <div id="flush-collapseDeploy" class="accordion-collapse collapse" data-bs-parent="#homeAccordion">
          <div class="accordion-body">
            <home-deploy></home-deploy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import HomeActuality from "@/Admin/Home/components/HomeActuality";
import HomeDeploy from "@/Admin/Home/components/HomeDeploy";

export default {
  name: 'HomeView',
  components: {
    NavigationAdmin,
    HomeActuality,
    HomeDeploy
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-home {
  width: 95%!important;
}
.admin-home .accordion {
  margin-top: 20px;
}
.admin-home .accordion h2.accordion-header {
  color: #4091c9;
  text-transform: uppercase;
}
.admin-home .accordion h2.accordion-header.accordion-button,
.admin-home .accordion h2.accordion-header.accordion-button.collapsed {
  background-color: transparent;
}
</style>
