<template>
  <select>
    <option v-if="'off' === option"></option>
    <option v-for="(data, index) in datas" :key="index" :value="data.value">{{ $t(data.label) }}</option>
  </select>
</template>

<script>

export default {
  name: 'SelectArray',
  props: {
    datas: Array,
    option: String
  },
}

</script>

<style>
</style>
