<template>
  <div class="content container-fluid products">
    <div class="card page-content">
      <div class="card-header page-title">
        {{ $t('products.title') }}
      </div>
      <div class="card-body page-body">
        <div class="filters">
          <h2>{{ $t('products.filters') }}</h2>

          <div class="row">
            <div class="form-group col-4">
              <label for="name">{{ $t('products.filter_name') }} :</label>
              <input type="text" v-model="name" class="form-control form-control-sm" id="name">
            </div>
            <div class="form-group col-4">
              <label for="category">{{ $t('products.filter_category') }} :</label>
              <select id="category" v-model="category" class="form-control form-control-sm">
                <option value=""></option>
                <option v-for="(category, index) in categories" :key="index" :value="category.uuid">{{category.name[$i18n.locale]}}</option>
              </select>
            </div>
            <div class="form-group col-4">
              <label for="craftspeople">{{ $t('products.filter_craftspeople') }} :</label>
              <select id="craftspeople" v-model="craftspeople" class="form-control form-control-sm">
                <option value=""></option>
                <option v-for="(craftspeople, index) in craftspeoples" :key="index" :value="craftspeople.uuid">{{craftspeople.name[$i18n.locale]}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <button class="search offset-10 col-2" @click="search()">{{ $t('products.search') }}</button>
          </div>
        </div>
        <products-list :list="products"></products-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductsList from "@/Open/Products/components/ProductsList";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'ProductsView',
  components: {
    ProductsList
  },
  computed: {
    ...mapState('products', {
        categories: 'categories',
        craftspeoples: 'craftspeoples',
        products: 'products'
      }
    )
  },
  data() {
    return {
      name: null,
      category: null,
      craftspeople: null,
    }
  },
  mounted() {
    this.$store.dispatch('products/getCategories');
    this.$store.dispatch('products/getCraftspeoples');
    this.$store.dispatch('products/tracking', {
      page: 'open-products',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  },
  methods: {
    search() {
      this.result = false;
      if(null === this.name && null === this.category && null === this.craftspeople) {
        this.$store.commit('products/setProducts', []);
      } else if ('' === this.name && '' === this.category && '' === this.craftspeople) {
        this.$store.commit('products/setProducts', []);
      } else {
        let payload = {
          name: '' !== this.name ? this.name : null,
          category: '' !== this.category ? this.category : null,
          craftspeople: '' !== this.craftspeople ? this.craftspeople : null
        }
        this.$store.dispatch('products/searchProducts', payload);
      }
      this.$store.dispatch('products/tracking', {
        page: 'open-products',
        component: 'search|name:'+this.name+'|category:'+this.category+'|craftspeople:'+this.craftspeople,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  },
}
</script>

<style>
.products {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.products .page-content {
  border: none;
}
.products .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.products .filters {
  border-bottom: 1px solid #4091c9;
  padding-bottom: 20px;
}
.products .filters h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}
.products .filters .search {
  width: 130px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
