import apiUser from "@/System/store/apiUser";
import api from "@/System/store/api";

const actions = {
  createAccount: ({commit}, payload) => {
    apiUser.post('/create-account', payload)
      .then(function (response) {
        if(200 === response.status) {
          commit('setSuccess');
        } else {
          commit('setError');
        }
      });
  },
  connexion: ({commit}, userInfos) => {
    apiUser.post('/connexion', userInfos)
      .then(function (response) {
        commit('connexionUser', response.data)
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
