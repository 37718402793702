<template>
  <table class="table table-bordered table-striped mines-hebdo">
    <thead>
      <tr class="tr-left tr-bottom">
        <td colspan="11" class="td-top td-right">{{$t('mines.titles.weekly')}}</td>
      </tr>
    </thead>
    <tbody>
      <tr class="tr-left">
        <td width="30%" class="table-label td-right">{{$t('mines.type')}}</td>
        <td colspan="10" class="td-right">
          <select v-model="typeMine">
            <option value="iron">{{$t('mines.type_mines.iron')}}</option>
            <option value="stone">{{$t('mines.type_mines.stone')}}</option>
            <option value="gold">{{$t('mines.type_mines.gold')}}</option>
            <option value="clay">{{$t('mines.type_mines.clay')}}</option>
            <option value="salt">{{$t('mines.type_mines.salt')}}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.level') }}</td>
        <td colspan="10" class="td-right">
          <select v-model="levelMine">
            <option v-for="n in arrayOfValues(3, 17)" :key="n" :value="n">{{ n }}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.sales_value') }}</td>
        <td class="table-label">{{$t('mines.salary')}}</td>
        <td><input type="number" v-model="salaryValue"></td>
        <td class="table-label">{{$t('mines.type_mines.iron')}}</td>
        <td><input type="number" v-model="ironValue"></td>
        <td class="table-label">{{$t('mines.type_mines.stone')}}</td>
        <td><input type="number" v-model="stoneValue"></td>
        <td class="table-label">{{$t('mines.type_mines.clay')}}</td>
        <td><input type="number" v-model="clayValue"></td>
        <td class="table-label">{{$t('mines.type_mines.salt')}}</td>
        <td class="td-right"><input type="number" v-model="saltValue"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.frequency') }}</td>
        <td class="table-label">{{$t('mines.time.1h')}}</td>
        <td class="table-label">{{$t('mines.time.5pa')}}</td>
        <td class="table-label">{{$t('mines.time.2h')}}</td>
        <td class="table-label">{{$t('mines.time.10pa')}}</td>
        <td class="table-label">{{$t('mines.time.6h')}}</td>
        <td class="table-label">{{$t('mines.time.30pa')}}</td>
        <td class="table-label">{{$t('mines.time.10h')}}</td>
        <td class="table-label">{{$t('mines.time.50pa')}}</td>
        <td class="table-label">{{$t('mines.time.22h')}}</td>
        <td class="table-label td-right">{{$t('mines.time.100pa')}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.format') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.one')}}</td>
        <td><input type="number" v-model="frequency1hd1"></td>
        <td><input type="number" v-model="frequency5pad1"></td>
        <td><input type="number" v-model="frequency2hd1"></td>
        <td><input type="number" v-model="frequency10pad1"></td>
        <td><input type="number" v-model="frequency6hd1"></td>
        <td><input type="number" v-model="frequency30pad1"></td>
        <td><input type="number" v-model="frequency10hd1"></td>
        <td><input type="number" v-model="frequency50pad1"></td>
        <td><input type="number" v-model="frequency22hd1"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad1"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.two')}}</td>
        <td><input type="number" v-model="frequency1hd2"></td>
        <td><input type="number" v-model="frequency5pad2"></td>
        <td><input type="number" v-model="frequency2hd2"></td>
        <td><input type="number" v-model="frequency10pad2"></td>
        <td><input type="number" v-model="frequency6hd2"></td>
        <td><input type="number" v-model="frequency30pad2"></td>
        <td><input type="number" v-model="frequency10hd2"></td>
        <td><input type="number" v-model="frequency50pad2"></td>
        <td><input type="number" v-model="frequency22hd2"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad2"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.three')}}</td>
        <td><input type="number" v-model="frequency1hd3"></td>
        <td><input type="number" v-model="frequency5pad3"></td>
        <td><input type="number" v-model="frequency2hd3"></td>
        <td><input type="number" v-model="frequency10pad3"></td>
        <td><input type="number" v-model="frequency6hd3"></td>
        <td><input type="number" v-model="frequency30pad3"></td>
        <td><input type="number" v-model="frequency10hd3"></td>
        <td><input type="number" v-model="frequency50pad3"></td>
        <td><input type="number" v-model="frequency22hd3"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad3"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.four')}}</td>
        <td><input type="number" v-model="frequency1hd4"></td>
        <td><input type="number" v-model="frequency5pad4"></td>
        <td><input type="number" v-model="frequency2hd4"></td>
        <td><input type="number" v-model="frequency10pad4"></td>
        <td><input type="number" v-model="frequency6hd4"></td>
        <td><input type="number" v-model="frequency30pad4"></td>
        <td><input type="number" v-model="frequency10hd4"></td>
        <td><input type="number" v-model="frequency50pad4"></td>
        <td><input type="number" v-model="frequency22hd4"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad4"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.five')}}</td>
        <td><input type="number" v-model="frequency1hd5"></td>
        <td><input type="number" v-model="frequency5pad5"></td>
        <td><input type="number" v-model="frequency2hd5"></td>
        <td><input type="number" v-model="frequency10pad5"></td>
        <td><input type="number" v-model="frequency6hd5"></td>
        <td><input type="number" v-model="frequency30pad5"></td>
        <td><input type="number" v-model="frequency10hd5"></td>
        <td><input type="number" v-model="frequency50pad5"></td>
        <td><input type="number" v-model="frequency22hd5"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad5"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.days.six')}}</td>
        <td><input type="number" v-model="frequency1hd6"></td>
        <td><input type="number" v-model="frequency5pad6"></td>
        <td><input type="number" v-model="frequency2hd6"></td>
        <td><input type="number" v-model="frequency10pad6"></td>
        <td><input type="number" v-model="frequency6hd6"></td>
        <td><input type="number" v-model="frequency30pad6"></td>
        <td><input type="number" v-model="frequency10hd6"></td>
        <td><input type="number" v-model="frequency50pad6"></td>
        <td><input type="number" v-model="frequency22hd6"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad6"></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.days.seven')}}</td>
        <td><input type="number" v-model="frequency1hd7"></td>
        <td><input type="number" v-model="frequency5pad7"></td>
        <td><input type="number" v-model="frequency2hd7"></td>
        <td><input type="number" v-model="frequency10pad7"></td>
        <td><input type="number" v-model="frequency6hd7"></td>
        <td><input type="number" v-model="frequency30pad7"></td>
        <td><input type="number" v-model="frequency10hd7"></td>
        <td><input type="number" v-model="frequency50pad7"></td>
        <td><input type="number" v-model="frequency22hd7"></td>
        <td class="td-right"><input type="number" v-model="frequency100pad7"></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td></td>
        <td colspan="10" class="td-right">
          <button @click="calculate">{{ $t('mines.calculate') }}</button>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.frequency') }}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.1h')}} | {{$t('mines.time.5pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.2h')}} | {{$t('mines.time.10pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.6h')}} | {{$t('mines.time.30pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.10h')}} | {{$t('mines.time.50pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.22h')}} | {{$t('mines.time.100pa')}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.format') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.without_skills') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.frequency')}}</td>
        <td>{{totalFrequency1h}}</td>
        <td class="td-right">{{totalFrequency5pa}}</td>
        <td>{{totalFrequency2h}}</td>
        <td class="td-right">{{totalFrequency10pa}}</td>
        <td>{{totalFrequency6h}}</td>
        <td class="td-right">{{totalFrequency30pa}}</td>
        <td>{{totalFrequency10h}}</td>
        <td class="td-right">{{totalFrequency50pa}}</td>
        <td>{{totalFrequency22h}}</td>
        <td class="td-right">{{totalFrequency100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_iron')}}</td>
        <td>{{maintenanceIron1h}}</td>
        <td class="td-right">{{maintenanceIron5pa}}</td>
        <td>{{maintenanceIron2h}}</td>
        <td class="td-right">{{maintenanceIron10pa}}</td>
        <td>{{maintenanceIron6h}}</td>
        <td class="td-right">{{maintenanceIron30pa}}</td>
        <td>{{maintenanceIron10h}}</td>
        <td class="td-right">{{maintenanceIron50pa}}</td>
        <td>{{maintenanceIron22h}}</td>
        <td class="td-right">{{maintenanceIron100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_iron')}}</td>
        <td>{{costMaintenanceIron1h}}</td>
        <td class="td-right">{{costMaintenanceIron5pa}}</td>
        <td>{{costMaintenanceIron2h}}</td>
        <td class="td-right">{{costMaintenanceIron10pa}}</td>
        <td>{{costMaintenanceIron6h}}</td>
        <td class="td-right">{{costMaintenanceIron30pa}}</td>
        <td>{{costMaintenanceIron10h}}</td>
        <td class="td-right">{{costMaintenanceIron50pa}}</td>
        <td>{{costMaintenanceIron22h}}</td>
        <td class="td-right">{{costMaintenanceIron100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_stone')}}</td>
        <td>{{maintenanceStone1h}}</td>
        <td class="td-right">{{maintenanceStone5pa}}</td>
        <td>{{maintenanceStone2h}}</td>
        <td class="td-right">{{maintenanceStone10pa}}</td>
        <td>{{maintenanceStone6h}}</td>
        <td class="td-right">{{maintenanceStone30pa}}</td>
        <td>{{maintenanceStone10h}}</td>
        <td class="td-right">{{maintenanceStone50pa}}</td>
        <td>{{maintenanceStone22h}}</td>
        <td class="td-right">{{maintenanceStone100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_stone')}}</td>
        <td>{{costMaintenanceStone1h}}</td>
        <td class="td-right">{{costMaintenanceStone5pa}}</td>
        <td>{{costMaintenanceStone2h}}</td>
        <td class="td-right">{{costMaintenanceStone10pa}}</td>
        <td>{{costMaintenanceStone6h}}</td>
        <td class="td-right">{{costMaintenanceStone30pa}}</td>
        <td>{{costMaintenanceStone10h}}</td>
        <td class="td-right">{{costMaintenanceStone50pa}}</td>
        <td>{{costMaintenanceStone22h}}</td>
        <td class="td-right">{{costMaintenanceStone100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_salary')}}</td>
        <td>{{costSalary1h}}</td>
        <td class="td-right">{{costSalary5pa}}</td>
        <td>{{costSalary2h}}</td>
        <td class="td-right">{{costSalary10pa}}</td>
        <td>{{costSalary6h}}</td>
        <td class="td-right">{{costSalary30pa}}</td>
        <td>{{costSalary10h}}</td>
        <td class="td-right">{{costSalary50pa}}</td>
        <td>{{costSalary22h}}</td>
        <td class="td-right">{{costSalary100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_total_production')}}</td>
        <td>{{costProduction1h}}</td>
        <td class="td-right">{{costProduction5pa}}</td>
        <td>{{costProduction2h}}</td>
        <td class="td-right">{{costProduction10pa}}</td>
        <td>{{costProduction6h}}</td>
        <td class="td-right">{{costProduction30pa}}</td>
        <td>{{costProduction10h}}</td>
        <td class="td-right">{{costProduction50pa}}</td>
        <td>{{costProduction22h}}</td>
        <td class="td-right">{{costProduction100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.quantity_produced')}}</td>
        <td>{{totalProd1h}}</td>
        <td class="td-right">{{totalProd5pa}}</td>
        <td>{{totalProd2h}}</td>
        <td class="td-right">{{totalProd10pa}}</td>
        <td>{{totalProd6h}}</td>
        <td class="td-right">{{totalProd30pa}}</td>
        <td>{{totalProd10h}}</td>
        <td class="td-right">{{totalProd50pa}}</td>
        <td>{{totalProd22h}}</td>
        <td class="td-right">{{totalProd100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.value_production')}}</td>
        <td>{{valueProd1h}}</td>
        <td class="td-right">{{valueProd5pa}}</td>
        <td>{{valueProd2h}}</td>
        <td class="td-right">{{valueProd10pa}}</td>
        <td>{{valueProd6h}}</td>
        <td class="td-right">{{valueProd30pa}}</td>
        <td>{{valueProd10h}}</td>
        <td class="td-right">{{valueProd50pa}}</td>
        <td>{{valueProd22h}}</td>
        <td class="td-right">{{valueProd100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.profit')}}</td>
        <td>{{benefit1h}}</td>
        <td class="td-right">{{benefit5pa}}</td>
        <td>{{benefit2h}}</td>
        <td class="td-right">{{benefit10pa}}</td>
        <td>{{benefit6h}}</td>
        <td class="td-right">{{benefit30pa}}</td>
        <td>{{benefit10h}}</td>
        <td class="td-right">{{benefit50pa}}</td>
        <td>{{benefit22h}}</td>
        <td class="td-right">{{benefit100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{investmentTotalMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotal}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{profitTotalMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotal}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.with_skills_down') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.frequency')}}</td>
        <td>{{totalFrequencyNoSkills1h}}</td>
        <td class="td-right">{{totalFrequencyNoSkills5pa}}</td>
        <td>{{totalFrequencyNoSkills2h}}</td>
        <td class="td-right">{{totalFrequencyNoSkills10pa}}</td>
        <td>{{totalFrequencyNoSkills6h}}</td>
        <td class="td-right">{{totalFrequencyNoSkills30pa}}</td>
        <td>{{totalFrequencyNoSkills10h}}</td>
        <td class="td-right">{{totalFrequencyNoSkills50pa}}</td>
        <td>{{totalFrequencyNoSkills22h}}</td>
        <td class="td-right">{{totalFrequencyNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_iron')}}</td>
        <td>{{maintenanceIronNoSkills1h}}</td>
        <td class="td-right">{{maintenanceIronNoSkills5pa}}</td>
        <td>{{maintenanceIronNoSkills2h}}</td>
        <td class="td-right">{{maintenanceIronNoSkills10pa}}</td>
        <td>{{maintenanceIronNoSkills6h}}</td>
        <td class="td-right">{{maintenanceIronNoSkills30pa}}</td>
        <td>{{maintenanceIronNoSkills10h}}</td>
        <td class="td-right">{{maintenanceIronNoSkills50pa}}</td>
        <td>{{maintenanceIronNoSkills22h}}</td>
        <td class="td-right">{{maintenanceIronNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_iron')}}</td>
        <td>{{costMaintenanceIronNoSkills1h}}</td>
        <td class="td-right">{{costMaintenanceIronNoSkills5pa}}</td>
        <td>{{costMaintenanceIronNoSkills2h}}</td>
        <td class="td-right">{{costMaintenanceIronNoSkills10pa}}</td>
        <td>{{costMaintenanceIronNoSkills6h}}</td>
        <td class="td-right">{{costMaintenanceIronNoSkills30pa}}</td>
        <td>{{costMaintenanceIronNoSkills10h}}</td>
        <td class="td-right">{{costMaintenanceIronNoSkills50pa}}</td>
        <td>{{costMaintenanceIronNoSkills22h}}</td>
        <td class="td-right">{{costMaintenanceIronNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_stone')}}</td>
        <td>{{maintenanceStoneNoSkills1h}}</td>
        <td class="td-right">{{maintenanceStoneNoSkills5pa}}</td>
        <td>{{maintenanceStoneNoSkills2h}}</td>
        <td class="td-right">{{maintenanceStoneNoSkills10pa}}</td>
        <td>{{maintenanceStoneNoSkills6h}}</td>
        <td class="td-right">{{maintenanceStoneNoSkills30pa}}</td>
        <td>{{maintenanceStoneNoSkills10h}}</td>
        <td class="td-right">{{maintenanceStoneNoSkills50pa}}</td>
        <td>{{maintenanceStoneNoSkills22h}}</td>
        <td class="td-right">{{maintenanceStoneNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_stone')}}</td>
        <td>{{costMaintenanceStoneNoSkills1h}}</td>
        <td class="td-right">{{costMaintenanceStoneNoSkills5pa}}</td>
        <td>{{costMaintenanceStoneNoSkills2h}}</td>
        <td class="td-right">{{costMaintenanceStoneNoSkills10pa}}</td>
        <td>{{costMaintenanceStoneNoSkills6h}}</td>
        <td class="td-right">{{costMaintenanceStoneNoSkills30pa}}</td>
        <td>{{costMaintenanceStoneNoSkills10h}}</td>
        <td class="td-right">{{costMaintenanceStoneNoSkills50pa}}</td>
        <td>{{costMaintenanceStoneNoSkills22h}}</td>
        <td class="td-right">{{costMaintenanceStoneNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_salary')}}</td>
        <td>{{costSalaryNoSkills1h}}</td>
        <td class="td-right">{{costSalaryNoSkills5pa}}</td>
        <td>{{costSalaryNoSkills2h}}</td>
        <td class="td-right">{{costSalaryNoSkills10pa}}</td>
        <td>{{costSalaryNoSkills6h}}</td>
        <td class="td-right">{{costSalaryNoSkills30pa}}</td>
        <td>{{costSalaryNoSkills10h}}</td>
        <td class="td-right">{{costSalaryNoSkills50pa}}</td>
        <td>{{costSalaryNoSkills22h}}</td>
        <td class="td-right">{{costSalaryNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_total_production')}}</td>
        <td>{{costProductionNoSkills1h}}</td>
        <td class="td-right">{{costProductionNoSkills5pa}}</td>
        <td>{{costProductionNoSkills2h}}</td>
        <td class="td-right">{{costProductionNoSkills10pa}}</td>
        <td>{{costProductionNoSkills6h}}</td>
        <td class="td-right">{{costProductionNoSkills30pa}}</td>
        <td>{{costProductionNoSkills10h}}</td>
        <td class="td-right">{{costProductionNoSkills50pa}}</td>
        <td>{{costProductionNoSkills22h}}</td>
        <td class="td-right">{{costProductionNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.quantity_produced')}}</td>
        <td>{{totalProdNoSkills1h}}</td>
        <td class="td-right">{{totalProdNoSkills5pa}}</td>
        <td>{{totalProdNoSkills2h}}</td>
        <td class="td-right">{{totalProdNoSkills10pa}}</td>
        <td>{{totalProdNoSkills6h}}</td>
        <td class="td-right">{{totalProdNoSkills30pa}}</td>
        <td>{{totalProdNoSkills10h}}</td>
        <td class="td-right">{{totalProdNoSkills50pa}}</td>
        <td>{{totalProdNoSkills22h}}</td>
        <td class="td-right">{{totalProdNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.value_production')}}</td>
        <td>{{valueProdNoSkills1h}}</td>
        <td class="td-right">{{valueProdNoSkills5pa}}</td>
        <td>{{valueProdNoSkills2h}}</td>
        <td class="td-right">{{valueProdNoSkills10pa}}</td>
        <td>{{valueProdNoSkills6h}}</td>
        <td class="td-right">{{valueProdNoSkills30pa}}</td>
        <td>{{valueProdNoSkills10h}}</td>
        <td class="td-right">{{valueProdNoSkills50pa}}</td>
        <td>{{valueProdNoSkills22h}}</td>
        <td class="td-right">{{valueProdNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.profit')}}</td>
        <td>{{benefitNoSkills1h}}</td>
        <td class="td-right">{{benefitNoSkills5pa}}</td>
        <td>{{benefitNoSkills2h}}</td>
        <td class="td-right">{{benefitNoSkills10pa}}</td>
        <td>{{benefitNoSkills6h}}</td>
        <td class="td-right">{{benefitNoSkills30pa}}</td>
        <td>{{benefitNoSkills10h}}</td>
        <td class="td-right">{{benefitNoSkills50pa}}</td>
        <td>{{benefitNoSkills22h}}</td>
        <td class="td-right">{{benefitNoSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalNoSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{ investmentTotalNoSkillsMob }}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotalNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalNoSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{ profitTotalNoSkillsMob }}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotalNoSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.with_skills_up') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.frequency')}}</td>
        <td>{{totalFrequencySkills1h}}</td>
        <td class="td-right">{{totalFrequencySkills5pa}}</td>
        <td>{{totalFrequencySkills2h}}</td>
        <td class="td-right">{{totalFrequencySkills10pa}}</td>
        <td>{{totalFrequencySkills6h}}</td>
        <td class="td-right">{{totalFrequencySkills30pa}}</td>
        <td>{{totalFrequencySkills10h}}</td>
        <td class="td-right">{{totalFrequencySkills50pa}}</td>
        <td>{{totalFrequencySkills22h}}</td>
        <td class="td-right">{{totalFrequencySkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_iron')}}</td>
        <td>{{maintenanceIronSkills1h}}</td>
        <td class="td-right">{{maintenanceIronSkills5pa}}</td>
        <td>{{maintenanceIronSkills2h}}</td>
        <td class="td-right">{{maintenanceIronSkills10pa}}</td>
        <td>{{maintenanceIronSkills6h}}</td>
        <td class="td-right">{{maintenanceIronSkills30pa}}</td>
        <td>{{maintenanceIronSkills10h}}</td>
        <td class="td-right">{{maintenanceIronSkills50pa}}</td>
        <td>{{maintenanceIronSkills22h}}</td>
        <td class="td-right">{{maintenanceIronSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_iron')}}</td>
        <td>{{costMaintenanceIronSkills1h}}</td>
        <td class="td-right">{{costMaintenanceIronSkills5pa}}</td>
        <td>{{costMaintenanceIronSkills2h}}</td>
        <td class="td-right">{{costMaintenanceIronSkills10pa}}</td>
        <td>{{costMaintenanceIronSkills6h}}</td>
        <td class="td-right">{{costMaintenanceIronSkills30pa}}</td>
        <td>{{costMaintenanceIronSkills10h}}</td>
        <td class="td-right">{{costMaintenanceIronSkills50pa}}</td>
        <td>{{costMaintenanceIronSkills22h}}</td>
        <td class="td-right">{{costMaintenanceIronSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.maintenance_stone')}}</td>
        <td>{{maintenanceStoneSkills1h}}</td>
        <td class="td-right">{{maintenanceStoneSkills5pa}}</td>
        <td>{{maintenanceStoneSkills2h}}</td>
        <td class="td-right">{{maintenanceStoneSkills10pa}}</td>
        <td>{{maintenanceStoneSkills6h}}</td>
        <td class="td-right">{{maintenanceStoneSkills30pa}}</td>
        <td>{{maintenanceStoneSkills10h}}</td>
        <td class="td-right">{{maintenanceStoneSkills50pa}}</td>
        <td>{{maintenanceStoneSkills22h}}</td>
        <td class="td-right">{{maintenanceStoneSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_maintenance_stone')}}</td>
        <td>{{costMaintenanceStoneSkills1h}}</td>
        <td class="td-right">{{costMaintenanceStoneSkills5pa}}</td>
        <td>{{costMaintenanceStoneSkills2h}}</td>
        <td class="td-right">{{costMaintenanceStoneSkills10pa}}</td>
        <td>{{costMaintenanceStoneSkills6h}}</td>
        <td class="td-right">{{costMaintenanceStoneSkills30pa}}</td>
        <td>{{costMaintenanceStoneSkills10h}}</td>
        <td class="td-right">{{costMaintenanceStoneSkills50pa}}</td>
        <td>{{costMaintenanceStoneSkills22h}}</td>
        <td class="td-right">{{costMaintenanceStoneSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_salary')}}</td>
        <td>{{costSalarySkills1h}}</td>
        <td class="td-right">{{costSalarySkills5pa}}</td>
        <td>{{costSalarySkills2h}}</td>
        <td class="td-right">{{costSalarySkills10pa}}</td>
        <td>{{costSalarySkills6h}}</td>
        <td class="td-right">{{costSalarySkills30pa}}</td>
        <td>{{costSalarySkills10h}}</td>
        <td class="td-right">{{costSalarySkills50pa}}</td>
        <td>{{costSalarySkills22h}}</td>
        <td class="td-right">{{costSalarySkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.cost_total_production')}}</td>
        <td>{{costProductionSkills1h}}</td>
        <td class="td-right">{{costProductionSkills5pa}}</td>
        <td>{{costProductionSkills2h}}</td>
        <td class="td-right">{{costProductionSkills10pa}}</td>
        <td>{{costProductionSkills6h}}</td>
        <td class="td-right">{{costProductionSkills30pa}}</td>
        <td>{{costProductionSkills10h}}</td>
        <td class="td-right">{{costProductionSkills50pa}}</td>
        <td>{{costProductionSkills22h}}</td>
        <td class="td-right">{{costProductionSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.result_weekly.quantity_produced')}}</td>
        <td>{{totalProdSkills1h}}</td>
        <td class="td-right">{{totalProdSkills5pa}}</td>
        <td>{{totalProdSkills2h}}</td>
        <td class="td-right">{{totalProdSkills10pa}}</td>
        <td>{{totalProdSkills6h}}</td>
        <td class="td-right">{{totalProdSkills30pa}}</td>
        <td>{{totalProdSkills10h}}</td>
        <td class="td-right">{{totalProdSkills50pa}}</td>
        <td>{{totalProdSkills22h}}</td>
        <td class="td-right">{{totalProdSkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.value_production')}}</td>
        <td>{{valueProdSkills1h}}</td>
        <td class="td-right">{{valueProdSkills5pa}}</td>
        <td>{{valueProdSkills2h}}</td>
        <td class="td-right">{{valueProdSkills10pa}}</td>
        <td>{{valueProdSkills6h}}</td>
        <td class="td-right">{{valueProdSkills30pa}}</td>
        <td>{{valueProdSkills10h}}</td>
        <td class="td-right">{{valueProdSkills50pa}}</td>
        <td>{{valueProdSkills22h}}</td>
        <td class="td-right">{{valueProdSkills100pa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.result_weekly.profit')}}</td>
        <td>{{benefitSkills1h}}</td>
        <td class="td-right">{{benefitSkills5pa}}</td>
        <td>{{benefitSkills2h}}</td>
        <td class="td-right">{{benefitSkills10pa}}</td>
        <td>{{benefitSkills6h}}</td>
        <td class="td-right">{{benefitSkills30pa}}</td>
        <td>{{benefitSkills10h}}</td>
        <td class="td-right">{{benefitSkills50pa}}</td>
        <td>{{benefitSkills22h}}</td>
        <td class="td-right">{{benefitSkills100pa}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{investmentTotalSkillsMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotalSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{profitTotalSkillsMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotalSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td></td>
        <td colspan="10" class="td-right">
          <button @click="reset">{{ $t('mines.reset') }}</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MinesHebdo',
  data() {
    return {
      typeMine: null,
      levelMine: null,
      salaryValue: null,
      ironValue: null,
      stoneValue: null,
      clayValue: null,
      saltValue: null,
      frequency1hd1: null,
      frequency2hd1: null,
      frequency6hd1: null,
      frequency10hd1: null,
      frequency22hd1: null,
      frequency5pad1: null,
      frequency10pad1: null,
      frequency30pad1: null,
      frequency50pad1: null,
      frequency100pad1: null,
      frequency1hd2: null,
      frequency2hd2: null,
      frequency6hd2: null,
      frequency10hd2: null,
      frequency22hd2: null,
      frequency5pad2: null,
      frequency10pad2: null,
      frequency30pad2: null,
      frequency50pad2: null,
      frequency100pad2: null,
      frequency1hd3: null,
      frequency2hd3: null,
      frequency6hd3: null,
      frequency10hd3: null,
      frequency22hd3: null,
      frequency5pad3: null,
      frequency10pad3: null,
      frequency30pad3: null,
      frequency50pad3: null,
      frequency100pad3: null,
      frequency1hd4: null,
      frequency2hd4: null,
      frequency6hd4: null,
      frequency10hd4: null,
      frequency22hd4: null,
      frequency5pad4: null,
      frequency10pad4: null,
      frequency30pad4: null,
      frequency50pad4: null,
      frequency100pad4: null,
      frequency1hd5: null,
      frequency2hd5: null,
      frequency6hd5: null,
      frequency10hd5: null,
      frequency22hd5: null,
      frequency5pad5: null,
      frequency10pad5: null,
      frequency30pad5: null,
      frequency50pad5: null,
      frequency100pad5: null,
      frequency1hd6: null,
      frequency2hd6: null,
      frequency6hd6: null,
      frequency10hd6: null,
      frequency22hd6: null,
      frequency5pad6: null,
      frequency10pad6: null,
      frequency30pad6: null,
      frequency50pad6: null,
      frequency100pad6: null,
      frequency1hd7: null,
      frequency2hd7: null,
      frequency6hd7: null,
      frequency10hd7: null,
      frequency22hd7: null,
      frequency5pad7: null,
      frequency10pad7: null,
      frequency30pad7: null,
      frequency50pad7: null,
      frequency100pad7: null,
      totalFrequency1h: null,
      totalFrequency2h: null,
      totalFrequency6h: null,
      totalFrequency10h: null,
      totalFrequency22h: null,
      totalFrequency5pa: null,
      totalFrequency10pa: null,
      totalFrequency30pa: null,
      totalFrequency50pa: null,
      totalFrequency100pa: null,
      costSalary1h: null,
      costSalary2h: null,
      costSalary6h: null,
      costSalary10h: null,
      costSalary22h: null,
      costSalary5pa: null,
      costSalary10pa: null,
      costSalary30pa: null,
      costSalary50pa: null,
      costSalary100pa: null,
      maintenanceIron1h: null,
      maintenanceIron2h: null,
      maintenanceIron6h: null,
      maintenanceIron10h: null,
      maintenanceIron22h: null,
      maintenanceIron5pa: null,
      maintenanceIron10pa: null,
      maintenanceIron30pa: null,
      maintenanceIron50pa: null,
      maintenanceIron100pa: null,
      costMaintenanceIron1h: null,
      costMaintenanceIron2h: null,
      costMaintenanceIron6h: null,
      costMaintenanceIron10h: null,
      costMaintenanceIron22h: null,
      costMaintenanceIron5pa: null,
      costMaintenanceIron10pa: null,
      costMaintenanceIron30pa: null,
      costMaintenanceIron50pa: null,
      costMaintenanceIron100pa: null,
      maintenanceStone1h: null,
      maintenanceStone2h: null,
      maintenanceStone6h: null,
      maintenanceStone10h: null,
      maintenanceStone22h: null,
      maintenanceStone5pa: null,
      maintenanceStone10pa: null,
      maintenanceStone30pa: null,
      maintenanceStone50pa: null,
      maintenanceStone100pa: null,
      costMaintenanceStone1h: null,
      costMaintenanceStone2h: null,
      costMaintenanceStone6h: null,
      costMaintenanceStone10h: null,
      costMaintenanceStone22h: null,
      costMaintenanceStone5pa: null,
      costMaintenanceStone10pa: null,
      costMaintenanceStone30pa: null,
      costMaintenanceStone50pa: null,
      costMaintenanceStone100pa: null,
      costProduction1h: null,
      costProduction2h: null,
      costProduction6h: null,
      costProduction10h: null,
      costProduction22h: null,
      costProduction5pa: null,
      costProduction10pa: null,
      costProduction30pa: null,
      costProduction50pa: null,
      costProduction100pa: null,
      totalProd1h: null,
      totalProd2h: null,
      totalProd6h: null,
      totalProd10h: null,
      totalProd22h: null,
      totalProd5pa: null,
      totalProd10pa: null,
      totalProd30pa: null,
      totalProd50pa: null,
      totalProd100pa: null,
      valueProd1h: null,
      valueProd2h: null,
      valueProd6h: null,
      valueProd10h: null,
      valueProd22h: null,
      valueProd5pa: null,
      valueProd10pa: null,
      valueProd30pa: null,
      valueProd50pa: null,
      valueProd100pa: null,
      benefit1h: null,
      benefit2h: null,
      benefit6h: null,
      benefit10h: null,
      benefit22h: null,
      benefit5pa: null,
      benefit10pa: null,
      benefit30pa: null,
      benefit50pa: null,
      benefit100pa: null,
      investmentTotalWeb: null,
      investmentTotalMob: null,
      investmentTotal: null,
      profitTotalWeb: null,
      profitTotalMob: null,
      profitTotal: null,
      totalFrequencyNoSkills1h: null,
      totalFrequencyNoSkills2h: null,
      totalFrequencyNoSkills6h: null,
      totalFrequencyNoSkills10h: null,
      totalFrequencyNoSkills22h: null,
      totalFrequencyNoSkills5pa: null,
      totalFrequencyNoSkills10pa: null,
      totalFrequencyNoSkills30pa: null,
      totalFrequencyNoSkills50pa: null,
      totalFrequencyNoSkills100pa: null,
      costSalaryNoSkills1h: null,
      costSalaryNoSkills2h: null,
      costSalaryNoSkills6h: null,
      costSalaryNoSkills10h: null,
      costSalaryNoSkills22h: null,
      costSalaryNoSkills5pa: null,
      costSalaryNoSkills10pa: null,
      costSalaryNoSkills30pa: null,
      costSalaryNoSkills50pa: null,
      costSalaryNoSkills100pa: null,
      maintenanceIronNoSkills1h: null,
      maintenanceIronNoSkills2h: null,
      maintenanceIronNoSkills6h: null,
      maintenanceIronNoSkills10h: null,
      maintenanceIronNoSkills22h: null,
      maintenanceIronNoSkills5pa: null,
      maintenanceIronNoSkills10pa: null,
      maintenanceIronNoSkills30pa: null,
      maintenanceIronNoSkills50pa: null,
      maintenanceIronNoSkills100pa: null,
      costMaintenanceIronNoSkills1h: null,
      costMaintenanceIronNoSkills2h: null,
      costMaintenanceIronNoSkills6h: null,
      costMaintenanceIronNoSkills10h: null,
      costMaintenanceIronNoSkills22h: null,
      costMaintenanceIronNoSkills5pa: null,
      costMaintenanceIronNoSkills10pa: null,
      costMaintenanceIronNoSkills30pa: null,
      costMaintenanceIronNoSkills50pa: null,
      costMaintenanceIronNoSkills100pa: null,
      maintenanceStoneNoSkills1h: null,
      maintenanceStoneNoSkills2h: null,
      maintenanceStoneNoSkills6h: null,
      maintenanceStoneNoSkills10h: null,
      maintenanceStoneNoSkills22h: null,
      maintenanceStoneNoSkills5pa: null,
      maintenanceStoneNoSkills10pa: null,
      maintenanceStoneNoSkills30pa: null,
      maintenanceStoneNoSkills50pa: null,
      maintenanceStoneNoSkills100pa: null,
      costMaintenanceStoneNoSkills1h: null,
      costMaintenanceStoneNoSkills2h: null,
      costMaintenanceStoneNoSkills6h: null,
      costMaintenanceStoneNoSkills10h: null,
      costMaintenanceStoneNoSkills22h: null,
      costMaintenanceStoneNoSkills5pa: null,
      costMaintenanceStoneNoSkills10pa: null,
      costMaintenanceStoneNoSkills30pa: null,
      costMaintenanceStoneNoSkills50pa: null,
      costMaintenanceStoneNoSkills100pa: null,
      costProductionNoSkills1h: null,
      costProductionNoSkills2h: null,
      costProductionNoSkills6h: null,
      costProductionNoSkills10h: null,
      costProductionNoSkills22h: null,
      costProductionNoSkills5pa: null,
      costProductionNoSkills10pa: null,
      costProductionNoSkills30pa: null,
      costProductionNoSkills50pa: null,
      costProductionNoSkills100pa: null,
      totalProdNoSkills1h: null,
      totalProdNoSkills2h: null,
      totalProdNoSkills6h: null,
      totalProdNoSkills10h: null,
      totalProdNoSkills22h: null,
      totalProdNoSkills5pa: null,
      totalProdNoSkills10pa: null,
      totalProdNoSkills30pa: null,
      totalProdNoSkills50pa: null,
      totalProdNoSkills100pa: null,
      valueProdNoSkills1h: null,
      valueProdNoSkills2h: null,
      valueProdNoSkills6h: null,
      valueProdNoSkills10h: null,
      valueProdNoSkills22h: null,
      valueProdNoSkills5pa: null,
      valueProdNoSkills10pa: null,
      valueProdNoSkills30pa: null,
      valueProdNoSkills50pa: null,
      valueProdNoSkills100pa: null,
      benefitNoSkills1h: null,
      benefitNoSkills2h: null,
      benefitNoSkills6h: null,
      benefitNoSkills10h: null,
      benefitNoSkills22h: null,
      benefitNoSkills5pa: null,
      benefitNoSkills10pa: null,
      benefitNoSkills30pa: null,
      benefitNoSkills50pa: null,
      benefitNoSkills100pa: null,
      investmentTotalNoSkillsWeb: null,
      investmentTotalNoSkillsMob: null,
      investmentTotalNoSkills: null,
      profitTotalNoSkillsWeb: null,
      profitTotalNoSkillsMob: null,
      profitTotalNoSkills: null,
      totalFrequencySkills1h: null,
      totalFrequencySkills2h: null,
      totalFrequencySkills6h: null,
      totalFrequencySkills10h: null,
      totalFrequencySkills22h: null,
      totalFrequencySkills5pa: null,
      totalFrequencySkills10pa: null,
      totalFrequencySkills30pa: null,
      totalFrequencySkills50pa: null,
      totalFrequencySkills100pa: null,
      costSalarySkills1h: null,
      costSalarySkills2h: null,
      costSalarySkills6h: null,
      costSalarySkills10h: null,
      costSalarySkills22h: null,
      costSalarySkills5pa: null,
      costSalarySkills10pa: null,
      costSalarySkills30pa: null,
      costSalarySkills50pa: null,
      costSalarySkills100pa: null,
      maintenanceIronSkills1h: null,
      maintenanceIronSkills2h: null,
      maintenanceIronSkills6h: null,
      maintenanceIronSkills10h: null,
      maintenanceIronSkills22h: null,
      maintenanceIronSkills5pa: null,
      maintenanceIronSkills10pa: null,
      maintenanceIronSkills30pa: null,
      maintenanceIronSkills50pa: null,
      maintenanceIronSkills100pa: null,
      costMaintenanceIronSkills1h: null,
      costMaintenanceIronSkills2h: null,
      costMaintenanceIronSkills6h: null,
      costMaintenanceIronSkills10h: null,
      costMaintenanceIronSkills22h: null,
      costMaintenanceIronSkills5pa: null,
      costMaintenanceIronSkills10pa: null,
      costMaintenanceIronSkills30pa: null,
      costMaintenanceIronSkills50pa: null,
      costMaintenanceIronSkills100pa: null,
      maintenanceStoneSkills1h: null,
      maintenanceStoneSkills2h: null,
      maintenanceStoneSkills6h: null,
      maintenanceStoneSkills10h: null,
      maintenanceStoneSkills22h: null,
      maintenanceStoneSkills5pa: null,
      maintenanceStoneSkills10pa: null,
      maintenanceStoneSkills30pa: null,
      maintenanceStoneSkills50pa: null,
      maintenanceStoneSkills100pa: null,
      costMaintenanceStoneSkills1h: null,
      costMaintenanceStoneSkills2h: null,
      costMaintenanceStoneSkills6h: null,
      costMaintenanceStoneSkills10h: null,
      costMaintenanceStoneSkills22h: null,
      costMaintenanceStoneSkills5pa: null,
      costMaintenanceStoneSkills10pa: null,
      costMaintenanceStoneSkills30pa: null,
      costMaintenanceStoneSkills50pa: null,
      costMaintenanceStoneSkills100pa: null,
      costProductionSkills1h: null,
      costProductionSkills2h: null,
      costProductionSkills6h: null,
      costProductionSkills10h: null,
      costProductionSkills22h: null,
      costProductionSkills5pa: null,
      costProductionSkills10pa: null,
      costProductionSkills30pa: null,
      costProductionSkills50pa: null,
      costProductionSkills100pa: null,
      totalProdSkills1h: null,
      totalProdSkills2h: null,
      totalProdSkills6h: null,
      totalProdSkills10h: null,
      totalProdSkills22h: null,
      totalProdSkills5pa: null,
      totalProdSkills10pa: null,
      totalProdSkills30pa: null,
      totalProdSkills50pa: null,
      totalProdSkills100pa: null,
      valueProdSkills1h: null,
      valueProdSkills2h: null,
      valueProdSkills6h: null,
      valueProdSkills10h: null,
      valueProdSkills22h: null,
      valueProdSkills5pa: null,
      valueProdSkills10pa: null,
      valueProdSkills30pa: null,
      valueProdSkills50pa: null,
      valueProdSkills100pa: null,
      benefitSkills1h: null,
      benefitSkills2h: null,
      benefitSkills6h: null,
      benefitSkills10h: null,
      benefitSkills22h: null,
      benefitSkills5pa: null,
      benefitSkills10pa: null,
      benefitSkills30pa: null,
      benefitSkills50pa: null,
      benefitSkills100pa: null,
      investmentTotalSkillsWeb: null,
      investmentTotalSkillsMob: null,
      investmentTotalSkills: null,
      profitTotalSkillsWeb: null,
      profitTotalSkillsMob: null,
      profitTotalSkills: null,
    }
  },
  mounted() {
    this.typeMine = 'iron';
    this.levelMine = this.getOptimumByMine(this.typeMine);
  },
  methods: {
    calculate() {
      let output = this.getOutputByMine(this.typeMine);
      let prodValue = this.getProdValue(this.typeMine);

      this.totalFrequency1h = Math.round((this.frequency1hd1 + this.frequency1hd2 + this.frequency1hd3 + this.frequency1hd4 + this.frequency1hd5 + this.frequency1hd6 + this.frequency1hd7) * 100) / 100;
      this.totalFrequency2h = Math.round((this.frequency2hd1 + this.frequency2hd2 + this.frequency2hd3 + this.frequency2hd4 + this.frequency2hd5 + this.frequency2hd6 + this.frequency2hd7) * 100) / 100;
      this.totalFrequency6h = Math.round((this.frequency6hd1 + this.frequency6hd2 + this.frequency6hd3 + this.frequency6hd4 + this.frequency6hd5 + this.frequency6hd6 + this.frequency6hd7) * 100) / 100;
      this.totalFrequency10h = Math.round((this.frequency10hd1 + this.frequency10hd2 + this.frequency10hd3 + this.frequency10hd4 + this.frequency10hd5 + this.frequency10hd6 + this.frequency10hd7) * 100) / 100;
      this.totalFrequency22h = Math.round((this.frequency22hd1 + this.frequency22hd2 + this.frequency22hd3 + this.frequency22hd4 + this.frequency22hd5 + this.frequency22hd6 + this.frequency22hd7) * 100) / 100;
      this.totalFrequency5pa = Math.round((this.frequency5pad1 + this.frequency5pad2 + this.frequency5pad3 + this.frequency5pad4 + this.frequency5pad5 + this.frequency5pad6 + this.frequency5pad7) * 100) / 100;
      this.totalFrequency10pa = Math.round((this.frequency10pad1 + this.frequency10pad2 + this.frequency10pad3 + this.frequency10pad4 + this.frequency10pad5 + this.frequency10pad6 + this.frequency10pad7) * 100) / 100;
      this.totalFrequency30pa = Math.round((this.frequency30pad1 + this.frequency30pad2 + this.frequency30pad3 + this.frequency30pad4 + this.frequency30pad5 + this.frequency30pad6 + this.frequency30pad7) * 100) / 100;
      this.totalFrequency50pa = Math.round((this.frequency50pad1 + this.frequency50pad2 + this.frequency50pad3 + this.frequency50pad4 + this.frequency50pad5 + this.frequency50pad6 + this.frequency50pad7) * 100) / 100;
      this.totalFrequency100pa = Math.round((this.frequency100pad1 + this.frequency100pad2 + this.frequency100pad3 + this.frequency100pad4 + this.frequency100pad5 + this.frequency100pad6 + this.frequency100pad7) * 100) / 100;

      this.maintenanceIron1h = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency1h, 1, 3);
      this.maintenanceIron2h = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency2h, 2, 2);
      this.maintenanceIron6h = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency6h, 6, 1.35);
      this.maintenanceIron10h = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency10h, 10, 1.15);
      this.maintenanceIron22h = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency22h, 22, 1);
      this.maintenanceIron5pa = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency5pa, 1, 1.8);
      this.maintenanceIron10pa = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency10pa, 2, 1.6);
      this.maintenanceIron30pa = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency30pa, 6, 1.35);
      this.maintenanceIron50pa = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency50pa, 10, 1.15);
      this.maintenanceIron100pa = this.calcMaintenance(output[this.levelMine].iron, this.totalFrequency100pa, 22, 1);

      this.costMaintenanceIron1h = Math.round((this.maintenanceIron1h * this.ironValue) * 100) / 100;
      this.costMaintenanceIron2h = Math.round((this.maintenanceIron2h * this.ironValue) * 100) / 100;
      this.costMaintenanceIron6h = Math.round((this.maintenanceIron6h * this.ironValue) * 100) / 100;
      this.costMaintenanceIron10h = Math.round((this.maintenanceIron10h * this.ironValue) * 100) / 100;
      this.costMaintenanceIron22h = Math.round((this.maintenanceIron22h * this.ironValue) * 100) / 100;
      this.costMaintenanceIron5pa = Math.round((this.maintenanceIron5pa * this.ironValue) * 100) / 100;
      this.costMaintenanceIron10pa = Math.round((this.maintenanceIron10pa * this.ironValue) * 100) / 100;
      this.costMaintenanceIron30pa = Math.round((this.maintenanceIron30pa * this.ironValue) * 100) / 100;
      this.costMaintenanceIron50pa = Math.round((this.maintenanceIron50pa * this.ironValue) * 100) / 100;
      this.costMaintenanceIron100pa = Math.round((this.maintenanceIron100pa * this.ironValue) * 100) / 100;

      this.maintenanceStone1h = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency1h, 1, 3);
      this.maintenanceStone2h = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency2h, 2, 2);
      this.maintenanceStone6h = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency6h, 6, 1.35);
      this.maintenanceStone10h = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency10h, 10, 1.15);
      this.maintenanceStone22h = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency22h, 22, 1);
      this.maintenanceStone5pa = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency5pa, 1, 1.8);
      this.maintenanceStone10pa = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency10pa, 2, 1.6);
      this.maintenanceStone30pa = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency30pa, 6, 1.35);
      this.maintenanceStone50pa = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency50pa, 10, 1.15);
      this.maintenanceStone100pa = this.calcMaintenance(output[this.levelMine].stone, this.totalFrequency100pa, 22, 1);

      this.costMaintenanceStone1h = Math.round((this.maintenanceStone1h * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone2h = Math.round((this.maintenanceStone2h * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone6h = Math.round((this.maintenanceStone6h * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone10h = Math.round((this.maintenanceStone10h * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone22h = Math.round((this.maintenanceStone22h * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone5pa = Math.round((this.maintenanceStone5pa * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone10pa = Math.round((this.maintenanceStone10pa * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone30pa = Math.round((this.maintenanceStone30pa * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone50pa = Math.round((this.maintenanceStone50pa * this.stoneValue) * 100) / 100;
      this.costMaintenanceStone100pa = Math.round((this.maintenanceStone100pa * this.stoneValue) * 100) / 100;

      this.costSalary1h = this.calcPay(this.salaryValue, 1, 3);
      this.costSalary2h = this.calcPay(this.salaryValue, 2, 2);
      this.costSalary6h = this.calcPay(this.salaryValue, 6, 1.35);
      this.costSalary10h = this.calcPay(this.salaryValue, 10, 1.15);
      this.costSalary22h = this.calcPay(this.salaryValue, 22, 1);
      this.costSalary5pa = this.calcPay(this.salaryValue, 1, 1.8);
      this.costSalary10pa = this.calcPay(this.salaryValue, 2, 1.6);
      this.costSalary30pa = this.calcPay(this.salaryValue, 6, 1.35);
      this.costSalary50pa = this.calcPay(this.salaryValue, 10, 1.15);
      this.costSalary100pa = this.calcPay(this.salaryValue, 22, 1);

      this.costProduction1h = Math.round((this.costMaintenanceIron1h + this.costMaintenanceStone1h + this.costSalary1h) * 100) / 100;
      this.costProduction2h = Math.round((this.costMaintenanceIron2h + this.costMaintenanceStone2h + this.costSalary2h) * 100) / 100;
      this.costProduction6h = Math.round((this.costMaintenanceIron6h + this.costMaintenanceStone6h + this.costSalary6h) * 100) / 100;
      this.costProduction10h = Math.round((this.costMaintenanceIron10h + this.costMaintenanceStone10h + this.costSalary10h) * 100) / 100;
      this.costProduction22h = Math.round((this.costMaintenanceIron22h + this.costMaintenanceStone22h + this.costSalary22h) * 100) / 100;
      this.costProduction5pa = Math.round((this.costMaintenanceIron5pa + this.costMaintenanceStone5pa + this.costSalary5pa) * 100) / 100;
      this.costProduction10pa = Math.round((this.costMaintenanceIron10pa + this.costMaintenanceStone10pa + this.costSalary10pa) * 100) / 100;
      this.costProduction30pa = Math.round((this.costMaintenanceIron30pa + this.costMaintenanceStone30pa + this.costSalary30pa) * 100) / 100;
      this.costProduction50pa = Math.round((this.costMaintenanceIron50pa + this.costMaintenanceStone50pa + this.costSalary50pa) * 100) / 100;
      this.costProduction100pa = Math.round((this.costMaintenanceIron100pa + this.costMaintenanceStone100pa + this.costSalary100pa) * 100) / 100;

      this.totalProd1h = this.getProd(this.totalFrequency2h, output[this.levelMine].output, 1, 3);
      this.totalProd2h = this.getProd(this.totalFrequency6h, output[this.levelMine].output, 2, 2);
      this.totalProd6h = this.getProd(this.totalFrequency6h, output[this.levelMine].output, 6, 1.35);
      this.totalProd10h = this.getProd(this.totalFrequency10h, output[this.levelMine].output, 10, 1.15);
      this.totalProd22h = this.getProd(this.totalFrequency22h, output[this.levelMine].output, 22, 1);
      this.totalProd5pa = this.getProd(this.totalFrequency5pa, output[this.levelMine].output, 1, 1.8);
      this.totalProd10pa = this.getProd(this.totalFrequency10pa, output[this.levelMine].output, 2, 1.6);
      this.totalProd30pa = this.getProd(this.totalFrequency30pa, output[this.levelMine].output, 6, 1.35);
      this.totalProd50pa = this.getProd(this.totalFrequency50pa, output[this.levelMine].output, 10, 1.15);
      this.totalProd100pa = this.getProd(this.totalFrequency100pa, output[this.levelMine].output, 22, 1);

      this.valueProd1h = Math.round((this.totalProd1h * prodValue) * 100) / 100;
      this.valueProd2h = Math.round((this.totalProd2h * prodValue) * 100) / 100;
      this.valueProd6h = Math.round((this.totalProd6h * prodValue) * 100) / 100;
      this.valueProd10h = Math.round((this.totalProd10h * prodValue) * 100) / 100;
      this.valueProd22h = Math.round((this.totalProd22h * prodValue) * 100) / 100;
      this.valueProd5pa = Math.round((this.totalProd5pa * prodValue) * 100) / 100;
      this.valueProd10pa = Math.round((this.totalProd10pa * prodValue) * 100) / 100;
      this.valueProd30pa = Math.round((this.totalProd30pa * prodValue) * 100) / 100;
      this.valueProd50pa = Math.round((this.totalProd50pa * prodValue) * 100) / 100;
      this.valueProd100pa = Math.round((this.totalProd100pa * prodValue) * 100) / 100;

      this.benefit1h= Math.round((this.valueProd1h - this.costProduction1h) * 100) / 100;
      this.benefit2h= Math.round((this.valueProd2h - this.costProduction2h) * 100) / 100;
      this.benefit6h= Math.round((this.valueProd6h - this.costProduction6h) * 100) / 100;
      this.benefit10h= Math.round((this.valueProd10h - this.costProduction10h) * 100) / 100;
      this.benefit22h= Math.round((this.valueProd22h - this.costProduction22h) * 100) / 100;
      this.benefit5pa= Math.round((this.valueProd5pa - this.costProduction5pa) * 100) / 100;
      this.benefit10pa= Math.round((this.valueProd10pa - this.costProduction10pa) * 100) / 100;
      this.benefit30pa= Math.round((this.valueProd30pa - this.costProduction30pa) * 100) / 100;
      this.benefit50pa= Math.round((this.valueProd50pa - this.costProduction50pa) * 100) / 100;
      this.benefit100pa= Math.round((this.valueProd100pa - this.costProduction100pa) * 100) / 100;

      this.investmentTotalWeb = Math.round((this.costProduction1h + this.costProduction2h + this.costProduction6h + this.costProduction10h + this.costProduction22h) * 100) / 100;
      this.investmentTotalMob = Math.round((this.costProduction5pa + this.costProduction10pa + this.costProduction30pa + this.costProduction50pa + this.costProduction100pa) * 100) / 100;
      this.investmentTotal = Math.round((this.investmentTotalWeb + this.investmentTotalMob) * 100) / 100;

      this.profitTotalWeb = Math.round((this.benefit1h + this.benefit2h + this.benefit6h + this.benefit10h + this.benefit22h) * 100) / 100;
      this.profitTotalMob = Math.round((this.benefit5pa + this.benefit10pa + this.benefit30pa + this.benefit50pa + this.benefit100pa) * 100) / 100;
      this.profitTotal = Math.round((this.profitTotalWeb + this.profitTotalMob) * 100) / 100;

      this.totalFrequencyNoSkills1h = this.getSkillsMin(this.totalFrequency1h);
      this.totalFrequencyNoSkills2h = this.getSkillsMin(this.totalFrequency2h);
      this.totalFrequencyNoSkills6h = this.getSkillsMin(this.totalFrequency6h);
      this.totalFrequencyNoSkills10h = this.getSkillsMin(this.totalFrequency10h);
      this.totalFrequencyNoSkills22h = this.getSkillsMin(this.totalFrequency22h);
      this.totalFrequencyNoSkills5pa = this.getSkillsMin(this.totalFrequency5pa);
      this.totalFrequencyNoSkills10pa = this.getSkillsMin(this.totalFrequency10pa);
      this.totalFrequencyNoSkills30pa = this.getSkillsMin(this.totalFrequency30pa);
      this.totalFrequencyNoSkills50pa = this.getSkillsMin(this.totalFrequency50pa);
      this.totalFrequencyNoSkills100pa = this.getSkillsMin(this.totalFrequency100pa);
      this.costSalaryNoSkills1h = this.getSkillsMin(this.costSalary1h);
      this.costSalaryNoSkills2h = this.getSkillsMin(this.costSalary2h);
      this.costSalaryNoSkills6h = this.getSkillsMin(this.costSalary6h);
      this.costSalaryNoSkills10h = this.getSkillsMin(this.costSalary10h);
      this.costSalaryNoSkills22h = this.getSkillsMin(this.costSalary22h);
      this.costSalaryNoSkills5pa = this.getSkillsMin(this.costSalary5pa);
      this.costSalaryNoSkills10pa = this.getSkillsMin(this.costSalary10pa);
      this.costSalaryNoSkills30pa = this.getSkillsMin(this.costSalary30pa);
      this.costSalaryNoSkills50pa = this.getSkillsMin(this.costSalary50pa);
      this.costSalaryNoSkills100pa = this.getSkillsMin(this.costSalary100pa);
      this.maintenanceIronNoSkills1h = this.getSkillsMin(this.maintenanceIron1h);
      this.maintenanceIronNoSkills2h = this.getSkillsMin(this.maintenanceIron2h);
      this.maintenanceIronNoSkills6h = this.getSkillsMin(this.maintenanceIron6h);
      this.maintenanceIronNoSkills10h = this.getSkillsMin(this.maintenanceIron10h);
      this.maintenanceIronNoSkills22h = this.getSkillsMin(this.maintenanceIron22h);
      this.maintenanceIronNoSkills5pa = this.getSkillsMin(this.maintenanceIron5pa);
      this.maintenanceIronNoSkills10pa = this.getSkillsMin(this.maintenanceIron10pa);
      this.maintenanceIronNoSkills30pa = this.getSkillsMin(this.maintenanceIron30pa);
      this.maintenanceIronNoSkills50pa = this.getSkillsMin(this.maintenanceIron50pa);
      this.maintenanceIronNoSkills100pa = this.getSkillsMin(this.maintenanceIron100pa);
      this.costMaintenanceIronNoSkills1h = this.getSkillsMin(this.costMaintenanceIron1h);
      this.costMaintenanceIronNoSkills2h = this.getSkillsMin(this.costMaintenanceIron2h);
      this.costMaintenanceIronNoSkills6h = this.getSkillsMin(this.costMaintenanceIron6h);
      this.costMaintenanceIronNoSkills10h = this.getSkillsMin(this.costMaintenanceIron10h);
      this.costMaintenanceIronNoSkills22h = this.getSkillsMin(this.costMaintenanceIron22h);
      this.costMaintenanceIronNoSkills5pa = this.getSkillsMin(this.costMaintenanceIron5pa);
      this.costMaintenanceIronNoSkills10pa = this.getSkillsMin(this.costMaintenanceIron10pa);
      this.costMaintenanceIronNoSkills30pa = this.getSkillsMin(this.costMaintenanceIron30pa);
      this.costMaintenanceIronNoSkills50pa = this.getSkillsMin(this.costMaintenanceIron50pa);
      this.costMaintenanceIronNoSkills100pa = this.getSkillsMin(this.costMaintenanceIron100pa);
      this.maintenanceStoneNoSkills1h = this.getSkillsMin(this.maintenanceStone1h);
      this.maintenanceStoneNoSkills2h = this.getSkillsMin(this.maintenanceStone2h);
      this.maintenanceStoneNoSkills6h = this.getSkillsMin(this.maintenanceStone6h);
      this.maintenanceStoneNoSkills10h = this.getSkillsMin(this.maintenanceStone10h);
      this.maintenanceStoneNoSkills22h = this.getSkillsMin(this.maintenanceStone22h);
      this.maintenanceStoneNoSkills5pa = this.getSkillsMin(this.maintenanceStone5pa);
      this.maintenanceStoneNoSkills10pa = this.getSkillsMin(this.maintenanceStone10pa);
      this.maintenanceStoneNoSkills30pa = this.getSkillsMin(this.maintenanceStone30pa);
      this.maintenanceStoneNoSkills50pa = this.getSkillsMin(this.maintenanceStone50pa);
      this.maintenanceStoneNoSkills100pa = this.getSkillsMin(this.maintenanceStone100pa);
      this.costMaintenanceStoneNoSkills1h = this.getSkillsMin(this.costMaintenanceStone1h);
      this.costMaintenanceStoneNoSkills2h = this.getSkillsMin(this.costMaintenanceStone2h);
      this.costMaintenanceStoneNoSkills6h = this.getSkillsMin(this.costMaintenanceStone6h);
      this.costMaintenanceStoneNoSkills10h = this.getSkillsMin(this.costMaintenanceStone10h);
      this.costMaintenanceStoneNoSkills22h = this.getSkillsMin(this.costMaintenanceStone22h);
      this.costMaintenanceStoneNoSkills5pa = this.getSkillsMin(this.costMaintenanceStone5pa);
      this.costMaintenanceStoneNoSkills10pa = this.getSkillsMin(this.costMaintenanceStone10pa);
      this.costMaintenanceStoneNoSkills30pa = this.getSkillsMin(this.costMaintenanceStone30pa);
      this.costMaintenanceStoneNoSkills50pa = this.getSkillsMin(this.costMaintenanceStone50pa);
      this.costMaintenanceStoneNoSkills100pa = this.getSkillsMin(this.costMaintenanceStone100pa);
      this.costProductionNoSkills1h = this.getSkillsMin(this.costProduction1h);
      this.costProductionNoSkills2h = this.getSkillsMin(this.costProduction2h);
      this.costProductionNoSkills6h = this.getSkillsMin(this.costProduction6h);
      this.costProductionNoSkills10h = this.getSkillsMin(this.costProduction10h);
      this.costProductionNoSkills22h = this.getSkillsMin(this.costProduction22h);
      this.costProductionNoSkills5pa = this.getSkillsMin(this.costProduction5pa);
      this.costProductionNoSkills10pa = this.getSkillsMin(this.costProduction10pa);
      this.costProductionNoSkills30pa = this.getSkillsMin(this.costProduction30pa);
      this.costProductionNoSkills50pa = this.getSkillsMin(this.costProduction50pa);
      this.costProductionNoSkills100pa = this.getSkillsMin(this.costProduction100pa);
      this.totalProdNoSkills1h = this.getSkillsMin(this.totalProd1h);
      this.totalProdNoSkills2h = this.getSkillsMin(this.totalProd2h);
      this.totalProdNoSkills6h = this.getSkillsMin(this.totalProd6h);
      this.totalProdNoSkills10h = this.getSkillsMin(this.totalProd10h);
      this.totalProdNoSkills22h = this.getSkillsMin(this.totalProd22h);
      this.totalProdNoSkills5pa = this.getSkillsMin(this.totalProd5pa);
      this.totalProdNoSkills10pa = this.getSkillsMin(this.totalProd10pa);
      this.totalProdNoSkills30pa = this.getSkillsMin(this.totalProd30pa);
      this.totalProdNoSkills50pa = this.getSkillsMin(this.totalProd50pa);
      this.totalProdNoSkills100pa = this.getSkillsMin(this.totalProd100pa);
      this.valueProdNoSkills1h = this.getSkillsMin(this.valueProd1h);
      this.valueProdNoSkills2h = this.getSkillsMin(this.valueProd2h);
      this.valueProdNoSkills6h = this.getSkillsMin(this.valueProd6h);
      this.valueProdNoSkills10h = this.getSkillsMin(this.valueProd10h);
      this.valueProdNoSkills22h = this.getSkillsMin(this.valueProd22h);
      this.valueProdNoSkills5pa = this.getSkillsMin(this.valueProd5pa);
      this.valueProdNoSkills10pa = this.getSkillsMin(this.valueProd10pa);
      this.valueProdNoSkills30pa = this.getSkillsMin(this.valueProd30pa);
      this.valueProdNoSkills50pa = this.getSkillsMin(this.valueProd50pa);
      this.valueProdNoSkills100pa = this.getSkillsMin(this.valueProd100pa);
      this.benefitNoSkills1h = this.getSkillsMin(this.benefit1h);
      this.benefitNoSkills2h = this.getSkillsMin(this.benefit2h);
      this.benefitNoSkills6h = this.getSkillsMin(this.benefit6h);
      this.benefitNoSkills10h = this.getSkillsMin(this.benefit10h);
      this.benefitNoSkills22h = this.getSkillsMin(this.benefit22h);
      this.benefitNoSkills5pa = this.getSkillsMin(this.benefit5pa);
      this.benefitNoSkills10pa = this.getSkillsMin(this.benefit10pa);
      this.benefitNoSkills30pa = this.getSkillsMin(this.benefit30pa);
      this.benefitNoSkills50pa = this.getSkillsMin(this.benefit50pa);
      this.benefitNoSkills100pa = this.getSkillsMin(this.benefit100pa);
      this.investmentTotalNoSkillsWeb = this.getSkillsMin(this.investmentTotalWeb);
      this.investmentTotalNoSkillsMob = this.getSkillsMin(this.investmentTotalMob);
      this.investmentTotalNoSkills = this.getSkillsMin(this.investmentTotal);
      this.profitTotalNoSkillsWeb = this.getSkillsMin(this.profitTotalWeb);
      this.profitTotalNoSkillsMob = this.getSkillsMin(this.profitTotalMob);
      this.profitTotalNoSkills = this.getSkillsMin(this.profitTotal);

      this.totalFrequencySkills1h = this.getSkillsMax(this.totalFrequency1h);
      this.totalFrequencySkills2h = this.getSkillsMax(this.totalFrequency2h);
      this.totalFrequencySkills6h = this.getSkillsMax(this.totalFrequency6h);
      this.totalFrequencySkills10h = this.getSkillsMax(this.totalFrequency10h);
      this.totalFrequencySkills22h = this.getSkillsMax(this.totalFrequency22h);
      this.totalFrequencySkills5pa = this.getSkillsMax(this.totalFrequency5pa);
      this.totalFrequencySkills10pa = this.getSkillsMax(this.totalFrequency10pa);
      this.totalFrequencySkills30pa = this.getSkillsMax(this.totalFrequency30pa);
      this.totalFrequencySkills50pa = this.getSkillsMax(this.totalFrequency50pa);
      this.totalFrequencySkills100pa = this.getSkillsMax(this.totalFrequency100pa);
      this.costSalarySkills1h = this.getSkillsMax(this.costSalary1h);
      this.costSalarySkills2h = this.getSkillsMax(this.costSalary2h);
      this.costSalarySkills6h = this.getSkillsMax(this.costSalary6h);
      this.costSalarySkills10h = this.getSkillsMax(this.costSalary10h);
      this.costSalarySkills22h = this.getSkillsMax(this.costSalary22h);
      this.costSalarySkills5pa = this.getSkillsMax(this.costSalary5pa);
      this.costSalarySkills10pa = this.getSkillsMax(this.costSalary10pa);
      this.costSalarySkills30pa = this.getSkillsMax(this.costSalary30pa);
      this.costSalarySkills50pa = this.getSkillsMax(this.costSalary50pa);
      this.costSalarySkills100pa = this.getSkillsMax(this.costSalary100pa);
      this.maintenanceIronSkills1h = this.getSkillsMax(this.maintenanceIron1h);
      this.maintenanceIronSkills2h = this.getSkillsMax(this.maintenanceIron2h);
      this.maintenanceIronSkills6h = this.getSkillsMax(this.maintenanceIron6h);
      this.maintenanceIronSkills10h = this.getSkillsMax(this.maintenanceIron10h);
      this.maintenanceIronSkills22h = this.getSkillsMax(this.maintenanceIron22h);
      this.maintenanceIronSkills5pa = this.getSkillsMax(this.maintenanceIron5pa);
      this.maintenanceIronSkills10pa = this.getSkillsMax(this.maintenanceIron10pa);
      this.maintenanceIronSkills30pa = this.getSkillsMax(this.maintenanceIron30pa);
      this.maintenanceIronSkills50pa = this.getSkillsMax(this.maintenanceIron50pa);
      this.maintenanceIronSkills100pa = this.getSkillsMax(this.maintenanceIron100pa);
      this.costMaintenanceIronSkills1h = this.getSkillsMax(this.costMaintenanceIron1h);
      this.costMaintenanceIronSkills2h = this.getSkillsMax(this.costMaintenanceIron2h);
      this.costMaintenanceIronSkills6h = this.getSkillsMax(this.costMaintenanceIron6h);
      this.costMaintenanceIronSkills10h = this.getSkillsMax(this.costMaintenanceIron10h);
      this.costMaintenanceIronSkills22h = this.getSkillsMax(this.costMaintenanceIron22h);
      this.costMaintenanceIronSkills5pa = this.getSkillsMax(this.costMaintenanceIron5pa);
      this.costMaintenanceIronSkills10pa = this.getSkillsMax(this.costMaintenanceIron10pa);
      this.costMaintenanceIronSkills30pa = this.getSkillsMax(this.costMaintenanceIron30pa);
      this.costMaintenanceIronSkills50pa = this.getSkillsMax(this.costMaintenanceIron50pa);
      this.costMaintenanceIronSkills100pa = this.getSkillsMax(this.costMaintenanceIron100pa);
      this.maintenanceStoneSkills1h = this.getSkillsMax(this.maintenanceStone1h);
      this.maintenanceStoneSkills2h = this.getSkillsMax(this.maintenanceStone2h);
      this.maintenanceStoneSkills6h = this.getSkillsMax(this.maintenanceStone6h);
      this.maintenanceStoneSkills10h = this.getSkillsMax(this.maintenanceStone10h);
      this.maintenanceStoneSkills22h = this.getSkillsMax(this.maintenanceStone22h);
      this.maintenanceStoneSkills5pa = this.getSkillsMax(this.maintenanceStone5pa);
      this.maintenanceStoneSkills10pa = this.getSkillsMax(this.maintenanceStone10pa);
      this.maintenanceStoneSkills30pa = this.getSkillsMax(this.maintenanceStone30pa);
      this.maintenanceStoneSkills50pa = this.getSkillsMax(this.maintenanceStone50pa);
      this.maintenanceStoneSkills100pa = this.getSkillsMax(this.maintenanceStone100pa);
      this.costMaintenanceStoneSkills1h = this.getSkillsMax(this.costMaintenanceStone1h);
      this.costMaintenanceStoneSkills2h = this.getSkillsMax(this.costMaintenanceStone2h);
      this.costMaintenanceStoneSkills6h = this.getSkillsMax(this.costMaintenanceStone6h);
      this.costMaintenanceStoneSkills10h = this.getSkillsMax(this.costMaintenanceStone10h);
      this.costMaintenanceStoneSkills22h = this.getSkillsMax(this.costMaintenanceStone22h);
      this.costMaintenanceStoneSkills5pa = this.getSkillsMax(this.costMaintenanceStone5pa);
      this.costMaintenanceStoneSkills10pa = this.getSkillsMax(this.costMaintenanceStone10pa);
      this.costMaintenanceStoneSkills30pa = this.getSkillsMax(this.costMaintenanceStone30pa);
      this.costMaintenanceStoneSkills50pa = this.getSkillsMax(this.costMaintenanceStone50pa);
      this.costMaintenanceStoneSkills100pa = this.getSkillsMax(this.costMaintenanceStone100pa);
      this.costProductionSkills1h = this.getSkillsMax(this.costProduction1h);
      this.costProductionSkills2h = this.getSkillsMax(this.costProduction2h);
      this.costProductionSkills6h = this.getSkillsMax(this.costProduction6h);
      this.costProductionSkills10h = this.getSkillsMax(this.costProduction10h);
      this.costProductionSkills22h = this.getSkillsMax(this.costProduction22h);
      this.costProductionSkills5pa = this.getSkillsMax(this.costProduction5pa);
      this.costProductionSkills10pa = this.getSkillsMax(this.costProduction10pa);
      this.costProductionSkills30pa = this.getSkillsMax(this.costProduction30pa);
      this.costProductionSkills50pa = this.getSkillsMax(this.costProduction50pa);
      this.costProductionSkills100pa = this.getSkillsMax(this.costProduction100pa);
      this.totalProdSkills1h = this.getSkillsMax(this.totalProd1h);
      this.totalProdSkills2h = this.getSkillsMax(this.totalProd2h);
      this.totalProdSkills6h = this.getSkillsMax(this.totalProd6h);
      this.totalProdSkills10h = this.getSkillsMax(this.totalProd10h);
      this.totalProdSkills22h = this.getSkillsMax(this.totalProd22h);
      this.totalProdSkills5pa = this.getSkillsMax(this.totalProd5pa);
      this.totalProdSkills10pa = this.getSkillsMax(this.totalProd10pa);
      this.totalProdSkills30pa = this.getSkillsMax(this.totalProd30pa);
      this.totalProdSkills50pa = this.getSkillsMax(this.totalProd50pa);
      this.totalProdSkills100pa = this.getSkillsMax(this.totalProd100pa);
      this.valueProdSkills1h = this.getSkillsMax(this.valueProd1h);
      this.valueProdSkills2h = this.getSkillsMax(this.valueProd2h);
      this.valueProdSkills6h = this.getSkillsMax(this.valueProd6h);
      this.valueProdSkills10h = this.getSkillsMax(this.valueProd10h);
      this.valueProdSkills22h = this.getSkillsMax(this.valueProd22h);
      this.valueProdSkills5pa = this.getSkillsMax(this.valueProd5pa);
      this.valueProdSkills10pa = this.getSkillsMax(this.valueProd10pa);
      this.valueProdSkills30pa = this.getSkillsMax(this.valueProd30pa);
      this.valueProdSkills50pa = this.getSkillsMax(this.valueProd50pa);
      this.valueProdSkills100pa = this.getSkillsMax(this.valueProd100pa);
      this.benefitSkills1h = this.getSkillsMax(this.benefit1h);
      this.benefitSkills2h = this.getSkillsMax(this.benefit2h);
      this.benefitSkills6h = this.getSkillsMax(this.benefit6h);
      this.benefitSkills10h = this.getSkillsMax(this.benefit10h);
      this.benefitSkills22h = this.getSkillsMax(this.benefit22h);
      this.benefitSkills5pa = this.getSkillsMax(this.benefit5pa);
      this.benefitSkills10pa = this.getSkillsMax(this.benefit10pa);
      this.benefitSkills30pa = this.getSkillsMax(this.benefit30pa);
      this.benefitSkills50pa = this.getSkillsMax(this.benefit50pa);
      this.benefitSkills100pa = this.getSkillsMax(this.benefit100pa);
      this.investmentTotalSkillsWeb = this.getSkillsMax(this.investmentTotalWeb);
      this.investmentTotalSkillsMob = this.getSkillsMax(this.investmentTotalMob);
      this.investmentTotalSkills = this.getSkillsMax(this.investmentTotal);
      this.profitTotalSkillsWeb = this.getSkillsMax(this.profitTotalWeb);
      this.profitTotalSkillsMob = this.getSkillsMax(this.profitTotalMob);
      this.profitTotalSkills = this.getSkillsMax(this.profitTotal);
    },
    getOutputByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].output;
    },
    getOptimumByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].optimum;
    },
    getProdValue(mine) {
      switch (mine) {
        case 'iron':
          return this.ironValue;
        case 'stone':
          return this.stoneValue;
        case 'gold':
          return 1;
        case 'clay':
          return this.clayValue;
        case 'salt':
          return this.saltValue;
      }
    },
    getProd(miners, level, hourly, ratio) {
      let result = miners / 22;
      result = Math.round((result * hourly * ratio * level) * 100) / 100;
      return result;
    },
    calcMaintenance(maintenance, miners, hourly, ratio) {
      let result = maintenance / 22;
      result = Math.round(((result * hourly * ratio * miners) / 100) * 100) / 100;
      return result;
    },
    calcPay(pay, hourly, ratio) {
      let result = pay / 22;
      result = Math.floor((result * hourly * ratio) * 100) / 100;
      return result;
    },
    getSkillsMin(value) {
      return Math.round((value - (value * 0.15)) * 100) /100;
    },
    getSkillsMax(value) {
      return Math.round((value * 1.10) * 100) /100;
    },
    arrayOfValues(min, max) {
      let values = [];
      for (let i = min; i <= max; i++) {
        values.push(i);
      }
      return values;
    },
    reset(){

    }
  },
  watch: {
    typeMine() {
      this.levelMine = this.getOptimumByMine(this.typeMine);
    }
  }
}
</script>
<style>
.mines-hebdo {
  margin-top: 20px;
  text-align: center;
}
.mines-hebdo thead {
  font-size: 18px;
  color: #4091c9;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.mines-hebdo tbody td {
  padding: 2px;
  font-size: 12px;
  vertical-align: middle;
}
.mines-hebdo select {
  width: 90px;
  text-align: center;
  border-radius: 5px;
}
.mines-hebdo input {
  width: 60px;
  height: 24px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
}
.mines-hebdo button {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.mines-hebdo .table-label {
  font-weight: 500;
}
.mines-hebdo input[type=number] {
  -moz-appearance: textfield;
}
.mines-hebdo input::-webkit-inner-spin-button,
.mines-hebdo input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin:0;
}
.mines-hebdo input::-o-inner-spin-button,
.mines-hebdo input::-o-outer-spin-button {
  -o-appearance: none;
  margin:0
}
.mines-hebdo .td-right {
  border-right: 1px solid #4091c9;
}
.mines-hebdo .tr-left {
  border-left: 1px solid #4091c9;
}
.mines-hebdo .tr-bottom {
  border-bottom: 1px solid #4091c9;
}
.mines-hebdo .td-top {
  border-top: 1px solid #4091c9;
}
</style>
