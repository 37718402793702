<template>
  <div class="faq-list card">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFaqForm" />
      </span>
    </div>
    <div class="card-body">
      <draggable-component :list="faqs" :disabled="!enabled" item-key="name" class="list-group" ghost-class="ghost" @start="dragging = true" @end="checkMove">
        <template #item="{ element }">
          <div class="list-group-item items-order-faqs" :class="{ 'not-draggable': !enabled }" :data-uuid="element.uuid">
            {{ element.title[$i18n.locale] }}
            <span class="actions">
              <font-awesome-icon icon="fa-solid fa-power-off" :class="element.publish ? 'online' : 'offline'" @click="publish(!element.publish, element.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', element)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFaqForm" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-trash-alt" @click="form('delete', element.uuid)" class="modal-icon-action" />
            </span>
          </div>
        </template>
      </draggable-component>
    </div>
  </div>

  <div class="modal fade" id="modalFaqForm" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ labelModal }}</h1>
        </div>
        <div class="modal-body">
          <faq-form v-if="'add' === mode || 'edit' === mode" :faq="faq" :mode="mode"></faq-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableComponent from "vuedraggable/src/vuedraggable";
import { mapState } from 'vuex'
import FaqForm from "@/Admin/Faq/components/form/FaqForm";

export default {
  name: "FaqList",
  components: {
    DraggableComponent,
    FaqForm
  },
  computed: {
    ...mapState('adminFaqs', {
        faqs: 'faqs'
      }
    )
  },
  data() {
    return {
      mode: null,
      labelModal: '',
      faq: {},
      enabled: true,
      dragging: false
    }
  },
  mounted() {
    this.$store.dispatch('adminFaqs/getFaqs');
  },
  methods: {
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.faq = {};
          this.labelModal = 'Ajouter une FAQ';
          break;
        case 'edit':
          this.faq = datas;
          this.labelModal = 'Modifier une FAQ';
          break;
        case 'delete':
          this.$store.dispatch('adminFaqs/deleteFaq', {
            uuid: datas,
          });
          break;
      }
    },
    publish(state, uuid) {
      this.$store.dispatch('adminFaqs/publishFaq', {
        uuid: uuid,
        state: state,
      });
    },
    checkMove: function() {
      this.dragging = false;
      let items = document.getElementsByClassName('items-order-faqs');
      let order = [];

      Array.from(items).forEach((item) => {
        order.push(item.getAttribute('data-uuid'));
      });

      this.$store.dispatch('adminFaqs/setOrder', {
        uuids: order,
      });
    },
  }
};
</script>

<style>
.faq-list {
  margin-top: 20px;
  border-radius: 0!important;
  border: none!important;
}
.faq-list .card-header {
  background-color: transparent;
}
.faq-list .card-header .actions {
  float: right;
  color: #4091c9;
}
.faq-list .card-header .actions .modal-icon-action {
  cursor: pointer;
}
.faq-list .list-group {
  border-radius: 0!important;
}
.faq-list .list-group .actions {
  float: right;
  color: #4091c9;
}
.faq-list .list-group .actions .modal-icon-action {
  cursor: pointer;
}
.faq-list .actions .modal-icon-action.online{
  color: #008000;
}
.faq-list .actions .modal-icon-action.offline {
  color: #ff0000;
}
#modalFaqForm .modal-header {
  padding: 5px
}
#modalFaqForm .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
