const mutations = {
  setCraftspeoples: function (state, craftspeoples) {
    state.craftspeoples = craftspeoples;
  },
  setProductions: function (state, productions) {
    state.productions = productions;
  },
  setProduction: function (state, production) {
    state.production = production;
  },
}

export default mutations;
