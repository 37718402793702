const mutations = {
  setActualities: function (state, actualities) {
    state.actualities = actualities;
  },
  setDeploys: function (state, deploys) {
    state.deploys = deploys;
  },
}

export default mutations;
