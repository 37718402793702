<template>
  <form class="translate-form">
    <div class="row">
      <p>Texte</p>
      <code class="overflow-auto" :class="translate.type">{{translate.fr}}</code>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.en')}} (en)</span>
        <textarea v-model="en" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="en" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.it')}} (it)</span>
        <textarea v-model="it" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="it" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.de')}} (de)</span>
        <textarea v-model="de" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="de" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.es')}} (es)</span>
        <textarea v-model="es" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="es" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.bu')}} (bu)</span>
        <textarea v-model="bu" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="bu" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.nl')}} (nl)</span>
        <textarea v-model="nl" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="nl" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.pl')}} (pl)</span>
        <textarea v-model="pl" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="pl" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.pt')}} (pt)</span>
        <textarea v-model="pt" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="pt" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.da')}} (da)</span>
        <textarea v-model="da" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="da" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.el')}} (el)</span>
        <textarea v-model="el" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="el" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.ro')}} (ro)</span>
        <textarea v-model="ro" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="ro" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.ru')}} (ru)</span>
        <textarea v-model="ru" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="ru" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.tr')}} (tr)</span>
        <textarea v-model="tr" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="tr" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.ct')}} (ct)</span>
        <textarea v-model="ct" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="ct" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.sl')}} (sl)</span>
        <textarea v-model="sl" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="sl" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.hr')}} (hr)</span>
        <textarea v-model="hr" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="hr" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.hu')}} (hu)</span>
        <textarea v-model="hu" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="hu" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.cz')}} (cz)</span>
        <textarea v-model="cz" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="cz" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.se')}} (se)</span>
        <textarea v-model="se" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="se" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.sr')}} (sr)</span>
        <textarea v-model="sr" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="sr" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.fi')}} (fi)</span>
        <textarea v-model="fi" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="fi" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.al')}} (al)</span>
        <textarea v-model="al" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="al" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.ba')}} (ba)</span>
        <textarea v-model="ba" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="ba" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
    <div class="row">
      <div class="input-group input-group-sm mb-3">
        <span class="input-group-text col-2">{{$t('player.translates.languages.eu')}} (eu)</span>
        <textarea v-model="eu" v-if="translate.type === 'ckeditor'" class="form-control"></textarea>
        <input type="text" v-model="eu" v-if="translate.type === 'input'" class="form-control" id="name">
      </div>
    </div>
  </form>
  <div class="translate-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
export default {
  name: 'TranslateForm',
  props: {
    translate: Object
  },
  data() {
    return {
      en: null,
      it: null,
      de: null,
      es: null,
      bu: null,
      nl: null,
      pl: null,
      pt: null,
      da: null,
      el: null,
      ro: null,
      ru: null,
      tr: null,
      ct: null,
      sl: null,
      hr: null,
      hu: null,
      cz: null,
      se: null,
      sr: null,
      fi: null,
      al: null,
      ba: null,
      eu: null,
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.en = undefined !== this.translate.en ? this.translate.en : '';
      this.it = undefined !== this.translate.it ? this.translate.it : '';
    },
    save() {
      this.$store.dispatch('adminTranslates/editTranslate', {
        uuid: this.translate.uuid,
        en: "" !== this.en ? this.en : null,
        it: "" !== this.it ? this.it : null,
        de: "" !== this.de ? this.de : null,
        es: "" !== this.es ? this.es : null,
        bu: "" !== this.bu ? this.bu : null,
        nl: "" !== this.nl ? this.nl : null,
        pl: "" !== this.pl ? this.pl : null,
        pt: "" !== this.pt ? this.pt : null,
        da: "" !== this.da ? this.da : null,
        el: "" !== this.el ? this.el : null,
        ro: "" !== this.ro ? this.ro : null,
        ru: "" !== this.ru ? this.ru : null,
        tr: "" !== this.tr ? this.tr : null,
        ct: "" !== this.ct ? this.ct : null,
        sl: "" !== this.sl ? this.sl : null,
        hr: "" !== this.hr ? this.hr : null,
        hu: "" !== this.hu ? this.hu : null,
        cz: "" !== this.cz ? this.cz : null,
        se: "" !== this.se ? this.se : null,
        sr: "" !== this.sr ? this.sr : null,
        fi: "" !== this.fi ? this.fi : null,
        al: "" !== this.al ? this.al : null,
        ba: "" !== this.ba ? this.ba : null,
        eu: "" !== this.eu ? this.eu : null,
      });
    }
  },
  watch: {
    translate() {
      this.getDatas();
    }
  },
}
</script>
<style>
.translate-form code.ckeditor {
  height: 200px;
  margin-bottom: 20px;
}
.translate-form code.input {
  margin-bottom: 20px;
}
.translate-form textarea {
  min-height: 200px!important;
}
.translate-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.translate-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
