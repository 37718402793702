<template>
  <div class="connect-form col-6">
    <h2>{{$t('user.connect')}}</h2>
    <form>
      <div class="form-group">
        <label>Identifiant</label>
        <input type="text" v-model="login" class="form-control" autocomplete="off">
      </div>
      <div class="form-group">
        <label>Mot de passe</label>
        <input type="password" v-model="password" class="form-control" autocomplete="off">
      </div>
      <input type="hidden" name="date" v-model="date">
    </form>
    <div class="button">
      <button @click="save();track();">Connexion</button>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UserConnexion',
  data() {
    return {
      login: null,
      password: null,
      date: null
    }
  },
  methods: {
    save() {
      let payload = {
        login: this.login,
        password: this.password
      };

      this.$store.dispatch('users/connexion', payload);

      setTimeout(() => {
        let role = this.$store.state.users.user.role;

        if('ROLE_ADMIN' === role) {
          this.$router.push('/admin');
        } else if('ROLE_CONTRIBUTOR' === role) {
          this.$router.push('/player');
        } else if('ROLE_USER' === role) {
          this.$router.push('/');
        } else {
          this.$store.commit('users/logout');
          this.$router.push('/');
        }
      }, 1000);
    },
    track() {
      this.$store.dispatch('users/tracking', {
        page: 'open-users',
        component: 'connexion',
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>

<style>
.connect-form {
  margin-top: 100px!important;
}
.connect-form h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}
.connect-form form {
  margin-top: 20px;
}
.connect-form .form-group label {
  display: block;
  text-align: center;
  font-weight: 500;
}
.connect-form input {
  text-align: center;
}
.connect-form .button {
  text-align: center;
}
.connect-form button {
  margin-top: 83px;
  width: 200px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
</style>
