<template>
  <div class="books">
    <ul class="list-group">
      <li v-for="(book, index) in books" :key="index" @click="load(book)" class="list-group-item d-flex justify-content-between align-items-center modal-label" data-bs-toggle="modal" data-bs-target="#bookModal">
        {{ book.name[$i18n.locale] }}
        <font-awesome-icon icon="fa-solid fa-hand-point-up" class="modal-icon-action"/>
      </li>
    </ul>

    <div class="modal fade" id="bookModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="bookModalLabel">{{book.name?.[$i18n.locale]}}</h1>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <p class="book-label"><span>{{ $t('university.author') }} : </span>{{book.author?.name[$i18n.locale]}}</p>
                <p class="book-label"><span>{{ $t('university.prerequisite') }} : </span>{{book.prerequisite?.[$i18n.locale]}}</p>
              </div>
              <div class="col-6">
                <p class="book-label"><span>{{ $t('university.language') }} : </span>{{book.language?.name[$i18n.locale]}}</p>
                <p class="book-label"><span>{{ $t('university.utility') }} : </span>{{book.utility?.[$i18n.locale]}}</p>
              </div>
            </div>
            <div class="row book-province">
              <p class="book-label"><span>{{ $t('university.provinces') }} :</span></p>
              <ul>
                <li v-for="(province, index) in provinces" :key="index">{{province.name}} ({{province.kingdom.name}})</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UniversityBooks',
  data() {
    return {
      book: {},
      provinces: []
    }
  },
  computed: {
    ...mapState('university', {
        books: 'books'
      }
    )
  },
  mounted() {
    this.$store.dispatch('university/getBooks');
  },
  methods: {
    load(book) {
      this.book = book;
      this.provinces = book.provinces;
      this.$store.dispatch('university/tracking', {
        page: 'open-university',
        component: 'book|uuid:'+book.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  },
}
</script>

<style>
.books {
  margin-top: 20px;
}
.books .modal-label {
  color: #4091c9!important;
  text-transform: uppercase;
  cursor: pointer;
}
.books .modal-icon-action {
  color: #4091c9!important;
  font-size: 20px;
}
.books .modal-title {
  color: #4091c9;
  text-transform: uppercase;
  font-weight: 400;
}
.books .book-label {
  font-size: 14px;
}
.books .book-label span {
  font-weight: bold;
  text-transform: uppercase;
}
.books .book-province ul {
  font-size: 14px;
  list-style: disclosure-closed none;
  margin-left: 50px;
}
</style>
