<template>
  <div class="content container-fluid admin admin-products">
    <navigation-admin></navigation-admin>

    <div class="filters">
      <h2>{{ $t('products.filters') }}</h2>

      <div class="row">
        <div class="form-group col-4">
          <label for="name">{{ $t('products.filter_name') }} :</label>
          <input type="text" v-model="name" class="form-control form-control-sm" id="name">
        </div>
        <div class="form-group col-4">
          <label for="category">{{ $t('products.filter_category') }} :</label>
          <select id="category" v-model="category" class="form-control form-control-sm">
            <option value=""></option>
            <option v-for="(category, index) in categories" :key="index" :value="category.uuid">{{category.name[$i18n.locale]}}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="craftspeople">{{ $t('products.filter_craftspeople') }} :</label>
          <select id="craftspeople" v-model="craftspeople" class="form-control form-control-sm">
            <option value=""></option>
            <option v-for="(craftspeople, index) in craftspeoples" :key="index" :value="craftspeople.uuid">{{craftspeople.name[$i18n.locale]}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <button class="search offset-10 col-2" @click="search()">{{ $t('products.search') }}</button>
      </div>
    </div>
    <products-block :list="products" :name="name" :category="category" :craftspeople="craftspeople"></products-block>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import ProductsBlock from "@/Admin/Products/components/ProductsBlock";
import { mapState } from 'vuex'

export default {
  name: 'ProductsView',
  components: {
    NavigationAdmin,
    ProductsBlock,
  },
  computed: {
    ...mapState('adminProducts', {
        categories: 'categories',
        craftspeoples: 'craftspeoples',
        products: 'products'
      }
    )
  },
  data() {
    return {
      name: null,
      category: null,
      craftspeople: null
    }
  },
  mounted() {
    this.$store.dispatch('adminProducts/getCategories');
    this.$store.dispatch('adminProducts/getCraftspeoples');
  },
  methods: {
    search() {
      if(null === this.name && null === this.category && null === this.craftspeople) {
        this.$store.commit('adminProducts/setProducts', []);
      } else if ('' === this.name && '' === this.category && '' === this.craftspeople) {
        this.$store.commit('adminProducts/setProducts', []);
      } else {
        let payload = {
          name: '' !== this.name ? this.name : null,
          category: '' !== this.category ? this.category : null,
          craftspeople: '' !== this.craftspeople ? this.craftspeople : null
        }
        this.$store.dispatch('adminProducts/searchProducts', payload);
      }
    }
  }
}
</script>

<style>
.admin-products {
  width: 95%!important;
}
.admin-products .filters {
  margin-top: 20px;
  border-bottom: 1px solid #4091c9;
  padding-bottom: 20px;
}
.admin-products .filters h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}
.admin-products .filters .search {
  width: 130px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
