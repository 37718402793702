import state from "@/Open/Faq/store/modules/states";
import getters from "@/Open/Faq/store/modules/getters";
import mutations from "@/Open/Faq/store/modules/mutations";
import actions from "@/Open/Faq/store/modules/actions";

const faqs = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
export default faqs;
