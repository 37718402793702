<template>
  <table class="table table-bordered table-striped mines-revient">
    <thead>
      <tr class="tr-left tr-bottom">
        <td colspan="11" class="td-top td-right">{{$t('mines.titles.ownership')}}</td>
      </tr>
    </thead>
    <tbody>
      <tr class="tr-left">
        <td width="30%" class="table-label td-right">{{$t('mines.type')}}</td>
        <td colspan="10" class="td-right">
          <select v-model="typeMine">
            <option value="iron">{{$t('mines.type_mines.iron')}}</option>
            <option value="stone">{{$t('mines.type_mines.stone')}}</option>
            <option value="gold">{{$t('mines.type_mines.gold')}}</option>
            <option value="clay">{{$t('mines.type_mines.clay')}}</option>
            <option value="salt">{{$t('mines.type_mines.salt')}}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.level') }}</td>
        <td colspan="10" class="td-right">
          <select v-model="levelMine">
            <option v-for="n in arrayOfValues(3, 17)" :key="n" :value="n">{{ n }}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.sales_value') }}</td>
        <td class="table-label">{{$t('mines.salary')}}</td>
        <td><input type="number" v-model="salaryValue"></td>
        <td class="table-label">{{$t('mines.type_mines.iron')}}</td>
        <td><input type="number" v-model="ironValue"></td>
        <td class="table-label">{{$t('mines.type_mines.stone')}}</td>
        <td><input type="number" v-model="stoneValue"></td>
        <td class="table-label">{{$t('mines.type_mines.clay')}}</td>
        <td><input type="number" v-model="clayValue"></td>
        <td class="table-label">{{$t('mines.type_mines.salt')}}</td>
        <td class="td-right"><input type="number" v-model="saltValue"></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.frequency') }}</td>
        <td class="table-label">{{$t('mines.time.1h')}}</td>
        <td class="table-label">{{$t('mines.time.5pa')}}</td>
        <td class="table-label">{{$t('mines.time.2h')}}</td>
        <td class="table-label">{{$t('mines.time.10pa')}}</td>
        <td class="table-label">{{$t('mines.time.6h')}}</td>
        <td class="table-label">{{$t('mines.time.30pa')}}</td>
        <td class="table-label">{{$t('mines.time.10h')}}</td>
        <td class="table-label">{{$t('mines.time.50pa')}}</td>
        <td class="table-label">{{$t('mines.time.22h')}}</td>
        <td class="table-label td-right">{{$t('mines.time.100pa')}}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.format') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td><input type="number" v-model="frequency1h"></td>
        <td><input type="number" v-model="frequency5pa"></td>
        <td><input type="number" v-model="frequency2h"></td>
        <td><input type="number" v-model="frequency10pa"></td>
        <td><input type="number" v-model="frequency6h"></td>
        <td><input type="number" v-model="frequency30pa"></td>
        <td><input type="number" v-model="frequency10h"></td>
        <td><input type="number" v-model="frequency50pa"></td>
        <td><input type="number" v-model="frequency22h"></td>
        <td class="td-right"><input type="number" v-model="frequency100pa"></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td></td>
        <td colspan="10" class="td-right">
          <button @click="calculate">{{ $t('mines.calculate') }}</button>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.frequency') }}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.1h')}} | {{$t('mines.time.5pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.2h')}} | {{$t('mines.time.10pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.6h')}} | {{$t('mines.time.30pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.10h')}} | {{$t('mines.time.50pa')}}</td>
        <td colspan="2" class="table-label td-right">{{$t('mines.time.22h')}} | {{$t('mines.time.100pa')}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.format') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
        <td class="table-label">{{ $t('mines.web') }}</td>
        <td class="table-label td-right">{{ $t('mines.mobile') }}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.without_skills') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_produced') }}</td>
        <td><span>{{prod1h}}</span></td>
        <td class="td-right"><span>{{prod5pa}}</span></td>
        <td><span>{{prod2h}}</span></td>
        <td class="td-right"><span>{{prod10pa}}</span></td>
        <td><span>{{prod6h}}</span></td>
        <td class="td-right"><span>{{prod30pa}}</span></td>
        <td><span>{{prod10h}}</span></td>
        <td class="td-right"><span>{{prod50pa}}</span></td>
        <td><span>{{prod22h}}</span></td>
        <td class="td-right"><span>{{prod100pa}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.value_production') }}</td>
        <td><span>{{prodValue1h}}</span></td>
        <td class="td-right"><span>{{prodValue5pa}}</span></td>
        <td><span>{{prodValue2h}}</span></td>
        <td class="td-right"><span>{{prodValue10pa}}</span></td>
        <td><span>{{prodValue6h}}</span></td>
        <td class="td-right"><span>{{prodValue30pa}}</span></td>
        <td><span>{{prodValue10h}}</span></td>
        <td class="td-right"><span>{{prodValue50pa}}</span></td>
        <td><span>{{prodValue22h}}</span></td>
        <td class="td-right"><span>{{prodValue100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_iron_used') }}</td>
        <td><span>{{ironUsed1h}}</span></td>
        <td class="td-right"><span>{{ironUsed5pa}}</span></td>
        <td><span>{{ironUsed2h}}</span></td>
        <td class="td-right"><span>{{ironUsed10pa}}</span></td>
        <td><span>{{ironUsed6h}}</span></td>
        <td class="td-right"><span>{{ironUsed30pa}}</span></td>
        <td><span>{{ironUsed10h}}</span></td>
        <td class="td-right"><span>{{ironUsed50pa}}</span></td>
        <td><span>{{ironUsed22h}}</span></td>
        <td class="td-right"><span>{{ironUsed100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_iron') }}</td>
        <td><span>{{costIronUsed1h}}</span></td>
        <td class="td-right"><span>{{costIronUsed5pa}}</span></td>
        <td><span>{{costIronUsed2h}}</span></td>
        <td class="td-right"><span>{{costIronUsed10pa}}</span></td>
        <td><span>{{costIronUsed6h}}</span></td>
        <td class="td-right"><span>{{costIronUsed30pa}}</span></td>
        <td><span>{{costIronUsed10h}}</span></td>
        <td class="td-right"><span>{{costIronUsed50pa}}</span></td>
        <td><span>{{costIronUsed22h}}</span></td>
        <td class="td-right"><span>{{costIronUsed100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_stone_used') }}</td>
        <td><span>{{stoneUsed1h}}</span></td>
        <td class="td-right"><span>{{stoneUsed5pa}}</span></td>
        <td><span>{{stoneUsed2h}}</span></td>
        <td class="td-right"><span>{{stoneUsed10pa}}</span></td>
        <td><span>{{stoneUsed6h}}</span></td>
        <td class="td-right"><span>{{stoneUsed30pa}}</span></td>
        <td><span>{{stoneUsed10h}}</span></td>
        <td class="td-right"><span>{{stoneUsed50pa}}</span></td>
        <td><span>{{stoneUsed22h}}</span></td>
        <td class="td-right"><span>{{stoneUsed100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_stone') }}</td>
        <td><span>{{costStoneUsed1h}}</span></td>
        <td class="td-right"><span>{{costStoneUsed5pa}}</span></td>
        <td><span>{{costStoneUsed2h}}</span></td>
        <td class="td-right"><span>{{costStoneUsed10pa}}</span></td>
        <td><span>{{costStoneUsed6h}}</span></td>
        <td class="td-right"><span>{{costStoneUsed30pa}}</span></td>
        <td><span>{{costStoneUsed10h}}</span></td>
        <td class="td-right"><span>{{costStoneUsed50pa}}</span></td>
        <td><span>{{costStoneUsed22h}}</span></td>
        <td class="td-right"><span>{{costStoneUsed100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.wages_paid') }}</td>
        <td><span>{{salary1h}}</span></td>
        <td class="td-right"><span>{{salary5pa}}</span></td>
        <td><span>{{salary2h}}</span></td>
        <td class="td-right"><span>{{salary10pa}}</span></td>
        <td><span>{{salary6h}}</span></td>
        <td class="td-right"><span>{{salary30pa}}</span></td>
        <td><span>{{salary10h}}</span></td>
        <td class="td-right"><span>{{salary50pa}}</span></td>
        <td><span>{{salary22h}}</span></td>
        <td class="td-right"><span>{{salary100pa}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.total_cost_production') }}</td>
        <td><span>{{totalCostProduction1h}}</span></td>
        <td class="td-right"><span>{{totalCostProduction5pa}}</span></td>
        <td><span>{{totalCostProduction2h}}</span></td>
        <td class="td-right"><span>{{totalCostProduction10pa}}</span></td>
        <td><span>{{totalCostProduction6h}}</span></td>
        <td class="td-right"><span>{{totalCostProduction30pa}}</span></td>
        <td><span>{{totalCostProduction10h}}</span></td>
        <td class="td-right"><span>{{totalCostProduction50pa}}</span></td>
        <td><span>{{totalCostProduction22h}}</span></td>
        <td class="td-right"><span>{{totalCostProduction100pa}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.profit') }}</td>
        <td><span>{{benefit1h}}</span></td>
        <td class="td-right"><span>{{benefit5pa}}</span></td>
        <td><span>{{benefit2h}}</span></td>
        <td class="td-right"><span>{{benefit10pa}}</span></td>
        <td><span>{{benefit6h}}</span></td>
        <td class="td-right"><span>{{benefit30pa}}</span></td>
        <td><span>{{benefit10h}}</span></td>
        <td class="td-right"><span>{{benefit50pa}}</span></td>
        <td><span>{{benefit22h}}</span></td>
        <td class="td-right"><span>{{benefit100pa}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{investmentTotalMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotal}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{profitTotalMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotal}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.with_skills_down') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_produced') }}</td>
        <td><span>{{prod1hNoSkills}}</span></td>
        <td class="td-right"><span>{{prod5paNoSkills}}</span></td>
        <td><span>{{prod2hNoSkills}}</span></td>
        <td class="td-right"><span>{{prod10paNoSkills}}</span></td>
        <td><span>{{prod6hNoSkills}}</span></td>
        <td class="td-right"><span>{{prod30paNoSkills}}</span></td>
        <td><span>{{prod10hNoSkills}}</span></td>
        <td class="td-right"><span>{{prod50paNoSkills}}</span></td>
        <td><span>{{prod22hNoSkills}}</span></td>
        <td class="td-right"><span>{{prod100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.value_production') }}</td>
        <td><span>{{prodValue1hNoSkills}}</span></td>
        <td class="td-right"><span>{{prodValue5paNoSkills}}</span></td>
        <td><span>{{prodValue2hNoSkills}}</span></td>
        <td class="td-right"><span>{{prodValue10paNoSkills}}</span></td>
        <td><span>{{prodValue6hNoSkills}}</span></td>
        <td class="td-right"><span>{{prodValue30paNoSkills}}</span></td>
        <td><span>{{prodValue10hNoSkills}}</span></td>
        <td class="td-right"><span>{{prodValue50paNoSkills}}</span></td>
        <td><span>{{prodValue22hNoSkills}}</span></td>
        <td class="td-right"><span>{{prodValue100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_iron_used') }}</td>
        <td><span>{{ironUsed1hNoSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed5paNoSkills}}</span></td>
        <td><span>{{ironUsed2hNoSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed10paNoSkills}}</span></td>
        <td><span>{{ironUsed6hNoSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed30paNoSkills}}</span></td>
        <td><span>{{ironUsed10hNoSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed50paNoSkills}}</span></td>
        <td><span>{{ironUsed22hNoSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_iron') }}</td>
        <td><span>{{costIronUsed1hNoSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed5paNoSkills}}</span></td>
        <td><span>{{costIronUsed2hNoSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed10paNoSkills}}</span></td>
        <td><span>{{costIronUsed6hNoSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed30paNoSkills}}</span></td>
        <td><span>{{costIronUsed10hNoSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed50paNoSkills}}</span></td>
        <td><span>{{costIronUsed22hNoSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_stone_used') }}</td>
        <td><span>{{stoneUsed1hNoSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed5paNoSkills}}</span></td>
        <td><span>{{stoneUsed2hNoSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed10paNoSkills}}</span></td>
        <td><span>{{stoneUsed6hNoSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed30paNoSkills}}</span></td>
        <td><span>{{stoneUsed10hNoSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed50paNoSkills}}</span></td>
        <td><span>{{stoneUsed22hNoSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_stone') }}</td>
        <td><span>{{costStoneUsed1hNoSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed5paNoSkills}}</span></td>
        <td><span>{{costStoneUsed2hNoSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed10paNoSkills}}</span></td>
        <td><span>{{costStoneUsed6hNoSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed30paNoSkills}}</span></td>
        <td><span>{{costStoneUsed10hNoSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed50paNoSkills}}</span></td>
        <td><span>{{costStoneUsed22hNoSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.wages_paid') }}</td>
        <td><span>{{salary1hNoSkills}}</span></td>
        <td class="td-right"><span>{{salary5paNoSkills}}</span></td>
        <td><span>{{salary2hNoSkills}}</span></td>
        <td class="td-right"><span>{{salary10paNoSkills}}</span></td>
        <td><span>{{salary6hNoSkills}}</span></td>
        <td class="td-right"><span>{{salary30paNoSkills}}</span></td>
        <td><span>{{salary10hNoSkills}}</span></td>
        <td class="td-right"><span>{{salary50paNoSkills}}</span></td>
        <td><span>{{salary22hNoSkills}}</span></td>
        <td class="td-right"><span>{{salary100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.total_cost_production') }}</td>
        <td><span>{{totalCostProduction1hNoSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction5paNoSkills}}</span></td>
        <td><span>{{totalCostProduction2hNoSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction10paNoSkills}}</span></td>
        <td><span>{{totalCostProduction6hNoSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction30paNoSkills}}</span></td>
        <td><span>{{totalCostProduction10hNoSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction50paNoSkills}}</span></td>
        <td><span>{{totalCostProduction22hNoSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.profit') }}</td>
        <td><span>{{benefit1hNoSkills}}</span></td>
        <td class="td-right"><span>{{benefit5paNoSkills}}</span></td>
        <td><span>{{benefit2hNoSkills}}</span></td>
        <td class="td-right"><span>{{benefit10paNoSkills}}</span></td>
        <td><span>{{benefit6hNoSkills}}</span></td>
        <td class="td-right"><span>{{benefit30paNoSkills}}</span></td>
        <td><span>{{benefit10hNoSkills}}</span></td>
        <td class="td-right"><span>{{benefit50paNoSkills}}</span></td>
        <td><span>{{benefit22hNoSkills}}</span></td>
        <td class="td-right"><span>{{benefit100paNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalNoSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{ investmentTotalNoSkillsMob }}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotalNoSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalNoSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{ profitTotalNoSkillsMob }}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotalNoSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right"></td>
        <td colspan="10" class="table-label td-right">{{ $t('mines.with_skills_up') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_produced') }}</td>
        <td><span>{{prod1hSkills}}</span></td>
        <td class="td-right"><span>{{prod5paSkills}}</span></td>
        <td><span>{{prod2hSkills}}</span></td>
        <td class="td-right"><span>{{prod10paSkills}}</span></td>
        <td><span>{{prod6hSkills}}</span></td>
        <td class="td-right"><span>{{prod30paSkills}}</span></td>
        <td><span>{{prod10hSkills}}</span></td>
        <td class="td-right"><span>{{prod50paSkills}}</span></td>
        <td><span>{{prod22hSkills}}</span></td>
        <td class="td-right"><span>{{prod100paSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.value_production') }}</td>
        <td><span>{{prodValue1hSkills}}</span></td>
        <td class="td-right"><span>{{prodValue5paSkills}}</span></td>
        <td><span>{{prodValue2hSkills}}</span></td>
        <td class="td-right"><span>{{prodValue10paSkills}}</span></td>
        <td><span>{{prodValue6hSkills}}</span></td>
        <td class="td-right"><span>{{prodValue30paSkills}}</span></td>
        <td><span>{{prodValue10hSkills}}</span></td>
        <td class="td-right"><span>{{prodValue50paSkills}}</span></td>
        <td><span>{{prodValue22hSkills}}</span></td>
        <td class="td-right"><span>{{prodValue100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_iron_used') }}</td>
        <td><span>{{ironUsed1hSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed5paSkills}}</span></td>
        <td><span>{{ironUsed2hSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed10paSkills}}</span></td>
        <td><span>{{ironUsed6hSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed30paSkills}}</span></td>
        <td><span>{{ironUsed10hSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed50paSkills}}</span></td>
        <td><span>{{ironUsed22hSkills}}</span></td>
        <td class="td-right"><span>{{ironUsed100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_iron') }}</td>
        <td><span>{{costIronUsed1hSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed5paSkills}}</span></td>
        <td><span>{{costIronUsed2hSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed10paSkills}}</span></td>
        <td><span>{{costIronUsed6hSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed30paSkills}}</span></td>
        <td><span>{{costIronUsed10hSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed50paSkills}}</span></td>
        <td><span>{{costIronUsed22hSkills}}</span></td>
        <td class="td-right"><span>{{costIronUsed100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.quantity_stone_used') }}</td>
        <td><span>{{stoneUsed1hSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed5paSkills}}</span></td>
        <td><span>{{stoneUsed2hSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed10paSkills}}</span></td>
        <td><span>{{stoneUsed6hSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed30paSkills}}</span></td>
        <td><span>{{stoneUsed10hSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed50paSkills}}</span></td>
        <td><span>{{stoneUsed22hSkills}}</span></td>
        <td class="td-right"><span>{{stoneUsed100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.cost_stone') }}</td>
        <td><span>{{costStoneUsed1hSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed5paSkills}}</span></td>
        <td><span>{{costStoneUsed2hSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed10paSkills}}</span></td>
        <td><span>{{costStoneUsed6hSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed30paSkills}}</span></td>
        <td><span>{{costStoneUsed10hSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed50paSkills}}</span></td>
        <td><span>{{costStoneUsed22hSkills}}</span></td>
        <td class="td-right"><span>{{costStoneUsed100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{ $t('mines.results_ownership.wages_paid') }}</td>
        <td><span>{{salary1hSkills}}</span></td>
        <td class="td-right"><span>{{salary5paSkills}}</span></td>
        <td><span>{{salary2hSkills}}</span></td>
        <td class="td-right"><span>{{salary10paSkills}}</span></td>
        <td><span>{{salary6hSkills}}</span></td>
        <td class="td-right"><span>{{salary30paSkills}}</span></td>
        <td><span>{{salary10hSkills}}</span></td>
        <td class="td-right"><span>{{salary50paSkills}}</span></td>
        <td><span>{{salary22hSkills}}</span></td>
        <td class="td-right"><span>{{salary100paSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.total_cost_production') }}</td>
        <td><span>{{totalCostProduction1hSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction5paSkills}}</span></td>
        <td><span>{{totalCostProduction2hSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction10paSkills}}</span></td>
        <td><span>{{totalCostProduction6hSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction30paSkills}}</span></td>
        <td><span>{{totalCostProduction10hSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction50paSkills}}</span></td>
        <td><span>{{totalCostProduction22hSkills}}</span></td>
        <td class="td-right"><span>{{totalCostProduction100paSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{ $t('mines.results_ownership.profit') }}</td>
        <td><span>{{benefit1hSkills}}</span></td>
        <td class="td-right"><span>{{benefit5paSkills}}</span></td>
        <td><span>{{benefit2hSkills}}</span></td>
        <td class="td-right"><span>{{benefit10paSkills}}</span></td>
        <td><span>{{benefit6hSkills}}</span></td>
        <td class="td-right"><span>{{benefit30paSkills}}</span></td>
        <td><span>{{benefit10hSkills}}</span></td>
        <td class="td-right"><span>{{benefit50paSkills}}</span></td>
        <td><span>{{benefit22hSkills}}</span></td>
        <td class="td-right"><span>{{benefit100paSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_web_mob')}}</td>
        <td colspan="5"><span>{{investmentTotalSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{investmentTotalSkillsMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.investment_total')}}</td>
        <td colspan="10" class="td-right"><span>{{investmentTotalSkills}}</span></td>
      </tr>
      <tr class="tr-left">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_web_mob')}}</td>
        <td colspan="5"><span>{{profitTotalSkillsWeb}}</span></td>
        <td colspan="5" class="td-right"><span>{{profitTotalSkillsMob}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="table-label td-right">{{$t('mines.results_ownership.profit_total')}}</td>
        <td colspan="10" class="td-right"><span>{{profitTotalSkills}}</span></td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td></td>
        <td colspan="10" class="td-right">
          <button @click="reset">{{ $t('mines.reset') }}</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MinesRevient',
  data() {
    return {
      typeMine: null,
      levelMine: null,
      salaryValue: null,
      ironValue: null,
      stoneValue: null,
      clayValue: null,
      saltValue: null,
      frequency1h: null,
      frequency2h: null,
      frequency6h: null,
      frequency10h: null,
      frequency22h: null,
      frequency5pa: null,
      frequency10pa: null,
      frequency30pa: null,
      frequency50pa: null,
      frequency100pa: null,
      prod1h: null,
      prod2h: null,
      prod6h: null,
      prod10h: null,
      prod22h: null,
      prod5pa: null,
      prod10pa: null,
      prod30pa: null,
      prod50pa: null,
      prod100pa: null,
      prodValue1h: null,
      prodValue2h: null,
      prodValue6h: null,
      prodValue10h: null,
      prodValue22h: null,
      prodValue5pa: null,
      prodValue10pa: null,
      prodValue30pa: null,
      prodValue50pa: null,
      prodValue100pa: null,
      ironUsed1h: null,
      ironUsed2h: null,
      ironUsed6h: null,
      ironUsed10h: null,
      ironUsed22h: null,
      ironUsed5pa: null,
      ironUsed10pa: null,
      ironUsed30pa: null,
      ironUsed50pa: null,
      ironUsed100pa: null,
      costIronUsed1h: null,
      costIronUsed2h: null,
      costIronUsed6h: null,
      costIronUsed10h: null,
      costIronUsed22h: null,
      costIronUsed5pa: null,
      costIronUsed10pa: null,
      costIronUsed30pa: null,
      costIronUsed50pa: null,
      costIronUsed100pa: null,
      stoneUsed1h: null,
      stoneUsed2h: null,
      stoneUsed6h: null,
      stoneUsed10h: null,
      stoneUsed22h: null,
      stoneUsed5pa: null,
      stoneUsed10pa: null,
      stoneUsed30pa: null,
      stoneUsed50pa: null,
      stoneUsed100pa: null,
      costStoneUsed1h: null,
      costStoneUsed2h: null,
      costStoneUsed6h: null,
      costStoneUsed10h: null,
      costStoneUsed22h: null,
      costStoneUsed5pa: null,
      costStoneUsed10pa: null,
      costStoneUsed30pa: null,
      costStoneUsed50pa: null,
      costStoneUsed100pa: null,
      salary1h: null,
      salary2h: null,
      salary6h: null,
      salary10h: null,
      salary22h: null,
      salary5pa: null,
      salary10pa: null,
      salary30pa: null,
      salary50pa: null,
      salary100pa: null,
      totalCostProduction1h: null,
      totalCostProduction2h: null,
      totalCostProduction6h: null,
      totalCostProduction10h: null,
      totalCostProduction22h: null,
      totalCostProduction5pa: null,
      totalCostProduction10pa: null,
      totalCostProduction30pa: null,
      totalCostProduction50pa: null,
      totalCostProduction100pa: null,
      benefit1h: null,
      benefit2h: null,
      benefit6h: null,
      benefit10h: null,
      benefit22h: null,
      benefit5pa: null,
      benefit10pa: null,
      benefit30pa: null,
      benefit50pa: null,
      benefit100pa: null,
      investmentTotalWeb: null,
      investmentTotalMob: null,
      investmentTotal: null,
      profitTotalWeb: null,
      profitTotalMob: null,
      profitTotal: null,
      prod1hNoSkills: null,
      prod2hNoSkills: null,
      prod6hNoSkills: null,
      prod10hNoSkills: null,
      prod22hNoSkills: null,
      prod5paNoSkills: null,
      prod10paNoSkills: null,
      prod30paNoSkills: null,
      prod50paNoSkills: null,
      prod100paNoSkills: null,
      prodValue1hNoSkills: null,
      prodValue2hNoSkills: null,
      prodValue6hNoSkills: null,
      prodValue10hNoSkills: null,
      prodValue22hNoSkills: null,
      prodValue5paNoSkills: null,
      prodValue10paNoSkills: null,
      prodValue30paNoSkills: null,
      prodValue50paNoSkills: null,
      prodValue100paNoSkills: null,
      ironUsed1hNoSkills: null,
      ironUsed2hNoSkills: null,
      ironUsed6hNoSkills: null,
      ironUsed10hNoSkills: null,
      ironUsed22hNoSkills: null,
      ironUsed5paNoSkills: null,
      ironUsed10paNoSkills: null,
      ironUsed30paNoSkills: null,
      ironUsed50paNoSkills: null,
      ironUsed100paNoSkills: null,
      costIronUsed1hNoSkills: null,
      costIronUsed2hNoSkills: null,
      costIronUsed6hNoSkills: null,
      costIronUsed10hNoSkills: null,
      costIronUsed22hNoSkills: null,
      costIronUsed5paNoSkills: null,
      costIronUsed10paNoSkills: null,
      costIronUsed30paNoSkills: null,
      costIronUsed50paNoSkills: null,
      costIronUsed100paNoSkills: null,
      stoneUsed1hNoSkills: null,
      stoneUsed2hNoSkills: null,
      stoneUsed6hNoSkills: null,
      stoneUsed10hNoSkills: null,
      stoneUsed22hNoSkills: null,
      stoneUsed5paNoSkills: null,
      stoneUsed10paNoSkills: null,
      stoneUsed30paNoSkills: null,
      stoneUsed50paNoSkills: null,
      stoneUsed100paNoSkills: null,
      costStoneUsed1hNoSkills: null,
      costStoneUsed2hNoSkills: null,
      costStoneUsed6hNoSkills: null,
      costStoneUsed10hNoSkills: null,
      costStoneUsed22hNoSkills: null,
      costStoneUsed5paNoSkills: null,
      costStoneUsed10paNoSkills: null,
      costStoneUsed30paNoSkills: null,
      costStoneUsed50paNoSkills: null,
      costStoneUsed100paNoSkills: null,
      salary1hNoSkills: null,
      salary2hNoSkills: null,
      salary6hNoSkills: null,
      salary10hNoSkills: null,
      salary22hNoSkills: null,
      salary5paNoSkills: null,
      salary10paNoSkills: null,
      salary30paNoSkills: null,
      salary50paNoSkills: null,
      salary100paNoSkills: null,
      totalCostProduction1hNoSkills: null,
      totalCostProduction2hNoSkills: null,
      totalCostProduction6hNoSkills: null,
      totalCostProduction10hNoSkills: null,
      totalCostProduction22hNoSkills: null,
      totalCostProduction5paNoSkills: null,
      totalCostProduction10paNoSkills: null,
      totalCostProduction30paNoSkills: null,
      totalCostProduction50paNoSkills: null,
      totalCostProduction100paNoSkills: null,
      benefit1hNoSkills: null,
      benefit2hNoSkills: null,
      benefit6hNoSkills: null,
      benefit10hNoSkills: null,
      benefit22hNoSkills: null,
      benefit5paNoSkills: null,
      benefit10paNoSkills: null,
      benefit30paNoSkills: null,
      benefit50paNoSkills: null,
      benefit100paNoSkills: null,
      investmentTotalNoSkillsWeb: null,
      investmentTotalNoSkillsMob: null,
      investmentTotalNoSkills: null,
      profitTotalNoSkillsWeb: null,
      profitTotalNoSkillsMob: null,
      profitTotalNoSkills: null,
      prod1hSkills: null,
      prod2hSkills: null,
      prod6hSkills: null,
      prod10hSkills: null,
      prod22hSkills: null,
      prod5paSkills: null,
      prod10paSkills: null,
      prod30paSkills: null,
      prod50paSkills: null,
      prod100paSkills: null,
      prodValue1hSkills: null,
      prodValue2hSkills: null,
      prodValue6hSkills: null,
      prodValue10hSkills: null,
      prodValue22hSkills: null,
      prodValue5paSkills: null,
      prodValue10paSkills: null,
      prodValue30paSkills: null,
      prodValue50paSkills: null,
      prodValue100paSkills: null,
      ironUsed1hSkills: null,
      ironUsed2hSkills: null,
      ironUsed6hSkills: null,
      ironUsed10hSkills: null,
      ironUsed22hSkills: null,
      ironUsed5paSkills: null,
      ironUsed10paSkills: null,
      ironUsed30paSkills: null,
      ironUsed50paSkills: null,
      ironUsed100paSkills: null,
      costIronUsed1hSkills: null,
      costIronUsed2hSkills: null,
      costIronUsed6hSkills: null,
      costIronUsed10hSkills: null,
      costIronUsed22hSkills: null,
      costIronUsed5paSkills: null,
      costIronUsed10paSkills: null,
      costIronUsed30paSkills: null,
      costIronUsed50paSkills: null,
      costIronUsed100paSkills: null,
      stoneUsed1hSkills: null,
      stoneUsed2hSkills: null,
      stoneUsed6hSkills: null,
      stoneUsed10hSkills: null,
      stoneUsed22hSkills: null,
      stoneUsed5paSkills: null,
      stoneUsed10paSkills: null,
      stoneUsed30paSkills: null,
      stoneUsed50paSkills: null,
      stoneUsed100paSkills: null,
      costStoneUsed1hSkills: null,
      costStoneUsed2hSkills: null,
      costStoneUsed6hSkills: null,
      costStoneUsed10hSkills: null,
      costStoneUsed22hSkills: null,
      costStoneUsed5paSkills: null,
      costStoneUsed10paSkills: null,
      costStoneUsed30paSkills: null,
      costStoneUsed50paSkills: null,
      costStoneUsed100paSkills: null,
      salary1hSkills: null,
      salary2hSkills: null,
      salary6hSkills: null,
      salary10hSkills: null,
      salary22hSkills: null,
      salary5paSkills: null,
      salary10paSkills: null,
      salary30paSkills: null,
      salary50paSkills: null,
      salary100paSkills: null,
      totalCostProduction1hSkills: null,
      totalCostProduction2hSkills: null,
      totalCostProduction6hSkills: null,
      totalCostProduction10hSkills: null,
      totalCostProduction22hSkills: null,
      totalCostProduction5paSkills: null,
      totalCostProduction10paSkills: null,
      totalCostProduction30paSkills: null,
      totalCostProduction50paSkills: null,
      totalCostProduction100paSkills: null,
      benefit1hSkills: null,
      benefit2hSkills: null,
      benefit6hSkills: null,
      benefit10hSkills: null,
      benefit22hSkills: null,
      benefit5paSkills: null,
      benefit10paSkills: null,
      benefit30paSkills: null,
      benefit50paSkills: null,
      benefit100paSkills: null,
      investmentTotalSkillsWeb: null,
      investmentTotalSkillsMob: null,
      investmentTotalSkills: null,
      profitTotalSkillsWeb: null,
      profitTotalSkillsMob: null,
      profitTotalSkills: null,
    }
  },
  mounted() {
    this.typeMine = 'iron';
    this.levelMine = this.getOptimumByMine(this.typeMine);
  },
  methods: {
    calculate() {
      let output = this.getOutputByMine(this.typeMine);
      let prodValue = this.getProdValue(this.typeMine);

      this.prod1h = this.getProd(this.frequency2h, output[this.levelMine].output, 1, 3);
      this.prod2h = this.getProd(this.frequency6h, output[this.levelMine].output, 2, 2);
      this.prod6h = this.getProd(this.frequency6h, output[this.levelMine].output, 6, 1.35);
      this.prod10h = this.getProd(this.frequency10h, output[this.levelMine].output, 10, 1.15);
      this.prod22h = this.getProd(this.frequency22h, output[this.levelMine].output, 22, 1);
      this.prod5pa = this.getProd(this.frequency5pa, output[this.levelMine].output, 1, 1.8);
      this.prod10pa = this.getProd(this.frequency10pa, output[this.levelMine].output, 2, 1.6);
      this.prod30pa = this.getProd(this.frequency30pa, output[this.levelMine].output, 6, 1.35);
      this.prod50pa = this.getProd(this.frequency50pa, output[this.levelMine].output, 10, 1.15);
      this.prod100pa = this.getProd(this.frequency100pa, output[this.levelMine].output, 22, 1);

      this.prodValue1h = Math.round((this.prod1h * prodValue) * 100) / 100;
      this.prodValue2h = Math.round((this.prod2h * prodValue) * 100) / 100;
      this.prodValue6h = Math.round((this.prod6h * prodValue) * 100) / 100;
      this.prodValue10h = Math.round((this.prod10h * prodValue) * 100) / 100;
      this.prodValue22h = Math.round((this.prod22h * prodValue) * 100) / 100;
      this.prodValue5pa = Math.round((this.prod5pa * prodValue) * 100) / 100;
      this.prodValue10pa = Math.round((this.prod10pa * prodValue) * 100) / 100;
      this.prodValue30pa = Math.round((this.prod30pa * prodValue) * 100) / 100;
      this.prodValue50pa = Math.round((this.prod50pa * prodValue) * 100) / 100;
      this.prodValue100pa = Math.round((this.prod100pa * prodValue) * 100) / 100;

      this.ironUsed1h = this.calcMaintenance(output[this.levelMine].iron, this.frequency1h, 1, 3);
      this.ironUsed2h = this.calcMaintenance(output[this.levelMine].iron, this.frequency2h, 2, 2);
      this.ironUsed6h = this.calcMaintenance(output[this.levelMine].iron, this.frequency6h, 6, 1.35);
      this.ironUsed10h = this.calcMaintenance(output[this.levelMine].iron, this.frequency10h, 10, 1.15);
      this.ironUsed22h = this.calcMaintenance(output[this.levelMine].iron, this.frequency22h, 22, 1);
      this.ironUsed5pa = this.calcMaintenance(output[this.levelMine].iron, this.frequency5pa, 1, 1.8);
      this.ironUsed10pa = this.calcMaintenance(output[this.levelMine].iron, this.frequency10pa, 2, 1.6);
      this.ironUsed30pa = this.calcMaintenance(output[this.levelMine].iron, this.frequency30pa, 6, 1.35);
      this.ironUsed50pa = this.calcMaintenance(output[this.levelMine].iron, this.frequency50pa, 10, 1.15);
      this.ironUsed100pa = this.calcMaintenance(output[this.levelMine].iron, this.frequency100pa, 22, 1);

      this.costIronUsed1h = Math.round((this.ironUsed1h * this.ironValue) * 100) / 100;
      this.costIronUsed2h = Math.round((this.ironUsed2h * this.ironValue) * 100) / 100;
      this.costIronUsed6h = Math.round((this.ironUsed6h * this.ironValue) * 100) / 100;
      this.costIronUsed10h = Math.round((this.ironUsed10h * this.ironValue) * 100) / 100;
      this.costIronUsed22h = Math.round((this.ironUsed22h * this.ironValue) * 100) / 100;
      this.costIronUsed5pa = Math.round((this.ironUsed5pa * this.ironValue) * 100) / 100;
      this.costIronUsed10pa = Math.round((this.ironUsed10pa * this.ironValue) * 100) / 100;
      this.costIronUsed30pa = Math.round((this.ironUsed30pa * this.ironValue) * 100) / 100;
      this.costIronUsed50pa = Math.round((this.ironUsed50pa * this.ironValue) * 100) / 100;
      this.costIronUsed100pa = Math.round((this.ironUsed100pa * this.ironValue) * 100) / 100;

      this.stoneUsed1h = this.calcMaintenance(output[this.levelMine].stone, this.frequency1h, 1, 3);
      this.stoneUsed2h = this.calcMaintenance(output[this.levelMine].stone, this.frequency2h, 2, 2);
      this.stoneUsed6h = this.calcMaintenance(output[this.levelMine].stone, this.frequency6h, 6, 1.35);
      this.stoneUsed10h = this.calcMaintenance(output[this.levelMine].stone, this.frequency10h, 10, 1.15);
      this.stoneUsed22h = this.calcMaintenance(output[this.levelMine].stone, this.frequency22h, 22, 1);
      this.stoneUsed5pa = this.calcMaintenance(output[this.levelMine].stone, this.frequency5pa, 1, 1.8);
      this.stoneUsed10pa = this.calcMaintenance(output[this.levelMine].stone, this.frequency10pa, 2, 1.6);
      this.stoneUsed30pa = this.calcMaintenance(output[this.levelMine].stone, this.frequency30pa, 6, 1.35);
      this.stoneUsed50pa = this.calcMaintenance(output[this.levelMine].stone, this.frequency50pa, 10, 1.15);
      this.stoneUsed100pa = this.calcMaintenance(output[this.levelMine].stone, this.frequency100pa, 22, 1);

      this.costStoneUsed1h = Math.round((this.stoneUsed1h * this.stoneValue) * 100) / 100;
      this.costStoneUsed2h = Math.round((this.stoneUsed2h * this.stoneValue) * 100) / 100;
      this.costStoneUsed6h = Math.round((this.stoneUsed6h * this.stoneValue) * 100) / 100;
      this.costStoneUsed10h = Math.round((this.stoneUsed10h * this.stoneValue) * 100) / 100;
      this.costStoneUsed22h = Math.round((this.stoneUsed22h * this.stoneValue) * 100) / 100;
      this.costStoneUsed5pa = Math.round((this.stoneUsed5pa * this.stoneValue) * 100) / 100;
      this.costStoneUsed10pa = Math.round((this.stoneUsed10pa * this.stoneValue) * 100) / 100;
      this.costStoneUsed30pa = Math.round((this.stoneUsed30pa * this.stoneValue) * 100) / 100;
      this.costStoneUsed50pa = Math.round((this.stoneUsed50pa * this.stoneValue) * 100) / 100;
      this.costStoneUsed100pa = Math.round((this.stoneUsed100pa * this.stoneValue) * 100) / 100;

      this.salary1h = this.calcPay(this.salaryValue, 1, 3);
      this.salary2h = this.calcPay(this.salaryValue, 2, 2);
      this.salary6h = this.calcPay(this.salaryValue, 6, 1.35);
      this.salary10h = this.calcPay(this.salaryValue, 10, 1.15);
      this.salary22h = this.calcPay(this.salaryValue, 22, 1);
      this.salary5pa = this.calcPay(this.salaryValue, 1, 1.8);
      this.salary10pa = this.calcPay(this.salaryValue, 2, 1.6);
      this.salary30pa = this.calcPay(this.salaryValue, 6, 1.35);
      this.salary50pa = this.calcPay(this.salaryValue, 10, 1.15);
      this.salary100pa = this.calcPay(this.salaryValue, 22, 1);

      this.totalCostProduction1h = Math.round((this.costIronUsed1h + this.costStoneUsed1h + this.salary1h) * 100) / 100;
      this.totalCostProduction2h = Math.round((this.costIronUsed2h + this.costStoneUsed2h + this.salary2h) * 100) / 100;
      this.totalCostProduction6h = Math.round((this.costIronUsed6h + this.costStoneUsed6h + this.salary6h) * 100) / 100;
      this.totalCostProduction10h = Math.round((this.costIronUsed10h + this.costStoneUsed10h + this.salary10h) * 100) / 100;
      this.totalCostProduction22h = Math.round((this.costIronUsed22h + this.costStoneUsed22h + this.salary22h) * 100) / 100;
      this.totalCostProduction5pa = Math.round((this.costIronUsed5pa + this.costStoneUsed5pa + this.salary5pa) * 100) / 100;
      this.totalCostProduction10pa = Math.round((this.costIronUsed10pa + this.costStoneUsed10pa + this.salary10pa) * 100) / 100;
      this.totalCostProduction30pa = Math.round((this.costIronUsed30pa + this.costStoneUsed30pa + this.salary30pa) * 100) / 100;
      this.totalCostProduction50pa = Math.round((this.costIronUsed50pa + this.costStoneUsed50pa + this.salary50pa) * 100) / 100;
      this.totalCostProduction100pa = Math.round((this.costIronUsed100pa + this.costStoneUsed100pa + this.salary100pa) * 100) / 100;

      this.benefit1h= Math.round((this.prodValue1h - this.totalCostProduction1h) * 100) / 100;
      this.benefit2h= Math.round((this.prodValue2h - this.totalCostProduction2h) * 100) / 100;
      this.benefit6h= Math.round((this.prodValue6h - this.totalCostProduction6h) * 100) / 100;
      this.benefit10h= Math.round((this.prodValue10h - this.totalCostProduction10h) * 100) / 100;
      this.benefit22h= Math.round((this.prodValue22h - this.totalCostProduction22h) * 100) / 100;
      this.benefit5pa= Math.round((this.prodValue5pa - this.totalCostProduction5pa) * 100) / 100;
      this.benefit10pa= Math.round((this.prodValue10pa - this.totalCostProduction10pa) * 100) / 100;
      this.benefit30pa= Math.round((this.prodValue30pa - this.totalCostProduction30pa) * 100) / 100;
      this.benefit50pa= Math.round((this.prodValue50pa - this.totalCostProduction50pa) * 100) / 100;
      this.benefit100pa= Math.round((this.prodValue100pa - this.totalCostProduction100pa) * 100) / 100;

      this.investmentTotalWeb = Math.round((this.totalCostProduction1h + this.totalCostProduction2h + this.totalCostProduction6h + this.totalCostProduction10h + this.totalCostProduction22h) * 100) / 100;
      this.investmentTotalMob = Math.round((this.totalCostProduction5pa + this.totalCostProduction10pa + this.totalCostProduction30pa + this.totalCostProduction50pa + this.totalCostProduction100pa) * 100) / 100;
      this.investmentTotal = Math.round((this.investmentTotalWeb + this.investmentTotalMob) * 100) / 100;

      this.profitTotalWeb = Math.round((this.benefit1h + this.benefit2h + this.benefit6h + this.benefit10h + this.benefit22h) * 100) / 100;
      this.profitTotalMob = Math.round((this.benefit5pa + this.benefit10pa + this.benefit30pa + this.benefit50pa + this.benefit100pa) * 100) / 100;
      this.profitTotal = Math.round((this.profitTotalWeb + this.profitTotalMob) * 100) / 100;

      this.prod1hNoSkills = this.getSkillsMin(this.prod1h);
      this.prod2hNoSkills = this.getSkillsMin(this.prod2h);
      this.prod6hNoSkills = this.getSkillsMin(this.prod6h);
      this.prod10hNoSkills = this.getSkillsMin(this.prod10h);
      this.prod22hNoSkills = this.getSkillsMin(this.prod22h);
      this.prod5paNoSkills = this.getSkillsMin(this.prod5pa);
      this.prod10paNoSkills = this.getSkillsMin(this.prod10pa);
      this.prod30paNoSkills = this.getSkillsMin(this.prod30pa);
      this.prod50paNoSkills = this.getSkillsMin(this.prod50pa);
      this.prod100paNoSkills = this.getSkillsMin(this.prod100pa);
      this.prodValue1hNoSkills = this.getSkillsMin(this.prodValue1h);
      this.prodValue2hNoSkills = this.getSkillsMin(this.prodValue2h);
      this.prodValue6hNoSkills = this.getSkillsMin(this.prodValue6h);
      this.prodValue10hNoSkills = this.getSkillsMin(this.prodValue10h);
      this.prodValue22hNoSkills = this.getSkillsMin(this.prodValue22h);
      this.prodValue5paNoSkills = this.getSkillsMin(this.prodValue5pa);
      this.prodValue10paNoSkills = this.getSkillsMin(this.prodValue10pa);
      this.prodValue30paNoSkills = this.getSkillsMin(this.prodValue30pa);
      this.prodValue50paNoSkills = this.getSkillsMin(this.prodValue50pa);
      this.prodValue100paNoSkills = this.getSkillsMin(this.prodValue100pa);
      this.ironUsed1hNoSkills = this.getSkillsMin(this.ironUsed1h);
      this.ironUsed2hNoSkills = this.getSkillsMin(this.ironUsed2h);
      this.ironUsed6hNoSkills = this.getSkillsMin(this.ironUsed6h);
      this.ironUsed10hNoSkills = this.getSkillsMin(this.ironUsed10h);
      this.ironUsed22hNoSkills = this.getSkillsMin(this.ironUsed22h);
      this.ironUsed5paNoSkills = this.getSkillsMin(this.ironUsed5pa);
      this.ironUsed10paNoSkills = this.getSkillsMin(this.ironUsed10pa);
      this.ironUsed30paNoSkills = this.getSkillsMin(this.ironUsed30pa);
      this.ironUsed50paNoSkills = this.getSkillsMin(this.ironUsed50pa);
      this.ironUsed100paNoSkills = this.getSkillsMin(this.ironUsed100pa);
      this.costIronUsed1hNoSkills = this.getSkillsMin(this.costIronUsed1h);
      this.costIronUsed2hNoSkills = this.getSkillsMin(this.costIronUsed2h);
      this.costIronUsed6hNoSkills = this.getSkillsMin(this.costIronUsed6h);
      this.costIronUsed10hNoSkills = this.getSkillsMin(this.costIronUsed10h);
      this.costIronUsed22hNoSkills = this.getSkillsMin(this.costIronUsed22h);
      this.costIronUsed5paNoSkills = this.getSkillsMin(this.costIronUsed5pa);
      this.costIronUsed10paNoSkills = this.getSkillsMin(this.costIronUsed10pa);
      this.costIronUsed30paNoSkills = this.getSkillsMin(this.costIronUsed30pa);
      this.costIronUsed50paNoSkills = this.getSkillsMin(this.costIronUsed50pa);
      this.costIronUsed100paNoSkills = this.getSkillsMin(this.costIronUsed100pa);
      this.stoneUsed1hNoSkills = this.getSkillsMin(this.stoneUsed1h);
      this.stoneUsed2hNoSkills = this.getSkillsMin(this.stoneUsed2h);
      this.stoneUsed6hNoSkills = this.getSkillsMin(this.stoneUsed6h);
      this.stoneUsed10hNoSkills = this.getSkillsMin(this.stoneUsed10h);
      this.stoneUsed22hNoSkills = this.getSkillsMin(this.stoneUsed22h);
      this.stoneUsed5paNoSkills = this.getSkillsMin(this.stoneUsed5pa);
      this.stoneUsed10paNoSkills = this.getSkillsMin(this.stoneUsed10pa);
      this.stoneUsed30paNoSkills = this.getSkillsMin(this.stoneUsed30pa);
      this.stoneUsed50paNoSkills = this.getSkillsMin(this.stoneUsed50pa);
      this.stoneUsed100paNoSkills = this.getSkillsMin(this.stoneUsed100pa);
      this.costStoneUsed1hNoSkills = this.getSkillsMin(this.costStoneUsed1h);
      this.costStoneUsed2hNoSkills = this.getSkillsMin(this.costStoneUsed2h);
      this.costStoneUsed6hNoSkills = this.getSkillsMin(this.costStoneUsed6h);
      this.costStoneUsed10hNoSkills = this.getSkillsMin(this.costStoneUsed10h);
      this.costStoneUsed22hNoSkills = this.getSkillsMin(this.costStoneUsed22h);
      this.costStoneUsed5paNoSkills = this.getSkillsMin(this.costStoneUsed5pa);
      this.costStoneUsed10paNoSkills = this.getSkillsMin(this.costStoneUsed10pa);
      this.costStoneUsed30paNoSkills = this.getSkillsMin(this.costStoneUsed30pa);
      this.costStoneUsed50paNoSkills = this.getSkillsMin(this.costStoneUsed50pa);
      this.costStoneUsed100paNoSkills = this.getSkillsMin(this.costStoneUsed100pa);
      this.salary1hNoSkills = this.getSkillsMin(this.salary1h);
      this.salary2hNoSkills = this.getSkillsMin(this.salary2h);
      this.salary6hNoSkills = this.getSkillsMin(this.salary6h);
      this.salary10hNoSkills = this.getSkillsMin(this.salary10h);
      this.salary22hNoSkills = this.getSkillsMin(this.salary22h);
      this.salary5paNoSkills = this.getSkillsMin(this.salary5pa);
      this.salary10paNoSkills = this.getSkillsMin(this.salary10pa);
      this.salary30paNoSkills = this.getSkillsMin(this.salary30pa);
      this.salary50paNoSkills = this.getSkillsMin(this.salary50pa);
      this.salary100paNoSkills = this.getSkillsMin(this.salary100pa);
      this.totalCostProduction1hNoSkills = this.getSkillsMin(this.totalCostProduction1h);
      this.totalCostProduction2hNoSkills = this.getSkillsMin(this.totalCostProduction2h);
      this.totalCostProduction6hNoSkills = this.getSkillsMin(this.totalCostProduction6h);
      this.totalCostProduction10hNoSkills = this.getSkillsMin(this.totalCostProduction10h);
      this.totalCostProduction22hNoSkills = this.getSkillsMin(this.totalCostProduction22h);
      this.totalCostProduction5paNoSkills = this.getSkillsMin(this.totalCostProduction5pa);
      this.totalCostProduction10paNoSkills = this.getSkillsMin(this.totalCostProduction10pa);
      this.totalCostProduction30paNoSkills = this.getSkillsMin(this.totalCostProduction30pa);
      this.totalCostProduction50paNoSkills = this.getSkillsMin(this.totalCostProduction50pa);
      this.totalCostProduction100paNoSkills = this.getSkillsMin(this.totalCostProduction100pa);
      this.benefit1hNoSkills = this.getSkillsMin(this.benefit1h);
      this.benefit2hNoSkills = this.getSkillsMin(this.benefit2h);
      this.benefit6hNoSkills = this.getSkillsMin(this.benefit6h);
      this.benefit10hNoSkills = this.getSkillsMin(this.benefit10h);
      this.benefit22hNoSkills = this.getSkillsMin(this.benefit22h);
      this.benefit5paNoSkills = this.getSkillsMin(this.benefit5pa);
      this.benefit10paNoSkills = this.getSkillsMin(this.benefit10pa);
      this.benefit30paNoSkills = this.getSkillsMin(this.benefit30pa);
      this.benefit50paNoSkills = this.getSkillsMin(this.benefit50pa);
      this.benefit100paNoSkills = this.getSkillsMin(this.benefit100pa);
      this.investmentTotalNoSkillsWeb = this.getSkillsMin(this.investmentTotalWeb);
      this.investmentTotalNoSkillsMob = this.getSkillsMin(this.investmentTotalMob);
      this.investmentTotalNoSkills = this.getSkillsMin(this.investmentTotal);
      this.profitTotalNoSkillsWeb = this.getSkillsMin(this.profitTotalWeb);
      this.profitTotalNoSkillsMob = this.getSkillsMin(this.profitTotalMob);
      this.profitTotalNoSkills = this.getSkillsMin(this.profitTotal);

      this.prod1hSkills = this.getSkillsMax(this.prod1h);
      this.prod2hSkills = this.getSkillsMax(this.prod2h);
      this.prod6hSkills = this.getSkillsMax(this.prod6h);
      this.prod10hSkills = this.getSkillsMax(this.prod10h);
      this.prod22hSkills = this.getSkillsMax(this.prod22h);
      this.prod5paSkills = this.getSkillsMax(this.prod5pa);
      this.prod10paSkills = this.getSkillsMax(this.prod10pa);
      this.prod30paSkills = this.getSkillsMax(this.prod30pa);
      this.prod50paSkills = this.getSkillsMax(this.prod50pa);
      this.prod100paSkills = this.getSkillsMax(this.prod100pa);
      this.prodValue1hSkills = this.getSkillsMax(this.prodValue1h);
      this.prodValue2hSkills = this.getSkillsMax(this.prodValue2h);
      this.prodValue6hSkills = this.getSkillsMax(this.prodValue6h);
      this.prodValue10hSkills = this.getSkillsMax(this.prodValue10h);
      this.prodValue22hSkills = this.getSkillsMax(this.prodValue22h);
      this.prodValue5paSkills = this.getSkillsMax(this.prodValue5pa);
      this.prodValue10paSkills = this.getSkillsMax(this.prodValue10pa);
      this.prodValue30paSkills = this.getSkillsMax(this.prodValue30pa);
      this.prodValue50paSkills = this.getSkillsMax(this.prodValue50pa);
      this.prodValue100paSkills = this.getSkillsMax(this.prodValue100pa);
      this.ironUsed1hSkills = this.getSkillsMax(this.ironUsed1h);
      this.ironUsed2hSkills = this.getSkillsMax(this.ironUsed2h);
      this.ironUsed6hSkills = this.getSkillsMax(this.ironUsed6h);
      this.ironUsed10hSkills = this.getSkillsMax(this.ironUsed10h);
      this.ironUsed22hSkills = this.getSkillsMax(this.ironUsed22h);
      this.ironUsed5paSkills = this.getSkillsMax(this.ironUsed5pa);
      this.ironUsed10paSkills = this.getSkillsMax(this.ironUsed10pa);
      this.ironUsed30paSkills = this.getSkillsMax(this.ironUsed30pa);
      this.ironUsed50paSkills = this.getSkillsMax(this.ironUsed50pa);
      this.ironUsed100paSkills = this.getSkillsMax(this.ironUsed100pa);
      this.costIronUsed1hSkills = this.getSkillsMax(this.costIronUsed1h);
      this.costIronUsed2hSkills = this.getSkillsMax(this.costIronUsed2h);
      this.costIronUsed6hSkills = this.getSkillsMax(this.costIronUsed6h);
      this.costIronUsed10hSkills = this.getSkillsMax(this.costIronUsed10h);
      this.costIronUsed22hSkills = this.getSkillsMax(this.costIronUsed22h);
      this.costIronUsed5paSkills = this.getSkillsMax(this.costIronUsed5pa);
      this.costIronUsed10paSkills = this.getSkillsMax(this.costIronUsed10pa);
      this.costIronUsed30paSkills = this.getSkillsMax(this.costIronUsed30pa);
      this.costIronUsed50paSkills = this.getSkillsMax(this.costIronUsed50pa);
      this.costIronUsed100paSkills = this.getSkillsMax(this.costIronUsed100pa);
      this.stoneUsed1hSkills = this.getSkillsMax(this.stoneUsed1h);
      this.stoneUsed2hSkills = this.getSkillsMax(this.stoneUsed2h);
      this.stoneUsed6hSkills = this.getSkillsMax(this.stoneUsed6h);
      this.stoneUsed10hSkills = this.getSkillsMax(this.stoneUsed10h);
      this.stoneUsed22hSkills = this.getSkillsMax(this.stoneUsed22h);
      this.stoneUsed5paSkills = this.getSkillsMax(this.stoneUsed5pa);
      this.stoneUsed10paSkills = this.getSkillsMax(this.stoneUsed10pa);
      this.stoneUsed30paSkills = this.getSkillsMax(this.stoneUsed30pa);
      this.stoneUsed50paSkills = this.getSkillsMax(this.stoneUsed50pa);
      this.stoneUsed100paSkills = this.getSkillsMax(this.stoneUsed100pa);
      this.costStoneUsed1hSkills = this.getSkillsMax(this.costStoneUsed1h);
      this.costStoneUsed2hSkills = this.getSkillsMax(this.costStoneUsed2h);
      this.costStoneUsed6hSkills = this.getSkillsMax(this.costStoneUsed6h);
      this.costStoneUsed10hSkills = this.getSkillsMax(this.costStoneUsed10h);
      this.costStoneUsed22hSkills = this.getSkillsMax(this.costStoneUsed22h);
      this.costStoneUsed5paSkills = this.getSkillsMax(this.costStoneUsed5pa);
      this.costStoneUsed10paSkills = this.getSkillsMax(this.costStoneUsed10pa);
      this.costStoneUsed30paSkills = this.getSkillsMax(this.costStoneUsed30pa);
      this.costStoneUsed50paSkills = this.getSkillsMax(this.costStoneUsed50pa);
      this.costStoneUsed100paSkills = this.getSkillsMax(this.costStoneUsed100pa);
      this.salary1hSkills = this.getSkillsMax(this.salary1h);
      this.salary2hSkills = this.getSkillsMax(this.salary2h);
      this.salary6hSkills = this.getSkillsMax(this.salary6h);
      this.salary10hSkills = this.getSkillsMax(this.salary10h);
      this.salary22hSkills = this.getSkillsMax(this.salary22h);
      this.salary5paSkills = this.getSkillsMax(this.salary5pa);
      this.salary10paSkills = this.getSkillsMax(this.salary10pa);
      this.salary30paSkills = this.getSkillsMax(this.salary30pa);
      this.salary50paSkills = this.getSkillsMax(this.salary50pa);
      this.salary100paSkills = this.getSkillsMax(this.salary100pa);
      this.totalCostProduction1hSkills = this.getSkillsMax(this.totalCostProduction1h);
      this.totalCostProduction2hSkills = this.getSkillsMax(this.totalCostProduction2h);
      this.totalCostProduction6hSkills = this.getSkillsMax(this.totalCostProduction6h);
      this.totalCostProduction10hSkills = this.getSkillsMax(this.totalCostProduction10h);
      this.totalCostProduction22hSkills = this.getSkillsMax(this.totalCostProduction22h);
      this.totalCostProduction5paSkills = this.getSkillsMax(this.totalCostProduction5pa);
      this.totalCostProduction10paSkills = this.getSkillsMax(this.totalCostProduction10pa);
      this.totalCostProduction30paSkills = this.getSkillsMax(this.totalCostProduction30pa);
      this.totalCostProduction50paSkills = this.getSkillsMax(this.totalCostProduction50pa);
      this.totalCostProduction100paSkills = this.getSkillsMax(this.totalCostProduction100pa);
      this.benefit1hSkills = this.getSkillsMax(this.benefit1h);
      this.benefit2hSkills = this.getSkillsMax(this.benefit2h);
      this.benefit6hSkills = this.getSkillsMax(this.benefit6h);
      this.benefit10hSkills = this.getSkillsMax(this.benefit10h);
      this.benefit22hSkills = this.getSkillsMax(this.benefit22h);
      this.benefit5paSkills = this.getSkillsMax(this.benefit5pa);
      this.benefit10paSkills = this.getSkillsMax(this.benefit10pa);
      this.benefit30paSkills = this.getSkillsMax(this.benefit30pa);
      this.benefit50paSkills = this.getSkillsMax(this.benefit50pa);
      this.benefit100paSkills = this.getSkillsMax(this.benefit100pa);
      this.investmentTotalSkillsWeb = this.getSkillsMax(this.investmentTotalWeb);
      this.investmentTotalSkillsMob = this.getSkillsMax(this.investmentTotalMob);
      this.investmentTotalSkills = this.getSkillsMax(this.investmentTotal);
      this.profitTotalSkillsWeb = this.getSkillsMax(this.profitTotalWeb);
      this.profitTotalSkillsMob = this.getSkillsMax(this.profitTotalMob);
      this.profitTotalSkills = this.getSkillsMax(this.profitTotal);
    },
    getOutputByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].output;
    },
    getOptimumByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].optimum;
    },
    getProdValue(mine) {
      switch (mine) {
        case 'iron':
          return this.ironValue;
        case 'stone':
          return this.stoneValue;
        case 'gold':
          return 1;
        case 'clay':
          return this.clayValue;
        case 'salt':
          return this.saltValue;
      }
    },
    getProd(miners, level, hourly, ratio) {
      let result = miners / 22;
      result = Math.round((result * hourly * ratio * level) * 100) / 100;
      return result;
    },
    calcMaintenance(maintenance, miners, hourly, ratio) {
      let result = maintenance / 22;
      result = Math.round(((result * hourly * ratio * miners) / 100) * 100) / 100;
      return result;
    },
    calcPay(pay, hourly, ratio) {
      let result = pay / 22;
      result = Math.floor((result * hourly * ratio) * 100) / 100;
      return result;
    },
    getSkillsMin(value) {
      return Math.round((value - (value * 0.15)) * 100) /100;
    },
    getSkillsMax(value) {
      return Math.round((value * 1.10) * 100) /100;
    },
    arrayOfValues(min, max) {
      let values = [];
      for (let i = min; i <= max; i++) {
        values.push(i);
      }
      return values;
    },
    reset() {
      this.levelMine = this.getOptimumByMine(this.typeMine);

      this.frequency1h = null;
      this.frequency2h = null;
      this.frequency6h = null;
      this.frequency10h = null;
      this.frequency22h = null;
      this.frequency5pa = null;
      this.frequency10pa = null;
      this.frequency30pa = null;
      this.frequency50pa = null;
      this.frequency100pa = null;
      this.prod1h = null;
      this.prod2h = null;
      this.prod6h = null;
      this.prod10h = null;
      this.prod22h = null;
      this.prod5pa = null;
      this.prod10pa = null;
      this.prod30pa = null;
      this.prod50pa = null;
      this.prod100pa = null;
      this.prodValue1h = null;
      this.prodValue2h = null;
      this.prodValue6h = null;
      this.prodValue10h = null;
      this.prodValue22h = null;
      this.prodValue5pa = null;
      this.prodValue10pa = null;
      this.prodValue30pa = null;
      this.prodValue50pa = null;
      this.prodValue100pa = null;
      this.ironUsed1h = null;
      this.ironUsed2h = null;
      this.ironUsed6h = null;
      this.ironUsed10h = null;
      this.ironUsed22h = null;
      this.ironUsed5pa = null;
      this.ironUsed10pa = null;
      this.ironUsed30pa = null;
      this.ironUsed50pa = null;
      this.ironUsed100pa = null;
      this.costIronUsed1h = null;
      this.costIronUsed2h = null;
      this.costIronUsed6h = null;
      this.costIronUsed10h = null;
      this.costIronUsed22h = null;
      this.costIronUsed5pa = null;
      this.costIronUsed10pa = null;
      this.costIronUsed30pa = null;
      this.costIronUsed50pa = null;
      this.costIronUsed100pa = null;
      this.stoneUsed1h = null;
      this.stoneUsed2h = null;
      this.stoneUsed6h = null;
      this.stoneUsed10h = null;
      this.stoneUsed22h = null;
      this.stoneUsed5pa = null;
      this.stoneUsed10pa = null;
      this.stoneUsed30pa = null;
      this.stoneUsed50pa = null;
      this.stoneUsed100pa = null;
      this.costStoneUsed1h = null;
      this.costStoneUsed2h = null;
      this.costStoneUsed6h = null;
      this.costStoneUsed10h = null;
      this.costStoneUsed22h = null;
      this.costStoneUsed5pa = null;
      this.costStoneUsed10pa = null;
      this.costStoneUsed30pa = null;
      this.costStoneUsed50pa = null;
      this.costStoneUsed100pa = null;
      this.salary1h = null;
      this.salary2h = null;
      this.salary6h = null;
      this.salary10h = null;
      this.salary22h = null;
      this.salary5pa = null;
      this.salary10pa = null;
      this.salary30pa = null;
      this.salary50pa = null;
      this.salary100pa = null;
      this.totalCostProduction1h = null;
      this.totalCostProduction2h = null;
      this.totalCostProduction6h = null;
      this.totalCostProduction10h = null;
      this.totalCostProduction22h = null;
      this.totalCostProduction5pa = null;
      this.totalCostProduction10pa = null;
      this.totalCostProduction30pa = null;
      this.totalCostProduction50pa = null;
      this.totalCostProduction100pa = null;
      this.benefit1h = null;
      this.benefit2h = null;
      this.benefit6h = null;
      this.benefit10h = null;
      this.benefit22h = null;
      this.benefit5pa = null;
      this.benefit10pa = null;
      this.benefit30pa = null;
      this.benefit50pa = null;
      this.benefit100pa = null;
      this.prod1hNoSkills = null;
      this.prod2hNoSkills = null;
      this.prod6hNoSkills = null;
      this.prod10hNoSkills = null;
      this.prod22hNoSkills = null;
      this.prod5paNoSkills = null;
      this.prod10paNoSkills = null;
      this.prod30paNoSkills = null;
      this.prod50paNoSkills = null;
      this.prod100paNoSkills = null;
      this.prodValue1hNoSkills = null;
      this.prodValue2hNoSkills = null;
      this.prodValue6hNoSkills = null;
      this.prodValue10hNoSkills = null;
      this.prodValue22hNoSkills = null;
      this.prodValue5paNoSkills = null;
      this.prodValue10paNoSkills = null;
      this.prodValue30paNoSkills = null;
      this.prodValue50paNoSkills = null;
      this.prodValue100paNoSkills = null;
      this.ironUsed1hNoSkills = null;
      this.ironUsed2hNoSkills = null;
      this.ironUsed6hNoSkills = null;
      this.ironUsed10hNoSkills = null;
      this.ironUsed22hNoSkills = null;
      this.ironUsed5paNoSkills = null;
      this.ironUsed10paNoSkills = null;
      this.ironUsed30paNoSkills = null;
      this.ironUsed50paNoSkills = null;
      this.ironUsed100paNoSkills = null;
      this.costIronUsed1hNoSkills = null;
      this.costIronUsed2hNoSkills = null;
      this.costIronUsed6hNoSkills = null;
      this.costIronUsed10hNoSkills = null;
      this.costIronUsed22hNoSkills = null;
      this.costIronUsed5paNoSkills = null;
      this.costIronUsed10paNoSkills = null;
      this.costIronUsed30paNoSkills = null;
      this.costIronUsed50paNoSkills = null;
      this.costIronUsed100paNoSkills = null;
      this.stoneUsed1hNoSkills = null;
      this.stoneUsed2hNoSkills = null;
      this.stoneUsed6hNoSkills = null;
      this.stoneUsed10hNoSkills = null;
      this.stoneUsed22hNoSkills = null;
      this.stoneUsed5paNoSkills = null;
      this.stoneUsed10paNoSkills = null;
      this.stoneUsed30paNoSkills = null;
      this.stoneUsed50paNoSkills = null;
      this.stoneUsed100paNoSkills = null;
      this.costStoneUsed1hNoSkills = null;
      this.costStoneUsed2hNoSkills = null;
      this.costStoneUsed6hNoSkills = null;
      this.costStoneUsed10hNoSkills = null;
      this.costStoneUsed22hNoSkills = null;
      this.costStoneUsed5paNoSkills = null;
      this.costStoneUsed10paNoSkills = null;
      this.costStoneUsed30paNoSkills = null;
      this.costStoneUsed50paNoSkills = null;
      this.costStoneUsed100paNoSkills = null;
      this.salary1hNoSkills = null;
      this.salary2hNoSkills = null;
      this.salary6hNoSkills = null;
      this.salary10hNoSkills = null;
      this.salary22hNoSkills = null;
      this.salary5paNoSkills = null;
      this.salary10paNoSkills = null;
      this.salary30paNoSkills = null;
      this.salary50paNoSkills = null;
      this.salary100paNoSkills = null;
      this.totalCostProduction1hNoSkills = null;
      this.totalCostProduction2hNoSkills = null;
      this.totalCostProduction6hNoSkills = null;
      this.totalCostProduction10hNoSkills = null;
      this.totalCostProduction22hNoSkills = null;
      this.totalCostProduction5paNoSkills = null;
      this.totalCostProduction10paNoSkills = null;
      this.totalCostProduction30paNoSkills = null;
      this.totalCostProduction50paNoSkills = null;
      this.totalCostProduction100paNoSkills = null;
      this.benefit1hNoSkills = null;
      this.benefit2hNoSkills = null;
      this.benefit6hNoSkills = null;
      this.benefit10hNoSkills = null;
      this.benefit22hNoSkills = null;
      this.benefit5paNoSkills = null;
      this.benefit10paNoSkills = null;
      this.benefit30paNoSkills = null;
      this.benefit50paNoSkills = null;
      this.benefit100paNoSkills = null;
      this.prod1hSkills = null;
      this.prod2hSkills = null;
      this.prod6hSkills = null;
      this.prod10hSkills = null;
      this.prod22hSkills = null;
      this.prod5paSkills = null;
      this.prod10paSkills = null;
      this.prod30paSkills = null;
      this.prod50paSkills = null;
      this.prod100paSkills = null;
      this.prodValue1hSkills = null;
      this.prodValue2hSkills = null;
      this.prodValue6hSkills = null;
      this.prodValue10hSkills = null;
      this.prodValue22hSkills = null;
      this.prodValue5paSkills = null;
      this.prodValue10paSkills = null;
      this.prodValue30paSkills = null;
      this.prodValue50paSkills = null;
      this.prodValue100paSkills = null;
      this.ironUsed1hSkills = null;
      this.ironUsed2hSkills = null;
      this.ironUsed6hSkills = null;
      this.ironUsed10hSkills = null;
      this.ironUsed22hSkills = null;
      this.ironUsed5paSkills = null;
      this.ironUsed10paSkills = null;
      this.ironUsed30paSkills = null;
      this.ironUsed50paSkills = null;
      this.ironUsed100paSkills = null;
      this.costIronUsed1hSkills = null;
      this.costIronUsed2hSkills = null;
      this.costIronUsed6hSkills = null;
      this.costIronUsed10hSkills = null;
      this.costIronUsed22hSkills = null;
      this.costIronUsed5paSkills = null;
      this.costIronUsed10paSkills = null;
      this.costIronUsed30paSkills = null;
      this.costIronUsed50paSkills = null;
      this.costIronUsed100paSkills = null;
      this.stoneUsed1hSkills = null;
      this.stoneUsed2hSkills = null;
      this.stoneUsed6hSkills = null;
      this.stoneUsed10hSkills = null;
      this.stoneUsed22hSkills = null;
      this.stoneUsed5paSkills = null;
      this.stoneUsed10paSkills = null;
      this.stoneUsed30paSkills = null;
      this.stoneUsed50paSkills = null;
      this.stoneUsed100paSkills = null;
      this.costStoneUsed1hSkills = null;
      this.costStoneUsed2hSkills = null;
      this.costStoneUsed6hSkills = null;
      this.costStoneUsed10hSkills = null;
      this.costStoneUsed22hSkills = null;
      this.costStoneUsed5paSkills = null;
      this.costStoneUsed10paSkills = null;
      this.costStoneUsed30paSkills = null;
      this.costStoneUsed50paSkills = null;
      this.costStoneUsed100paSkills = null;
      this.salary1hSkills = null;
      this.salary2hSkills = null;
      this.salary6hSkills = null;
      this.salary10hSkills = null;
      this.salary22hSkills = null;
      this.salary5paSkills = null;
      this.salary10paSkills = null;
      this.salary30paSkills = null;
      this.salary50paSkills = null;
      this.salary100paSkills = null;
      this.totalCostProduction1hSkills = null;
      this.totalCostProduction2hSkills = null;
      this.totalCostProduction6hSkills = null;
      this.totalCostProduction10hSkills = null;
      this.totalCostProduction22hSkills = null;
      this.totalCostProduction5paSkills = null;
      this.totalCostProduction10paSkills = null;
      this.totalCostProduction30paSkills = null;
      this.totalCostProduction50paSkills = null;
      this.totalCostProduction100paSkills = null;
      this.benefit1hSkills = null;
      this.benefit2hSkills = null;
      this.benefit6hSkills = null;
      this.benefit10hSkills = null;
      this.benefit22hSkills = null;
      this.benefit5paSkills = null;
      this.benefit10paSkills = null;
      this.benefit30paSkills = null;
      this.benefit50paSkills = null;
      this.benefit100paSkills = null;
      this.investmentTotalWeb = null;
      this.investmentTotalMob = null;
      this.investmentTotal = null;
      this.profitTotalWeb = null;
      this.profitTotalMob = null;
      this.profitTotal = null;
      this.investmentTotalNoSkillsWeb = null;
      this.investmentTotalNoSkillsMob = null;
      this.investmentTotalNoSkills = null;
      this.profitTotalNoSkillsWeb = null;
      this.profitTotalNoSkillsMob = null;
      this.profitTotalNoSkills = null;
      this.investmentTotalSkillsWeb = null;
      this.investmentTotalSkillsMob = null;
      this.investmentTotalSkills = null;
      this.profitTotalSkillsWeb = null;
      this.profitTotalSkillsMob = null;
      this.profitTotalSkills = null;
    }
  },
  watch: {
    typeMine() {
      this.levelMine = this.getOptimumByMine(this.typeMine);
    }
  }
}
</script>
<style>
.mines-revient {
  margin-top: 20px;
  text-align: center;
}
.mines-revient thead {
  font-size: 18px;
  color: #4091c9;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.mines-revient tbody td {
  padding: 2px;
  font-size: 12px;
  vertical-align: middle;
}
.mines-revient select {
  width: 90px;
  text-align: center;
  border-radius: 5px;
}
.mines-revient input {
  width: 60px;
  height: 24px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
}
.mines-revient button {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.mines-revient .table-label {
  font-weight: 500;
}
.mines-revient input[type=number] {
  -moz-appearance: textfield;
}
.mines-revient input::-webkit-inner-spin-button,
.mines-revient input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin:0;
}
.mines-revient input::-o-inner-spin-button,
.mines-revient input::-o-outer-spin-button {
  -o-appearance: none;
  margin:0
}
.mines-revient .td-right {
  border-right: 1px solid #4091c9;
}
.mines-revient .tr-left {
  border-left: 1px solid #4091c9;
}
.mines-revient .tr-bottom {
  border-bottom: 1px solid #4091c9;
}
.mines-revient .td-top {
  border-top: 1px solid #4091c9;
}
</style>
