import apiUser from "@/System/store/apiUser";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

let user = cookies.get('lbo-site-user');

if(null === user) {
  user = {
    token: '',
    role: '',
  };
} else {
  try {
    apiUser.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  } catch (e) {
    user = {
      token: '',
      role: '',
    };
  }
}

const state = {
  success: null,
  error: null,
  user: user,
}

export default state;
