<template>
  <div class="university-categories card">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormCategory" />
      </span>
    </div>
    <div class="card-body">

      <draggable-component :list="categories" :disabled="!enabled" item-key="name" class="list-group" ghost-class="ghost" @start="dragging = true" @end="checkMove">
        <template #item="{ element }">
          <div class="list-group-item items-order-categories" :class="{ 'not-draggable': !enabled }" :data-uuid="element.uuid">
            {{ element.name[$i18n.locale] }}
            <span class="actions">
              <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', element)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormCategory" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-trash-alt" @click="form('delete', element.uuid)" class="modal-icon-action" />
            </span>
          </div>
        </template>
      </draggable-component>
    </div>
  </div>

  <div class="modal fade" id="modalFormCategory" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ labelModal }}</h1>
        </div>
        <div class="modal-body">
          <category-form v-if="'add' === mode || 'edit' === mode" :category="category" :mode="mode"></category-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableComponent from "vuedraggable/src/vuedraggable";
import { mapState } from 'vuex'
import CategoryForm from "@/Admin/University/components/form/CategoryForm";

export default {
  name: "UniversityAdminCategories",
  components: {
    DraggableComponent,
    CategoryForm
  },
  computed: {
    ...mapState('adminUniversity', {
        categories: 'categories'
      }
    )
  },
  data() {
    return {
      mode: null,
      labelModal: '',
      category: {},
      enabled: true,
      dragging: false
    }
  },
  mounted() {
    this.$store.dispatch('adminUniversity/getCategories');
  },
  methods: {
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.category = {};
          this.labelModal = 'Ajouter une catégorie de voie d\'étude';
          break;
        case 'edit':
          this.category = datas;
          this.labelModal = 'Modifier une catégorie de voie d\'étude';
          break;
        case 'delete':
          this.$store.dispatch('adminUniversity/deleteCategory', {
            uuid: datas,
          });
          break;
      }
    },
    checkMove: function() {
      this.dragging = false;
      let items = document.getElementsByClassName('items-order-categories');
      let order = [];

      Array.from(items).forEach((item) => {
        order.push(item.getAttribute('data-uuid'));
      });

      this.$store.dispatch('adminUniversity/setOrder', {
        object: 'categories',
        uuids: order,
      });
    },
  }
};
</script>

<style>
.university-categories.card {
  border-radius: 0!important;
  border: none!important;
}
.university-categories .card-header {
  background-color: transparent;
}
.university-categories .card-header .actions {
  float: right;
  color: #4091c9;
}
.university-categories .list-group {
  border-radius: 0!important;
}
.university-categories .items-order-categories .actions {
  float: right;
  color: #4091c9;
}
.university-categories .items-order-categories .actions .modal-icon-action {
  cursor: pointer;
}
.university-categories .ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.university-categories .not-draggable {
  cursor: no-drop;
}
#modalFormCategory .modal-header {
  padding: 5px
}
#modalFormCategory .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
