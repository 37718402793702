<template>
  <ul class="nav nav-admin justify-content-center">
    <li class="nav-item">
      <a class="nav-link" :class="('admin' === $route.name) ? 'active' : ''" aria-current="page" href="admin">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-artisan' === $route.name) ? 'active' : ''" href="admin-artisan">Artisan</a>
    </li>
    <li class="nav-item">
      <a class="nav-link inactive" :class="('admin-cabine' === $route.name) ? 'active' : ''" href="admin-cabine">Cabines</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-contact' === $route.name) ? 'active' : ''" href="admin-contact">Contact</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-faq' === $route.name) ? 'active' : ''" href="admin-faq">FAQ</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-products' === $route.name) ? 'active' : ''" href="admin-products">Produits</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-translate' === $route.name) ? 'active' : ''" href="admin-translate">Traductions</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-university' === $route.name) ? 'active' : ''" href="admin-university">Université</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="('admin-users' === $route.name) ? 'active' : ''" href="admin-users">Utilisateurs</a>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'NavigationAdmin'
}
</script>

<style>
.nav-admin {
  margin-top: 20px;
  border-bottom: 2px solid #4091c9;
  padding-bottom: 10px;
}
.nav-admin .nav-link {
  color: dimgrey!important;
  text-transform: uppercase;
}
.nav-admin .nav-link.active {
  color: #4091c9!important;
}
.nav-admin .nav-link.inactive {
  color: red!important;
}
</style>
