<template>
  <form class="lesson-form">
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Voie de la matière</span>
      <select id="way" v-model="way" class="form-control">
        <option></option>
        <option v-for="(way, index) in ways" :value="way.uuid" :key="index">{{way.name[$i18n.locale]}}</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Nom de la matière</span>
      <input type="text" v-model="name" class="form-control" id="name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Prérequis</span>
      <ckeditor :editor="editor" v-model="prerequisite" :config="editorConfig" class="form-control col-10" id="prerequis"></ckeditor>
    </div>
  </form>
  <div class="lesson-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'LessonForm',
  props: {
    ways: Object,
    lesson: Object,
    mode: String,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'bulletedList']
      },
      way: null,
      name: null,
      prerequisite: '',
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.name = undefined !== this.lesson.name ? this.lesson.name[this.$i18n.locale] : null;
      this.prerequisite = undefined !== this.lesson.prerequisite && null !== this.lesson.prerequisite ? this.lesson.prerequisite[this.$i18n.locale] : '';
      if(undefined !== this.lesson.way) {
        let way = this.lesson.way;
        this.way = way.uuid;
      }
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminUniversity/addLesson', {
          name: this.name,
          way: this.way,
          prerequisite: this.prerequisite,
        });
      } else {
        this.$store.dispatch('adminUniversity/editLesson', {
          uuid: this.lesson.uuid,
          name: this.name,
          way: this.way,
          prerequisite: this.prerequisite,
        });
      }
    }
  },
  watch: {
    lesson() {
      this.getDatas();
    }
  },
}
</script>
<style>
.lesson-form .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.lesson-form .ck-editor__editable {
  min-height: 300px;
}
.lesson-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.lesson-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
