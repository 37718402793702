<template>
  <div class="content container-fluid mines">
    <div class="card page-content">
      <div class="card-header page-title">
        {{ $t('mines.title') }}
      </div>
      <div class="card-body page-body">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button @click="track('frequentation')" class="nav-link active" id="nav-frequentation-tab" data-bs-toggle="tab" data-bs-target="#nav-frequentation" type="button" role="tab" aria-selected="true">{{$t('mines.tab.frequency')}}</button>
          <button @click="track('revient')" class="nav-link" id="nav-revient-tab" data-bs-toggle="tab" data-bs-target="#nav-revient" type="button" role="tab" aria-selected="true">{{$t('mines.tab.ownership')}}</button>
          <button @click="track('hebdo')" class="nav-link" id="nav-hebdo-tab" data-bs-toggle="tab" data-bs-target="#nav-hebdo" type="button" role="tab" aria-selected="true">{{$t('mines.tab.weekly')}}</button>
          <button @click="track('production')" class="nav-link" id="nav-prod-tab" data-bs-toggle="tab" data-bs-target="#nav-prod" type="button" role="tab" aria-selected="true">{{$t('mines.tab.prod')}}</button>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="separator"></div>
          <div class="tab-pane fade show active" id="nav-frequentation" role="tabpanel">
            <mines-frequentation></mines-frequentation>
          </div>
          <div class="tab-pane fade show" id="nav-revient" role="tabpanel">
            <mines-revient></mines-revient>
          </div>
          <div class="tab-pane fade show" id="nav-hebdo" role="tabpanel">
            <mines-hebdo></mines-hebdo>
          </div>
          <div class="tab-pane fade show" id="nav-prod" role="tabpanel">
            <mines-production></mines-production>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MinesFrequentation from "@/Open/Mines/components/MinesFrequentation";
import MinesRevient from "@/Open/Mines/components/MinesRevient";
import MinesHebdo from "@/Open/Mines/components/MinesHebdo";
import MinesProduction from "@/Open/Mines/components/MinesProduction.vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'MinesView',
  components: {
    MinesFrequentation,
    MinesRevient,
    MinesHebdo,
    MinesProduction
  },
  mounted() {
    this.$store.dispatch('mines/tracking', {
      page: 'open-mines',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
    this.track('frequentation');
  },
  methods: {
    track(tab) {
      this.$store.dispatch('mines/tracking', {
        page: 'open-mines',
        component: tab,
        visitor: cookies.get('lbo-site-visitor')
      });    }
  }
}
</script>

<style>
.mines {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.mines .page-content {
  border: none;
}
.mines .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.mines .nav-link {
  color: #000000;
}
.mines .nav-link.active {
  color: #4091c9!important;
}
</style>
