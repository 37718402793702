<template>
  <tr class="line-table-craftspeople">
    <td class="calc-input">{{recipe.quantityProduction}}</td>
    <td class="matieres-premieres">
      <ul>
        <li v-for="(ingredient, ind) in recipe.ingredients" :key="ind">
          {{ingredient.quantity}} {{ingredient.product.name[$i18n.locale]}}
        </li>
      </ul>
    </td>
    <td class="matieres-premieres">
      <ul>
        <li v-for="(ingredient, ind) in recipe.ingredients" :key="ind">
          <select-min-max :min="ingredient.product.priceMin" :max="ingredient.product.priceMax" @change="calcGainTotal(line)" class="select-price" :id="'buy-unit-'+line+'-'+ind" :data-buy-qty="ingredient.quantity"></select-min-max>
          <select-price-cent class="select-price" @change="calcGainTotal(line)" :id="'buy-cent-'+line+'-'+ind"></select-price-cent>
        </li>
      </ul>
    </td>
    <td class="calc-input">{{ calcTime(recipe.duration) }} h</td>
    <td class="calc-input">{{ calcTime(recipe.durationPa) }} PA</td>
    <td class="calc-input">
      <select-min-max :min="production.priceMin" :max="production.priceMax" class="select-price" @change="calcGainTotal(line)" :id="'sell-unit-'+line" :data-sell-qty="recipe.quantityProduction"></select-min-max>
      <select-price-cent class="select-price" @change="calcGainTotal(line)" :id="'sell-cent-'+line"></select-price-cent>
    </td>
    <td class="calc-input gains-empty" :id="'gain-total-'+line"></td>
    <td class="calc-input gains-empty" :id="'gain-unit-'+line"></td>
    <td class="calc-input">
      <input class="input-empty" type="text" :id="'need-'+line" @change="calcGainTotal(line)">
    </td>
    <td class="calc-input gains-empty" :id="'gain-need-'+line"></td>
  </tr>
</template>

<script>
import SelectMinMax from "@/components/element/SelectMinMax";
import SelectPriceCent from "@/components/element/SelectPriceCent";

export default {
  name: 'LineTableCraftspeople',
  components: {
    SelectMinMax,
    SelectPriceCent,
  },
  props: {
    line: Number,
    recipe: Object,
    production: Object,
    intelligence: Number,
    nbIngredients: Number
  },
  methods: {
    calcTime(time) {
      let reduction = Math.round(20 * this.intelligence / 255);
      //reduction += this.competencePa;
      return  Math.round(time - (time * reduction / 100));
    },
    calcGainTotal(line) {
      let datas = this.getDataCalc(line);

      let investment = 0;
      let gain = Math.round((datas.priceSell[0].price * datas.priceSell[0].qty) * 100) / 100;

      for(let i = 0; i<datas.pricesBuy.length; i++) {
        investment += Math.round((datas.pricesBuy[i].price * datas.pricesBuy[i].qty) * 100) / 100;
      }

      let beneficeTotal = Math.round((gain - investment) * 100) / 100;
      let beneficeUnit = Math.round((beneficeTotal / datas.priceSell[0].qty) * 100) / 100;
      document.getElementById('gain-total-'+line).innerText = beneficeTotal;
      document.getElementById('gain-unit-'+line).innerText = beneficeUnit;

      let needSell = Math.round(((datas.need + investment) / datas.priceSell[0].qty) * 100) / 100;
      document.getElementById('gain-need-'+line).innerText = needSell;
    },
    getDataCalc(line) {
      let pricesBuy = [];
      let priceSell = [];

      for(let i = 0; i < this.nbIngredients; i++) {
        let selectUnit = document.getElementById('buy-unit-'+line+'-'+i);
        let unit = selectUnit.value;
        let cent = document.getElementById('buy-cent-'+line+'-'+i).value;
        let qty = Math.round(selectUnit.getAttribute('data-buy-qty') * 100) / 100;
        pricesBuy.push({
          qty: qty,
          price: Math.round((unit + '.'+ cent) * 100) / 100
        });
      }

      let selectUnitSell = document.getElementById('sell-unit-'+line);
      let unitSell = selectUnitSell.value;
      let centSell = document.getElementById('sell-cent-'+line).value;
      let qty = Math.round(selectUnitSell.getAttribute('data-sell-qty') * 100) / 100;
      priceSell.push({
        qty: qty,
        price: Math.round((unitSell+'.'+centSell) * 100) / 100
      })

      let needValue = 0;
      let input = document.getElementById('need-'+line).value;
      if('' !== input) {
        needValue += Math.round(input * 100) / 100;
      }

      return {
        pricesBuy: pricesBuy,
        priceSell: priceSell,
        need: needValue
      }
    }
  },
  watch: {
    production() {
      let empties = document.getElementsByClassName('gains-empty');
      let inputs = document.getElementsByClassName('input-empty');
      Array.from(empties).forEach((empty) => {
        empty.innerText = '';
      });

      Array.from(inputs).forEach((empty) => {
        empty.value = '';
      });
    }
  }
}
</script>

<style>
.line-table-craftspeople .matieres-premieres ul {
  list-style: disclosure-closed;
  margin-bottom: 0;
}
.line-table-craftspeople .matieres-premieres ul li {
  font-size: 12px;
  line-height: 24px;
}
.line-table-craftspeople .select-price {
  width: 50px;
  height: 24px;
  border-radius: 5px;
  text-align: center;
}
.line-table-craftspeople .calc-input {
  text-align: center;
  vertical-align: middle;
}
.line-table-craftspeople .calc-input input {
  width: 40px;
  height: 24px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
}
</style>
