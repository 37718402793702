import HomeView from "@/Player/Home/views/HomeView";
import CraftspeopleView from "@/Player/Craftspeople/views/CraftspeopleView";
import CabineView from "@/Player/Cabine/view/CabineView";
import ContactView from "@/Player/Contact/views/ContactView";
import ProductsView from "@/Player/Products/views/ProductsView";
import TranslateView from "@/Player/Translate/views/TranslateView";

export default [
  {
    path: '/player-craftspeople',
    name: 'player-craftspeople',
    component: CraftspeopleView
  },
  {
    path: '/player',
    name: 'player',
    component: HomeView
  },
  {
    path: '/player-cabine',
    name: 'player-cabine',
    component: CabineView
  },
  {
    path: '/player-contact',
    name: 'player-contact',
    component: ContactView
  },
  {
    path: '/player-products',
    name: 'player-products',
    component: ProductsView
  },
  {
    path: '/player-translate',
    name: 'player-translate',
    component: TranslateView
  },
];
