import { createRouter, createWebHistory } from 'vue-router'
import admin from "@/Admin/router"
import open from "@/Open/router"
import player from "@/Player/router"


const routes = [
  ...admin,
  ...open,
  ...player
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'La Boîte à Outils';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
