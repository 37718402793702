<template>
  <div class="content container-fluid admin-contact">
    <navigation-admin></navigation-admin>

    <contact-list></contact-list>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import ContactList from "@/Admin/Contact/components/ContactList";

export default {
  name: 'ContactView',
  components: {
    NavigationAdmin,
    ContactList
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-contact {
  width: 95%!important;
}
</style>
