<template>
  <table class="table table-bordered table-striped mines-frequentations">
    <thead>
    <tr class="tr-left">
      <td colspan="9" class="td-top td-right">{{$t('mines.titles.frequency')}}</td>
    </tr>
    </thead>
    <tbody>
      <tr class="tr-left">
        <td width="32%" class="td-top td-right table-label">{{$t('mines.type')}}</td>
        <td colspan="8" class="td-top td-right">
          <select v-model="typeMine">
            <option value="iron">{{$t('mines.type_mines.iron')}}</option>
            <option value="stone">{{$t('mines.type_mines.stone')}}</option>
            <option value="gold">{{$t('mines.type_mines.gold')}}</option>
            <option value="clay">{{$t('mines.type_mines.clay')}}</option>
            <option value="salt">{{$t('mines.type_mines.salt')}}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{ $t('mines.level') }}</td>
        <td colspan="8" class="td-right">
          <select v-model="levelMine">
            <option v-for="n in arrayOfValues(3, 17)" :key="n" :value="n">{{ n }}</option>
          </select>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="td-top td-right table-label">{{ $t('mines.format') }}</td>
        <td class="td-top table-label">{{ $t('mines.web') }}</td>
        <td class="td-top table-label">{{$t('mines.time_without_skills')}}</td>
        <td class="td-top table-label">{{$t('mines.time_skills_down')}}</td>
        <td class="td-top td-right table-label">{{$t('mines.time_skills_up')}}</td>
        <td class="td-top table-label">{{ $t('mines.mobile') }}</td>
        <td class="td-top table-label">{{$t('mines.time_without_skills')}}</td>
        <td class="td-top table-label">{{$t('mines.time_skills_down')}}</td>
        <td class="td-top td-right">{{$t('mines.time_skills_up')}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{$t('mines.time.1h')}} | {{$t('mines.time.5pa')}}</td>
        <td width="11%">
          <input type="number" v-model="time1h">
        </td>
        <td width="6%">{{resultTime1h}}</td>
        <td width="6%">{{resultTime1hNoSkills}}</td>
        <td width="6%" class="td-right">{{resultTime1hSkills}}</td>
        <td width="11%">
          <input type="number" v-model="time5pa">
        </td>
        <td width="6%">{{resultTime5pa}}</td>
        <td width="6%">{{resultTime5paNoSkills}}</td>
        <td width="6%" class="td-right">{{resultTime5paSkills}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{$t('mines.time.2h')}} | {{$t('mines.time.10pa')}}</td>
        <td>
          <input type="number" v-model="time2h">
        </td>
        <td>{{resultTime2h}}</td>
        <td>{{resultTime2hNoSkills}}</td>
        <td class="td-right">{{resultTime2hSkills}}</td>
        <td>
          <input type="number" v-model="time10pa">
        </td>
        <td>{{resultTime10pa}}</td>
        <td>{{resultTime10paNoSkills}}</td>
        <td class="td-right">{{resultTime10paSkills}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{$t('mines.time.6h')}} | {{$t('mines.time.30pa')}}</td>
        <td>
          <input type="number" v-model="time6h">
        </td>
        <td>{{resultTime6h}}</td>
        <td>{{resultTime6hNoSkills}}</td>
        <td class="td-right">{{resultTime6hSkills}}</td>
        <td>
          <input type="number" v-model="time30pa">
        </td>
        <td>{{resultTime30pa}}</td>
        <td>{{resultTime30paNoSkills}}</td>
        <td class="td-right">{{resultTime30paSkills}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{$t('mines.time.10h')}} | {{$t('mines.time.50pa')}}</td>
        <td>
          <input type="number" v-model="time10h">
        </td>
        <td>{{resultTime10h}}</td>
        <td>{{resultTime10hNoSkills}}</td>
        <td class="td-right">{{resultTime10hSkills}}</td>
        <td>
          <input type="number" v-model="time50pa">
        </td>
        <td>{{resultTime50pa}}</td>
        <td>{{resultTime50paNoSkills}}</td>
        <td class="td-right">{{resultTime50paSkills}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right table-label">{{$t('mines.time.22h')}} | {{$t('mines.time.100pa')}}</td>
        <td>
          <input type="number" v-model="time22h">
        </td>
        <td>{{resultTime22h}}</td>
        <td>{{resultTime22hNoSkills}}</td>
        <td class="td-right">{{resultTime22hSkills}}</td>
        <td>
          <input type="number" v-model="time100pa">
        </td>
        <td>{{resultTime100pa}}</td>
        <td>{{resultTime100paNoSkills}}</td>
        <td class="td-right">{{resultTime100paSkills}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td></td>
        <td colspan="8" class="td-right">
          <button @click="calculate">{{ $t('mines.calculate') }}</button>
        </td>
      </tr>
      <tr class="tr-left">
        <td class="td-right"></td>
        <td colspan="8" class="td-right table-label">{{ $t('mines.without_skills') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{ $t('mines.total_format') }}</td>
        <td colspan="4">{{totalTimeH}}</td>
        <td colspan="4" class="td-right">{{totalTimePa}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right table-label">{{ $t('mines.total') }}</td>
        <td colspan="8" class="td-right">{{totalMine}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right"></td>
        <td colspan="8" class="td-right table-label">{{ $t('mines.with_skills_down') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{ $t('mines.total_format') }}</td>
        <td colspan="4">{{totalTimeHNoSkills}}</td>
        <td colspan="4" class="td-right">{{totalTimePaNoSkills}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-right table-label">{{ $t('mines.total') }}</td>
        <td colspan="8" class="td-right">{{totalMineNoSkills}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right"></td>
        <td colspan="8" class="td-right table-label">{{ $t('mines.with_skills_up') }}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{ $t('mines.total_format') }}</td>
        <td colspan="4">{{totalTimeHSkills}}</td>
        <td colspan="4" class="td-right">{{totalTimePaSkills}}</td>
      </tr>
      <tr class="tr-left">
        <td class="td-right table-label">{{ $t('mines.total') }}</td>
        <td colspan="8" class="td-right">{{totalMineSkills}}</td>
      </tr>
      <tr class="tr-left tr-bottom">
        <td class="td-top"></td>
        <td colspan="8" class="td-top td-right">
          <button @click="reset">{{ $t('mines.reset') }}</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MinesFrequentation',
  data() {
    return {
      typeMine: null,
      levelMine: null,
      time1h: null,
      time2h: null,
      time6h: null,
      time10h: null,
      time22h: null,
      time5pa: null,
      time10pa: null,
      time30pa: null,
      time50pa: null,
      time100pa: null,
      resultTime1h: null,
      resultTime2h: null,
      resultTime6h: null,
      resultTime10h: null,
      resultTime22h: null,
      resultTime5pa: null,
      resultTime10pa: null,
      resultTime30pa: null,
      resultTime50pa: null,
      resultTime100pa: null,
      totalTimeH: null,
      totalTimePa: null,
      totalMine: null,
      resultTime1hNoSkills: null,
      resultTime2hNoSkills: null,
      resultTime6hNoSkills: null,
      resultTime10hNoSkills: null,
      resultTime22hNoSkills: null,
      resultTime5paNoSkills: null,
      resultTime10paNoSkills: null,
      resultTime30paNoSkills: null,
      resultTime50paNoSkills: null,
      resultTime100paNoSkills: null,
      totalTimeHNoSkills: null,
      totalTimePaNoSkills: null,
      totalMineNoSkills: null,
      resultTime1hSkills: null,
      resultTime2hSkills: null,
      resultTime6hSkills: null,
      resultTime10hSkills: null,
      resultTime22hSkills: null,
      resultTime5paSkills: null,
      resultTime10paSkills: null,
      resultTime30paSkills: null,
      resultTime50paSkills: null,
      resultTime100paSkills: null,
      totalTimeHSkills: null,
      totalTimePaSkills: null,
      totalMineSkills: null,
    }
  },
  mounted() {
    this.typeMine = 'iron';
    this.levelMine = this.getOptimumByMine(this.typeMine);
  },
  methods: {
    calculate() {
      let outputMine = this.getOutputByMine(this.typeMine);

      this.resultTime1h = this.getProd(this.time1h, outputMine[this.levelMine].output, 1, 3);
      this.resultTime2h = this.getProd(this.time2h, outputMine[this.levelMine].output, 2, 2);
      this.resultTime6h = this.getProd(this.time6h, outputMine[this.levelMine].output, 6, 1.35);
      this.resultTime10h = this.getProd(this.time10h, outputMine[this.levelMine].output, 10, 1.15);
      this.resultTime22h = this.getProd(this.time22h, outputMine[this.levelMine].output, 22, 1);
      this.resultTime5pa = this.getProd(this.time5pa, outputMine[this.levelMine].output, 1, 1.8);
      this.resultTime10pa = this.getProd(this.time10pa, outputMine[this.levelMine].output, 2, 1.6);
      this.resultTime30pa = this.getProd(this.time30pa, outputMine[this.levelMine].output, 6, 1.35);
      this.resultTime50pa = this.getProd(this.time50pa, outputMine[this.levelMine].output, 10, 1.15);
      this.resultTime100pa = this.getProd(this.time100pa, outputMine[this.levelMine].output, 22, 1);

      this.totalTimeH = (Math.round((this.resultTime1h + this.resultTime2h + this.resultTime6h + this.resultTime10h + this.resultTime22h) * 100) / 100);
      this.totalTimePa = (Math.round((this.resultTime5pa + this.resultTime10pa + this.resultTime30pa + this.resultTime50pa + this.resultTime100pa) * 100) / 100);
      this.totalMine = (Math.round((this.totalTimeH + this.totalTimePa) * 100) / 100);

      this.resultTime1hNoSkills = this.getProdMin(this.resultTime1h);
      this.resultTime2hNoSkills =  this.getProdMin(this.resultTime2h);
      this.resultTime6hNoSkills =  this.getProdMin(this.resultTime6h);
      this.resultTime10hNoSkills =  this.getProdMin(this.resultTime10h);
      this.resultTime22hNoSkills =  this.getProdMin(this.resultTime22h);
      this.resultTime5paNoSkills =  this.getProdMin(this.resultTime5pa);
      this.resultTime10paNoSkills = this.getProdMin(this.resultTime10pa);
      this.resultTime30paNoSkills = this.getProdMin(this.resultTime30pa);
      this.resultTime50paNoSkills = this.getProdMin(this.resultTime50pa);
      this.resultTime100paNoSkills = this.getProdMin(this.resultTime100pa);

      this.totalTimeHNoSkills = Math.round((this.resultTime1hNoSkills + this.resultTime2hNoSkills + this.resultTime6hNoSkills + this.resultTime10hNoSkills + this.resultTime22hNoSkills) * 100) / 100;
      this.totalTimePaNoSkills = (Math.round((this.resultTime5paNoSkills + this.resultTime10paNoSkills + this.resultTime30paNoSkills + this.resultTime50paNoSkills + this.resultTime100paNoSkills) * 100) / 100);
      this.totalMineNoSkills = (Math.round((this.totalTimeHNoSkills + this.totalTimePaNoSkills) * 100) / 100);

      this.resultTime1hSkills = this.getProdMax(this.resultTime1h);
      this.resultTime2hSkills = this.getProdMax(this.resultTime2h);
      this.resultTime6hSkills = this.getProdMax(this.resultTime6h);
      this.resultTime10hSkills = this.getProdMax(this.resultTime10h);
      this.resultTime22hSkills = this.getProdMax(this.resultTime22h);
      this.resultTime5paSkills = this.getProdMax(this.resultTime5pa);
      this.resultTime10paSkills = this.getProdMax(this.resultTime10pa);
      this.resultTime30paSkills = this.getProdMax(this.resultTime30pa);
      this.resultTime50paSkills = this.getProdMax(this.resultTime50pa);
      this.resultTime100paSkills = this.getProdMax(this.resultTime100pa);

      this.totalTimeHSkills = (Math.round((this.resultTime1hSkills + this.resultTime2hSkills + this.resultTime6hSkills + this.resultTime10hSkills + this.resultTime22hSkills) * 100) / 100);
      this.totalTimePaSkills = (Math.round((this.resultTime5paSkills + this.resultTime10paSkills + this.resultTime30paSkills + this.resultTime50paSkills + this.resultTime100paSkills) * 100) / 100);
      this.totalMineSkills = (Math.round((this.totalTimeHSkills + this.totalTimePaSkills) * 100) / 100);
    },
    getProdMin(value) {
      return Math.round((value - (value * 0.15)) * 100) /100;
    },
    getProdMax(value) {
      return Math.round((value * 1.10) * 100) /100;
    },
    getOutputByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].output;
    },
    getOptimumByMine(mine) {
      return this.$store.state.mines.output.filter(o => o.mine === mine)[0].optimum;
    },
    getProd(miners, level, hourly, ratio) {
      let result = miners / 22;
      result = Math.round((result * hourly * ratio * level) * 100) / 100;
      return result;
    },
    arrayOfValues(min, max) {
      let values = [];
      for (let i = min; i <= max; i++) {
        values.push(i);
      }
      return values;
    },
    reset() {
      this.levelMine = this.getOptimumByMine(this.typeMine);
      this.time1h = null;
      this.time2h = null;
      this.time6h = null;
      this.time10h = null;
      this.time22h = null;
      this.time5pa = null;
      this.time10pa = null;
      this.time30pa = null;
      this.time50pa = null;
      this.time100pa = null;
      this.resultTime1h = null;
      this.resultTime2h = null;
      this.resultTime6h = null;
      this.resultTime10h = null;
      this.resultTime22h = null;
      this.resultTime5pa = null;
      this.resultTime10pa = null;
      this.resultTime30pa = null;
      this.resultTime50pa = null;
      this.resultTime100pa = null;
      this.totalTimeH = null;
      this.totalTimePa = null;
      this.totalMine = null;
      this.resultTime1hNoSkills = null;
      this.resultTime2hNoSkills =  null;
      this.resultTime6hNoSkills =  null;
      this.resultTime10hNoSkills =  null;
      this.resultTime22hNoSkills =  null;
      this.resultTime5paNoSkills =  null;
      this.resultTime10paNoSkills = null;
      this.resultTime30paNoSkills = null;
      this.resultTime50paNoSkills = null;
      this.resultTime100paNoSkills = null;
      this.totalTimeHNoSkills = null;
      this.totalTimePaNoSkills = null;
      this.totalMineNoSkills = null;
      this.resultTime1hSkills = null;
      this.resultTime2hSkills = null;
      this.resultTime6hSkills = null;
      this.resultTime10hSkills = null;
      this.resultTime22hSkills = null;
      this.resultTime5paSkills = null;
      this.resultTime10paSkills = null;
      this.resultTime30paSkills = null;
      this.resultTime50paSkills = null;
      this.resultTime100paSkills = null;
      this.totalTimeHSkills = null;
      this.totalTimePaSkills = null;
      this.totalMineSkills = null;
    },
  },
  watch: {
    typeMine() {
      this.levelMine = this.getOptimumByMine(this.typeMine);
    }
  }
}
</script>

<style>
.mines-frequentations {
  margin-top: 20px;
  text-align: center;
}
.mines-frequentations thead {
  font-size: 18px;
  color: #4091c9;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.mines-frequentations tbody td {
  padding: 2px;
  font-size: 12px;
  vertical-align: middle;
}
.mines-frequentations select {
  width: 90px;
  text-align: center;
  border-radius: 5px;
}
.mines-frequentations input {
  width: 60px;
  height: 24px;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
}
.mines-frequentations button {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.mines-frequentations .table-label {
   font-weight: 500;
}
.mines-frequentations input[type=number] {
  -moz-appearance: textfield;
}
.mines-frequentations input::-webkit-inner-spin-button,
.mines-frequentations input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin:0;
}
.mines-frequentations input::-o-inner-spin-button,
.mines-frequentations input::-o-outer-spin-button {
  -o-appearance: none;
  margin:0
}
.mines-frequentations .td-right {
  border-right: 1px solid #4091c9;
}
.mines-frequentations .tr-left {
  border-left: 1px solid #4091c9;
}
.mines-frequentations .tr-bottom {
  border-bottom: 1px solid #4091c9;
}
.mines-frequentations .td-top {
  border-top: 1px solid #4091c9;
}
</style>
