<template>
  <div v-for="(recipe, index) in recipes" :key="index" class="desynchro-form-edit-recipes">
    <div class="row">
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.time_hour')}}</span>
          <input type="text" v-model="recipe.duration" @change="emit()" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.time_pa')}}</span>
          <input type="text" v-model="recipe.durationPa" @change="emit()" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.quantity_output')}}</span>
          <input type="text" v-model="recipe.quantityProduction" @change="emit()" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">         </div>
      </div>
    </div>

    <div class="details">
      <div class="row" v-for="(ingredient, index) in recipe.ingredients" :key="index">
        <div class="col-6">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.ingredient')}}</span>
            <select id="domain" v-model="ingredient.product" @change="emit()" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
              <option></option>
              <option v-for="(product, i) in products" :key="i" :value="product.uuid">{{product.name[$i18n.locale]}}</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.quantity')}}</span>
            <input type="number" v-model="ingredient.quantity" @change="emit()" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "DesynchroFormEdit",
  props: {
    nbIngredients: Number,
    nbDesynchro: Number,
    listRecipes: Array
  },
  computed: {
    ...mapState('playerCraftspeople', {
        products: 'products',
      }
    )
  },
  data() {
    return {
      recipes: this.listRecipes,
    }
  },
  emits :{
    fetchRecipe: null,
  },
  mounted() {
    this.$store.dispatch('playerCraftspeople/getProducts');
  },
  methods: {
    emit() {
      this.$emit("fetchRecipe", this.recipes);
    }
  },
  watch: {
    listRecipes() {
      this.recipes = this.listRecipes;
    }
  }
};
</script>
<style>
.desynchro-form-edit-recipes {
  border: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px;
}
.desynchro-form-edit-recipes .details {
  width: 90%;
  margin: auto;
}
</style>
