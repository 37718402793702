<template>
  <div class="content container-fluid admin-craftspeople">
    <navigation-player></navigation-player>

    <craftspeople-productions></craftspeople-productions>
  </div>
</template>

<script>
import NavigationPlayer from "@/Player/NavigationPlayer";
import CraftspeopleProductions from "@/Player/Craftspeople/components/CraftspeopleProductions";

export default {
  name: 'CraftspeopleView',
  components: {
    NavigationPlayer,
    CraftspeopleProductions,
  },
}
</script>

<style>
.admin-craftspeople {
  width: 95%!important;
}
</style>
