import apiUser from "@/System/store/apiUser";

const actions = {
  getCraftspeoples: ({commit}) => {
    apiUser.get('/close-api/craftspeoples')
      .then(function (response) {
        commit('setCraftspeoples', response.data);
      });
  },
  getProducts: ({commit}) => {
    apiUser.get('/close-api/products')
      .then(function (response) {
        commit('setProducts', response.data);
      });
  },
  getProductions: ({commit}) => {
    apiUser.get('/close-api/productions')
      .then(function (response) {
        commit('setProductions', response.data);
      });
  },
  addProduction: ({commit}, payload) => {
    apiUser.post('/close-api/productions', payload)
      .then(function () {
        apiUser.get('/close-api/productions')
          .then(function (response) {
            commit('setProductions', response.data);
          });
      });
  },
  editProduction: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      craftspeople: datas.craftspeople,
      product: datas.product,
      recipes: datas.recipes,
      slug: datas.slug,
      note: datas.note,
    };

    apiUser.put('/close-api/production/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/productions')
          .then(function (response) {
            commit('setProductions', response.data);
          });
      });
  },
  deleteProduction: ({commit}, datas) => {
    apiUser.delete('/close-api/production/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/productions')
          .then(function (response) {
            commit('setProductions', response.data);
          });
      });
  },
  publishProduction: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state,
    };

    apiUser.put('/close-api/production/'+uuid+'/publish', payload)
      .then(function () {
        apiUser.get('/close-api/productions')
          .then(function (response) {
            commit('setProductions', response.data);
          });
      });
  },
}

export default actions;
