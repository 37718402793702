<template>
  <tr v-for="(translate, index) in translates" :key="index" class="translate-list">
    <td v-html="truncate(translate.fr)"></td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.frStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.enStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.itStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.deStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.esStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.buStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.nlStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.plStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.ptStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.daStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.elStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.roStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.ruStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.trStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.ctStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.slStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.hrStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.huStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.czStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.seStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.srStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.fiStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.alStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.baStatus)" />
    </td>
    <td class="status">
      <font-awesome-icon icon="fa-solid fa-circle-check" :class="status(translate.euStatus)" />
    </td>
    <td class="actions">
      <font-awesome-icon icon="fa-solid fa-circle-check" @click="form(translate)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormValidTranslate" />&nbsp;&nbsp;&nbsp;
      <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form(translate)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormTranslate" />
    </td>
  </tr>

  <div class="modal fade" id="modalFormTranslate" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Gestion de la traduction</h1>
        </div>
        <div class="modal-body">
          <translate-form :translate="translate"></translate-form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modalFormValidTranslate" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Validation des traductions</h1>
        </div>
        <div class="modal-body">
          <validation-form :translate="translate"></validation-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateForm from "@/Admin/Translate/components/form/TranslateForm";
import ValidationForm from "@/Admin/Translate/components/form/ValidationForm";

export default {
  name: "TranslatesList",
  components: {
    TranslateForm,
    ValidationForm
  },
  props: {
    translates: Array,
  },
  data() {
    return {
      translate: {},
    }
  },
  methods: {
    form(datas) {
      this.translate = datas;
    },
    truncate(value) {
      if (value.length > 300) {
        return value.substring(0, 300) + "...";
      } else {
        return value;
      }
    },
    status(state) {
      switch (state) {
        case 1:
          return 'wait';
        case 2:
          return 'valid';
        default:
          return 'empty';
      }
    }
  }
};
</script>

<style>
.translate-list .empty {
  color: #ff0000;
}
.translate-list .wait {
  color: #ff9e00;
}
.translate-list .valid {
  color: #008000;
}
.translate-list .status,
.translate-list .actions {
  text-align: center;
}
.translate-list .modal-icon-action {
  color: #4091c9;
  cursor: pointer;
}
#modalFormTranslate .modal-header {
  padding: 5px
}
#modalFormTranslate .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
