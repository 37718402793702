import apiUser from "@/System/store/apiUser";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();


const mutations = {
  setSuccess: function (state) {
    state.success = true;
  },
  setError: function (state) {
    state.error = true;
  },
  connexionUser: function (state, user) {
    cookies.set('lbo-site-user', JSON.stringify(user),0);
    state.user = user;
    apiUser.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  },
  logout: function (state) {
    state.user = {
      token:'',
      role:''
    };
    cookies.remove('lbo-site-user');
  },
}

export default mutations;

