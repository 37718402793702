import api from "@/System/store/api";

const actions = {
  getCraftspeoples: ({commit}) => {
    api.get('/api/craftspeoples')
      .then(function (response) {
        commit('setCraftspeoples', response.data);
      });
  },
  getProductions: ({commit}) => {
    api.get('/api/productions/publish')
      .then(function (response) {
        commit('setProductions', response.data);
      });
  },
  getProduction: ({commit}, uuid) => {
    api.get('/api/production/'+uuid)
      .then(function (response) {
        commit('setProduction', response.data);
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
