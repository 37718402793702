import HomeView from '@/Open/Home/views/HomeView.vue'
import PeasantView from '@/Open/Peasant/views/PeasantView.vue'
import ProductsView from "@/Open/Products/views/ProductsView";
import MinesView from "@/Open/Mines/views/MinesView";
import UniversityView from "@/Open/University/views/UniversityView";
import CraftspeopleView from "@/Open/Craftspeople/views/CraftspeopleView";
import ContactView from "@/Open/Contact/views/ContactView";
import FaqView from "@/Open/Faq/views/FaqView";
import AccountView from "@/Open/User/views/AccountView";
import UserView from "@/Open/User/views/UserView";

import RoyaumeView from "@/Open/Kingdoms/views/RoyaumeView";
import CabineView from "@/Open/Cabine/views/CabineView";
import Cabine3DView from "@/Open/House/views/Cabine3DView";
import ItineraireView from "@/Open/Road/views/ItineraireView";

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/paysans',
    name: 'paysans',
    component: PeasantView
  },
  {
    path: '/artisans',
    name: 'artisans',
    component: CraftspeopleView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/university',
    name: 'university',
    component: UniversityView
  },
  {
    path: '/mines',
    name: 'mines',
    component: MinesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/inscription-connexion',
    name: 'inscription-connexion',
    component: UserView
  },
  {
    path: '/player-account',
    name: 'player-account',
    component: AccountView
  },



  {
    path: '/royaumes',
    name: 'royaumes',
    component: RoyaumeView
  },
  {
    path: '/cabine',
    name: 'cabine',
    component: CabineView
  },
  {
    path: '/cabine3d',
    name: 'cabine3d',
    component: Cabine3DView
  },
  {
    path: '/itineraire',
    name: 'itineraire',
    component: ItineraireView
  },
];
