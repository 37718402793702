import api from "@/System/store/api";

const actions = {
  getBooks: ({commit}) => {
    api.get('/api/university/books')
      .then(function (response) {
        commit('setBooks', response.data);
      })
  },
  getLessonsByWay: ({commit}, way) => {
    api.get('/api/university/lessons/way/'+way)
      .then(function (response) {
        commit('setLessonsByWay', response.data);
      })
  },
  getWays: ({commit}) => {
    api.get('/api/university/ways')
      .then(function (response) {
        commit('setWays', response.data);
      })
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
