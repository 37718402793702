<template>
  <select>
    <option v-for="n in arrayOfCent()" :key="n" :value="n">{{ n }}</option>
  </select>
</template>

<script>

export default {
  name: 'SelectPriceCent',
  methods: {
    arrayOfCent() {
      let values = [];
      for (let i = 0; i <= 95; i++) {
        if(0 === i % 5) {
          values.push(i);
        }
      }
      return values;
    },
  }
}

</script>

<style>
</style>
