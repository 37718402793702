<template>
  <div class="content container-fluid cabine">
    <div class="card page-content">
      <div class="card-header page-title">
        Itinéraire
      </div>
      <div class="card-body page-body">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ItineraireView'
}
</script>
