<template>
  <div class="content container-fluid home">
    <div class="row">
      <div class="col-6">
        <div class="card home-welcome">
          <div class="card-header">
            {{ $t('home.welcome') }}
          </div>
          <div class="card-body" v-html="$t('home.welcome_text')"></div>
        </div>
      </div>
      <div class="col-6">
        <home-actuality></home-actuality>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <home-deploy></home-deploy>
      </div>
      <div class="col-6">
        <home-timeline></home-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import HomeActuality from "@/Open/Home/components/HomeActuality";
import HomeDeploy from "@/Open/Home/components/HomeDeploy";
import HomeTimeline from "@/Open/Home/components/HomeTimeline";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'HomeView',
  components: {
    HomeActuality,
    HomeDeploy,
    HomeTimeline
  },
  mounted() {
    this.$store.dispatch('home/tracking', {
      page: 'open-home',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  }
}
</script>

<style>
.home {
  margin-top: 20px;
}
.home-welcome.card {
  border: none;
}
.home-welcome .card-header {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
}
.home-welcome .card-body {
  text-align: justify;
}
.home-welcome .card-body a {
  text-decoration: underline;
  color: #4091c9;
}
</style>
