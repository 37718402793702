const mutations = {
  setCraftspeoples: function (state, craftspeoples) {
    state.craftspeoples = craftspeoples;
  },
  setProducts: function (state, products) {
    state.products = products;
  },
  setCategories: function (state, categories) {
    state.categories = categories;
  },
}

export default mutations;
