<template>
  <div class="card home-timeline">
    <div class="card-header">
      Timeline
    </div>
    <div class="card-body">
      <div class="timeline">
        <div class="timeline-block timeline-block-right">
          <div class="marker"></div>
          <div class="timeline-content">
            <h3>V2 - Finition</h3>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Finitions esthétiques
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Finitions espace contributeur
              </li>
            </ul>
          </div>
        </div>
        <div class="timeline-block timeline-block-right">
          <div class="marker"></div>
          <div class="timeline-content">
            <h3>Fin de la partie V1</h3>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Paysans : Elevages
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Mines : bilan sur production réelle
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeTimeline',
}

</script>

<style>
.home-timeline.card {
  border: none;
}
.home-timeline .card-header {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
}











.home-timeline .timeline {
  /*width: 80%;
  padding: 50px 0;
  margin: 50px auto;*/
  position: relative;
  overflow: hidden;
}

.home-timeline .timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 8px;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #CCD1D9;
  z-index: 1
}

.home-timeline .timeline-block {
  /*width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.home-timeline .timeline-block-right {
  /*float: right;*/
}

.home-timeline .timeline-block-left {
  float: left;
  direction: rtl
}

.home-timeline .marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #4091c9;
  margin-top: 10px;
  z-index: 999;
}

.home-timeline .timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666
}

.home-timeline .timeline-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500
}

.home-timeline .timeline-content span {
  font-size: 15px;
  /* color: #a4a4a4; */
  font-style: italic;
  font-weight: lighter;
}

.home-timeline .timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}
</style>
