const mutations = {
  setFaqs: function (state, faqs) {
    state.faqs = faqs;
  },
  setCategories: function (state, categories) {
    state.categories = categories;
  },
}

export default mutations;

