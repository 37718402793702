<template>
  <form class="validation-form">
    <div class="row">
      <p class="label">Texte</p>
      <div class="overflow-auto" :class="translate.type">{{translate.fr}}</div>
    </div>
    <hr>
    <div class="row">
      <p class="label">Anglais</p>
      <div class="overflow-auto" :class="translate.type">{{translate.en}}</div>
      <div class="actions-validations">
        <select v-model="en" class="form-controle form-select-sm col-4">
          <option :value="0">Vide</option>
          <option :value="1">En attente</option>
          <option :value="2">Valider</option>
        </select>
      </div>
    </div>
    <hr>
    <div class="row">
      <p class="label">Italien</p>
      <div class="overflow-auto" :class="translate.type">{{translate.it}}</div>
      <div class="actions-validations">
        <select v-model="it" class="form-controle form-select-sm col-4">
          <option :value="0">Vide</option>
          <option :value="1">En attente</option>
          <option :value="2">Valider</option>
        </select>
      </div>
    </div>
  </form>
  <div class="translate-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
export default {
  name: 'ValidationForm',
  props: {
    translate: Object
  },
  data() {
    return {
      en: null,
      it: null,
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.en = undefined !== this.translate.enStatus ? this.translate.enStatus : '';
      this.it = undefined !== this.translate.itStatus ? this.translate.itStatus : '';
    },
    save() {
      this.$store.dispatch('adminTranslates/validTranslate', {
        uuid: this.translate.uuid,
        en: this.en,
        it: this.it,
      });
    }
  },
  watch: {
    translate() {
      this.getDatas();
    }
  },
}
</script>
<style>
.validation-form .overflow-auto.ckeditor {
  height: 200px;
  margin-bottom: 20px;
}
.validation-form .overflow-auto.input {
  margin-bottom: 20px;
}
.validation-form .label {
  text-align: center;
  color: #4091c9;
  text-transform: uppercase;
}
.validation-form .actions-validations {
  text-align: center;
}
.validation-form  button.reject {
  height: 24px;
  text-transform: uppercase;
  background-color: #ff0000;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
}
.validation-form  button.waiting {
  height: 24px;
  text-transform: uppercase;
  background-color: #ff9e00;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
}
.validation-form  button.valid {
  height: 24px;
  text-transform: uppercase;
  background-color: #008000;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
}
.validation-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.validation-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
