import apiUser from "@/System/store/apiUser";

const actions = {
  getContacts: ({commit}) => {
    apiUser.get('/close-api/contacts')
      .then(function (response) {
        commit('setContacts', response.data);
      });
  },
  closeContact: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/contact/'+uuid+'/close', payload)
      .then(function () {
        apiUser.get('/close-api/contacts')
          .then(function (response) {
            commit('setContacts', response.data);
          });
        apiUser.get('/close-api/contact/'+uuid)
          .then(function (response) {
            commit('setContact', response.data);
          });
      });
  },
}

export default actions;
