<template>
  <line-table-craftspeople v-for="(recipe, index) in datas.recipes" :key="index" :line="index" :production="datas.product" :nb-ingredients="datas.qtyIngredients" :recipe="recipe" :intelligence="intelligence"></line-table-craftspeople>
</template>

<script>
import LineTableCraftspeople from "@/Open/Craftspeople/components/element/LineTableCraftspeople";

export default {
  name: 'CraftspeopleCalc',
  components: {
    LineTableCraftspeople
  },
  props: {
    datas: Object,
    intelligence: Number,
  },
  data() {
    return {
      gainTotal: 0,
    }
  },
  mounted() {

  }
}
</script>
