import apiUser from "@/System/store/apiUser";

const actions = {
  getFaqs: ({commit}) => {
    apiUser.get('/close-api/faqs')
      .then(function (response) {
        commit('setFaqs', response.data);
      });
  },
  getCategories: ({commit}) => {
    apiUser.get('/close-api/faq/categories')
      .then(function (response) {
        commit('setCategories', response.data);
      });
  },
  addFaq: ({commit}, payload) => {
    apiUser.post('/close-api/faqs', payload)
      .then(function () {
        apiUser.get('/close-api/faqs')
          .then(function (response) {
            commit('setFaqs', response.data);
          });
      });
  },
  editFaq: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      title: datas.title,
      content: datas.content,
      category: datas.category
    };

    apiUser.put('/close-api/faq/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/faqs')
          .then(function (response) {
            commit('setFaqs', response.data);
          });
      });
  },
  deleteFaq: ({commit}, datas) => {
    apiUser.delete('/close-api/faq/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/faqs')
          .then(function (response) {
            commit('setFaqs', response.data);
          });
      });
  },
  publishFaq: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/faq/'+uuid+'/publish', payload)
      .then(function () {
        apiUser.get('/close-api/faqs')
          .then(function (response) {
            commit('setFaqs', response.data);
          });
      });
  },
  setOrder: ({commit}, payload) => {
    apiUser.put('/close-api/faqs/order', payload)
      .then(function () {
        apiUser.get('/close-api/faqs')
          .then(function (response) {
            commit('setFaqs', response.data);
          });
      });
  },
}

export default actions;
