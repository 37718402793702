<template>
  <div class="lessons">
    <ul class="list-group">
      <li v-for="(way, index) in ways" :key="index" @click="load(way)" class="list-group-item d-flex justify-content-between align-items-center modal-label" data-bs-toggle="modal" data-bs-target="#lessonModal">
        {{ way.name[$i18n.locale] }}
        <font-awesome-icon icon="fa-solid fa-hand-point-up" class="modal-icon-action"/>
      </li>
    </ul>

    <div class="modal fade" id="lessonModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="lessonModalLabel">{{ wayName[$i18n.locale]}} ({{categoryName[$i18n.locale]}})</h1>
          </div>
          <div class="modal-body">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <td>{{ $t('university.lessons') }}</td>
                  <td>{{ $t('university.prerequisite') }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lesson, index) in lessons" :key="index">
                  <td class="lesson">{{lesson.name[$i18n.locale]}}</td>
                  <td v-html="lesson.prerequisite?.[$i18n.locale]" class="lesson-prerequisite"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UniversityLessons',
  data() {
    return {
      wayName: {},
      categoryName: {},
    }
  },
  computed: {
    ...mapState('university', {
        ways: 'ways',
        lessons: 'lessonsByWay'
      }
    )
  },
  async mounted() {
    this.$store.dispatch('university/getWays');
  },
  methods: {
    load(way) {
      this.wayName = way.name;
      this.categoryName = way.category.name;
      this.$store.dispatch('university/getLessonsByWay', way.uuid);
      this.$store.dispatch('university/tracking', {
        page: 'open-university',
        component: 'lessons-way|uuid:'+way.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>

<style>
.lessons {
  margin-top: 20px;
}
.lessons .modal-label {
  color: #4091c9!important;
  text-transform: uppercase;
  cursor: pointer;
}
.lessons .modal-icon-action {
  color: #4091c9!important;
  font-size: 20px;
}
.lessons .modal-title {
  color: #4091c9;
  text-transform: uppercase;
  font-weight: 400;
}
.lessons thead td {
  text-transform: uppercase;
  text-align: center;
  color: #4091c9;
}
.lessons .lesson {
  font-size: 14px;
  width: 40%;
}
.lessons .lesson-prerequisite p {
  margin-bottom: 0;
}
.lessons .lesson-prerequisite p strong {
  font-size: 14px;
}
.lessons .lesson-prerequisite ul {
  font-size: 14px;
  list-style: disclosure-closed none;
}
</style>
