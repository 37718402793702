<template>
  <form class="category-form">
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text">Nom de la catégorie</span>
      <input type="text" v-model="name" class="form-control" id="name">
    </div>
  </form>
  <div class="category-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
export default {
  name: 'UniversityCategory',
  props: {
    category: Object,
    mode: String,
  },
  data() {
    return {
      name: null
    }
  },
  mounted() {
    this.getName();
  },
  methods: {
    getName() {
      this.name = undefined !== this.category.name ? this.category.name[this.$i18n.locale] : '';
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminUniversity/addCategory', {
          name: this.name
        });
      } else {
        this.$store.dispatch('adminUniversity/editCategory', {
          uuid: this.category.uuid,
          name: this.name
        });
      }
    }
  },
  watch: {
    category() {
      this.getName();
    }
  }
}
</script>

<style>
.category-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.category-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
