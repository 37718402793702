const mutations = {
  setCraftspeoples: function (state, craftspeoples) {
    state.craftspeoples = craftspeoples;
  },
  setProducts: function (state, products) {
    state.products = products;
  },
  setProductions: function (state, productions) {
    state.productions = productions;
  },
}

export default mutations;
