<template>
  <div class="admin-home-actuality card">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormActuality" />
      </span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(actuality, index) in actualities" :key="index" class="list-group-item">
          {{ actuality.title[$i18n.locale] }}
          <span class="actions">
              <font-awesome-icon icon="fa-solid fa-circle-up" :class="actuality.newPublish ? 'online' : 'offline'" @click="newPublish(!actuality.newPublish, actuality.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-solid fa-power-off" :class="actuality.publish ? 'online' : 'offline'" @click="publish(!actuality.publish, actuality.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', actuality)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormActuality" />&nbsp;&nbsp;&nbsp;
              <font-awesome-icon icon="fa-regular fa-trash-alt" @click="form('delete', actuality.uuid)" class="modal-icon-action" />
            </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal fade" id="modalFormActuality" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ labelModal }}</h1>
        </div>
        <div class="modal-body">
          <actuality-form v-if="'add' === mode || 'edit' === mode" :actuality="actuality" :mode="mode"></actuality-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ActualityForm from "@/Admin/Home/components/form/ActualityForm";

export default {
  name: "HomeAdminActuality",
  components: {
    ActualityForm,
  },
  computed: {
    ...mapState('adminHome', {
        actualities: 'actualities'
      }
    )
  },
  data() {
    return {
      mode: null,
      labelModal: '',
      actuality: {},
      enabled: true,
      dragging: false
    }
  },
  mounted() {
    this.$store.dispatch('adminHome/getActualities');
  },
  methods: {
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.actuality = {};
          this.labelModal = 'Ajouter une actualité';
          break;
        case 'edit':
          this.actuality = datas;
          this.labelModal = 'Modifier une actualité';
          break;
        case 'delete':
          this.$store.dispatch('adminHome/deleteActuality', {
            uuid: datas,
          });
          break;
      }
    },
    publish(state, uuid) {
      this.$store.dispatch('adminHome/publishActuality', {
        uuid: uuid,
        state: state,
      });
    },
    newPublish(state, uuid) {
      this.$store.dispatch('adminHome/newPublishActuality', {
        uuid: uuid,
        state: state,
      });
    }
  }
};
</script>

<style>
.admin-home-actuality.card {
  border-radius: 0!important;
  border: none!important;
}
.admin-home-actuality .card-header {
  background-color: transparent;
}
.admin-home-actuality .card-header .actions {
  float: right;
  color: #4091c9;
}
.admin-home-actuality .list-group {
  border-radius: 0!important;
}
.admin-home-actuality .actions {
  float: right;
  color: #4091c9;
}
.admin-home-actuality .actions .modal-icon-action {
  cursor: pointer;
}
.admin-home-actuality .actions .modal-icon-action.online{
  color: #008000;
}
.admin-home-actuality .actions .modal-icon-action.offline {
  color: #ff0000;
}
#modalFormActuality .modal-header {
  padding: 5px
}
#modalFormActuality .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
