<template>
  <div class="sidebar-akahon" :class="isOpened ? 'open' : ''" :style="cssVars">
    <div class="logo-details" style="margin: 6px 14px 0 14px;">
      <div class="logo_name">
        <a href="/">{{ $t('nav.title') }}</a>
      </div>
      <i class="bx" :class="isOpened ? 'bx-menu-alt-right' : 'bx-menu icon-menu-close'" id="btn" @click="isOpened = !isOpened"/>
    </div>
    <div style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
      <div id="my-scroll" style="margin: 6px 12px 0 12px;">
        <ul class="nav-list" style="overflow: visible;">
          <span v-for="(menuItem, index) in menuItems" :key="index">
            <li :class="($route.name === menuItem.link) ? 'nav-active' : ''">
              <a :href="menuItem.link">
                <font-awesome-icon class="icon" :icon="menuItem.icon" />
                <span class="links_name">{{ $t(menuItem.name) }}</span>
              </a>
              <span class="tooltip">{{ menuItem.tooltip || $t(menuItem.name) }}</span>
            </li>
          </span>
        </ul>
        <div class="various-infos">
          <ul class="nav-list" style="overflow: visible;">
            <span>
              <li v-if="'' === user.token" :class="('inscription-connexion' === $route.name) ? 'nav-active' : ''">
                <a href="/inscription-connexion">
                  <font-awesome-icon class="icon" icon="fa-solid fa-user-plus" />
                  <span class="links_name">Inscription - Connexion</span>
                </a>
              </li>
              <li v-if="'ROLE_ADMIN' === user.role" :class="isAdmin() ? 'nav-active' : ''">
                <a href="admin">
                  <font-awesome-icon class="icon" icon="fa-solid fa-screwdriver-wrench" />
                  <span class="links_name">Admin</span>
                </a>
              </li>
              <li v-if="'ROLE_CONTRIBUTOR' === user.role" :class="isPlayer() ? 'nav-active' : ''">
                <a href="player">
                  <font-awesome-icon class="icon" icon="fa-solid fa-screwdriver-wrench" />
                  <span class="links_name">{{$t('nav.player')}}</span>
                </a>
              </li>
              <li v-if="'' !== user.token">
                <a @click="logout">
                  <font-awesome-icon class="icon logout" icon="fa-solid fa-right-from-bracket" />
                  <span class="links_name logout">Déconnexion</span>
                </a>
              </li>
            </span>
          </ul>
        </div>
        <div class="various-infos">
          <p>
            <select v-if="isOpened" id="translateOpen" v-model="lang" @change="translate($event)">
              <option value="fr">{{ $t('nav.languages.french')}}</option>
            </select>
            <select v-if="!isOpened" id="translateClose" v-model="lang" @change="translate($event)">
              <option value="fr">{{ $t('nav.languages_small.french')}}</option>
            </select>
          </p>
          <p>
            <a href="/faq" style="color:white;text-decoration:none;">FAQ</a>
          </p>
          <p>
            <a href="/contact">
              <font-awesome-icon class="icon" icon="fa-regular fa-envelope" style="height: 30px;" />
            </a>
          </p>
          <p>
            <a href="https://gitlab.com/Anna-AP/la-boite-a-outils" target="_blank">
              <img src="@/System/assets/global/gitlab.png" alt="Code source" id="gitlab">
            </a>
          </p>
        </div>
      </div>

      <div class="profile">
        <div class="profile-details">
          <img v-if="profileImg" :src="profileImg" alt="profileImg">
          <i v-else class="bx bxs-user-rectangle"/>
          <div v-if="isOpened" class="name_job">
            <div class="name">
              Silanie
            </div>
            <div class="job">
              {{ $t('nav.profil') }}
            </div>
            <div class="job" v-html="copyright"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'NavigationPrincipal',
  props: {
    isMenuOpen: {
      type: Boolean,
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: '250px'
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: '78px'
    },
    menuItems: {
      type: Array,
      default: () => [
        {
          link: 'paysans',
          name: 'nav.peasant',
          tooltip: 'nav.peasant',
          icon: 'fa-solid fa-person-digging',
        },
        {
          link: 'artisans',
          name: 'nav.craftspeople',
          tooltip: 'nav.craftspeople',
          icon: 'fa-solid fa-person-dots-from-line',
        },
        {
          link: 'products',
          name: 'nav.product',
          tooltip: 'nav.product',
          icon: 'fa-solid fa-briefcase',
        },
        /*{
          link: 'royaumes',
          name: 'nav.kingdoms',
          tooltip: 'nav.kingdoms',
          icon: 'fa-solid fa-chess',
        },*/
        {
          link: 'university',
          name: 'nav.university',
          tooltip: 'nav.university',
          icon: 'fa-solid fa-book',
        },
        {
          link: 'mines',
          name: 'nav.mines',
          tooltip: 'nav.mines',
          icon: 'fa-solid fa-calculator',
        },
        /*{
          link: 'cabine',
          name: 'nav.cabin',
          tooltip: 'nav.cabin',
          icon: 'bx-cog',
        },*/
        /*{
          link: 'cabine3d',
          name: 'nav.cabin_3d',
          tooltip: 'nav.cabin_3d',
          icon: 'bx-cog',
        },*/
        /*{
          link: 'itineraire',
          name: 'nav.itinerary',
          tooltip: 'nav.itinerary',
          icon: 'bx-cog',
        },*/
      ],
    },
    profileImg: {
      type: String,
      default: require('./System/assets/global/dev.png'),
    },
    copyright: {
      type: String,
      default: 'laboiteaoutils &copy; 2019-' + new Date().getFullYear(),
    },
    isExitButton: {
      type: Boolean,
      default: true,
    },

    //! Styles
    bgColor: {
      type: String,
      default: '#11101d',
    },
    secondaryColor: {
      type: String,
      default: '#1d1b31',
    },
    homeSectionColor: {
      type: String,
      default: '#e4e9f7',
    },
    logoTitleColor: {
      type: String,
      default: '#fff',
    },
    iconsColor: {
      type: String,
      default: '#fff',
    },
    itemsTooltipColor: {
      type: String,
      default: '#e4e9f7',
    },
    searchInputTextColor: {
      type: String,
      default: '#fff',
    },
    menuItemsHoverColor: {
      type: String,
      default: '#fff',
    },
    menuItemsTextColor: {
      type: String,
      default: '#fff',
    },
    menuFooterTextColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      isOpened: false,
      lang: null !== localStorage.getItem('lbo-site-lang') ? localStorage.getItem('lbo-site-lang') : 'fr'
    }
  },
  mounted() {
    this.isOpened = this.isMenuOpen
  },
  computed: {
    ...mapState('users', {
        user: 'user'
      }
    ),
    cssVars() {
      return {
        // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
        '--bg-color': this.bgColor,
        '--secondary-color': this.secondaryColor,
        '--home-section-color': this.homeSectionColor,
        '--logo-title-color': this.logoTitleColor,
        '--icons-color': this.iconsColor,
        '--items-tooltip-color': this.itemsTooltipColor,
        '--serach-input-text-color': this.searchInputTextColor,
        '--menu-items-hover-color': this.menuItemsHoverColor,
        '--menu-items-text-color': this.menuItemsTextColor,
        '--menu-footer-text-color': this.menuFooterTextColor,
      }
    },
  },
  methods: {
    logout() {
      this.$store.commit('users/logout');
      this.$router.push('/');
    },
    translate(e) {

      localStorage.setItem('lbo-site-lang', e.target.value);
      window.location.reload();
    },
    isAdmin() {
      switch (this.$route.name) {
        case 'admin':
        case 'admin-university':
        case 'admin-catalogue':
        case 'admin-artisan':
        case 'admin-cabine':
        case 'admin-faq':
        case 'admin-contact':
        case 'admin-translate':
        case 'admin-users':
          return true;
      }
      return false;
    },
    isPlayer() {
      switch (this.$route.name) {
        case 'player':
        case 'player-craftpeople':
        case 'player-cabine':
        case 'player-contact':
        case 'player-products':
        case 'player-translate':
          return true;
      }
      return false;
    }
  },
  watch: {
    isOpened() {
      window.document.body.style.paddingLeft = this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  transition: all 0.1s ease;
}
.sidebar-akahon {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  /* overflow-y: auto; */
  width: 78px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar-akahon.open {
  width: 250px;
}
.sidebar-akahon .bx-menu-alt-right {
  color: white;
}
.sidebar-akahon .icon-menu-close {
  color: white;
  margin-right: 12px;
}
.sidebar-akahon .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar-akahon .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar-akahon .logo-details .logo_name {
  opacity: 0;
  transition: all 0.5s ease;
  margin: auto;
}
.sidebar-akahon .logo-details .logo_name a {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}
.sidebar-akahon.open .logo-details .icon,
.sidebar-akahon.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar-akahon .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar-akahon.open .logo-details #btn {
  text-align: right;
}
.sidebar-akahon .icon, .sidebar-akahon .i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar-akahon .nav-list {
  margin-top: 20px;
  padding-left: 0;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}
.sidebar-akahon li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar-akahon li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: var(--items-tooltip-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar-akahon li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-akahon.open li .tooltip {
  display: none;
}
.sidebar-akahon input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}
.sidebar-akahon.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar-akahon li a {
  display: flex;
  line-height: 50px;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
}
.sidebar-akahon li a:hover {
  background: var(--menu-items-hover-color);
}
.sidebar-akahon li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar-akahon.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar-akahon li a:hover .links_name,
.sidebar-akahon li a:hover .icon {
  transition: all 0.5s ease;
  color: var(--bg-color);
}
.sidebar-akahon li .icon {
  height: 20px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar-akahon div.profile {
  position: relative;
  height: 70px;
  width: 78px;
  /* left: 0;
  bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar-akahon.open div.profile {
  width: 250px;
}
.sidebar-akahon div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar-akahon div img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar-akahon div.profile .name,
.sidebar-akahon div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
}
.sidebar-akahon div.profile .job {
  font-size: 12px;
}
.sidebar-akahon .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar-akahon.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}
.sidebar-akahon.open .profile:hover #log_out {
  opacity: 1;
}
.sidebar-akahon.open .profile #log_out:hover {
  opacity: 1;
  color: red;
}
.sidebar-akahon .profile #log_out:hover {
  color: red;
}
.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar-akahon.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
.my-scroll-active {
  overflow-y: auto;
}
#my-scroll {
  overflow-y: auto;
  height: calc(100% - 60px);
}
#my-scroll::-webkit-scrollbar{
  display:none;
  /* background-color: rgba(255, 255, 255, 0.2);
  width: 10px;
  border-radius:5px  */
}
/* #my-scroll::-webkit-scrollbar-thumb{
  background-color: red;
  border-radius:5px
}
#my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
  display:none;
}
#my-scroll::-webkit-scrollbar-button:vertical:end:increment{
  display:none;
} */
@media (max-width: 420px) {
  .sidebar-akahon li .tooltip {
    display: none;
  }
}
.various-infos {
  margin-bottom: 20px;
  text-align: center;
  border-top: 1px solid grey;
  padding-top: 10px;
}
#translateOpen {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
#translateClose {
  width: 40px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
#gitlab {
  margin-right: 0!important;
  width: 30px;
  height: 28px;
}
.nav-active a .icon, .nav-active .links_name {
  color: #4091c9!important;
}
#my-scroll .modal-icon-action {
  cursor: pointer;
}
.logout {
  color: #ff0000!important;
  cursor: pointer;
}
</style>
