<template>
  <form class="craftpeople-form">
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.output')}}</span>
          <select id="domain" v-model="product" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            <option></option>
            <option v-for="(product, index) in products" :key="index" :value="product.uuid">{{product.name[$i18n.locale]}}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.craftspeople')}}</span>
          <select id="domain" v-model="craftspeople" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            <option></option>
            <option v-for="(craftspeople, index) in craftspeoples" :key="index" :value="craftspeople.uuid">{{craftspeople.name[$i18n.locale]}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.slug')}}</span>
          <input type="text" v-model="slug" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.note')}}</span>
          <input type="text" v-model="note" class="form-control" id="name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.quantity_ingredient')}}</span>
          <select id="domain" v-model="qtyIngredient" class="form-control" :disabled="disabled" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            <option v-for="n in 4" :key="n" :value="n">{{ n }}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4" id="inputGroup-sizing-sm">{{$t('player.craftspeople.nb_desynchro')}}</span>
          <select id="domain" v-model="nbDesynchro" class="form-control" :disabled="disabled" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            <option v-for="n in 20" :key="n" :value="n">{{ n }}</option>
          </select>
        </div>
      </div>
    </div>
    <hr>
    <desynchro-form-add v-if="'add' === mode" @fetchRecipe="getRecette($event)" :nb-ingredients="qtyIngredient" :nb-desynchro="nbDesynchro"></desynchro-form-add>
    <desynchro-form-edit v-if="'edit' === mode" @fetchRecipe="getRecette($event)" :list-recipes="listRecipes"></desynchro-form-edit>
  </form>
  <div class="craftpeople-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">{{$t('player.craftspeople.save')}}</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">{{$t('player.craftspeople.close')}}</button>
  </div>
</template>

<script>
import DesynchroFormAdd from "@/Player/Craftspeople/components/element/DesynchroFormAdd";
import DesynchroFormEdit from "@/Player/Craftspeople/components/element/DesynchroFormEdit";
import { mapState } from 'vuex'

export default {
  name: 'CraftspeopleForm',
  components: {
    DesynchroFormAdd,
    DesynchroFormEdit
  },
  props: {
    production: Object,
    mode: String,
  },
  data() {
    return {
      product: null,
      craftspeople: null,
      slug: null,
      note: null,
      qtyIngredient: 1,
      nbDesynchro: 1,
      recettes: [],
      listRecipes: [],
      disabled: false,
    }
  },
  computed: {
    ...mapState('playerCraftspeople', {
        craftspeoples: 'craftspeoples',
        products: 'products',
      }
    )
  },
  mounted() {
    this.setDisabled();
    this.$store.dispatch('playerCraftspeople/getCraftspeoples');
    this.$store.dispatch('playerCraftspeople/getProducts');
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.product = undefined !== this.production.product ? this.production.product.uuid : null;
      this.craftspeople = undefined !== this.production.craftspeople ? this.production.craftspeople.uuid : null;
      this.slug = undefined !== this.production.slug ? this.production.slug : null;
      this.note = null !== this.production.note && undefined !== this.production.note ? this.production.note[this.$i18n.locale] : null;
      this.qtyIngredient = undefined !== this.production.qtyIngredients ? this.production.qtyIngredients : null;
      this.nbDesynchro = undefined !== this.production.qtyDesynchros ? this.production.qtyDesynchros : null;
      this.listRecipes = undefined !== this.production.recipes ? this.getListRecipes(this.production.recipes) : [];
    },
    getListRecipes(list) {
      let recipes = [];
      for(let i = 0; i < list.length; i++) {
        let ing = [];
        for(let j = 0; j < list[i].ingredients.length; j++) {

          ing.push({
            product: list[i].ingredients[j].product.uuid,
            quantity: list[i].ingredients[j].quantity
          });
        }

        recipes.push({
          duration: list[i].duration,
          durationPa: list[i].durationPa,
          quantityProduction: list[i].quantityProduction,
          ingredients: ing
        });
      }

      return recipes;
    },
    getRecette(event) {
      this.recettes = event;
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('playerCraftspeople/addProduction', {
          craftspeople: this.craftspeople,
          nbDesynchro: this.nbDesynchro,
          product: this.product,
          qtyIngredient: this.qtyIngredient,
          recipes: this.recettes,
          slug: this.slug,
          note: this.note,
        });
      } else {
        this.$store.dispatch('playerCraftspeople/editProduction', {
          uuid: this.production.uuid,
          craftspeople: this.craftspeople,
          product: this.product,
          recipes: this.recettes,
          slug: this.slug,
          note: this.note,
        });
      }
    },
    setDisabled() {
      this.disabled = 'edit' === this.mode;
    }
  },
  watch: {
    qtyIngredient() {
      this.qtyIngredient = parseInt(this.qtyIngredient);
    },
    nbDesynchro() {
      this.nbDesynchro = parseInt(this.nbDesynchro);
    },
    production() {
      this.getDatas();
    },
    mode() {
      this.setDisabled();
    }
  },
}
</script>
<style>
.craftpeople-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.craftpeople-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
