<template>
  <form class="book-form">
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Nom du livre</span>
      <input type="text" v-model="name" class="form-control" id="name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Auteur</span>
      <select id="author" v-model="author" class="form-control">
        <option></option>
        <option v-for="(author, index) in authors" :key="index" :value="author.uuid">{{ author.name[$i18n.locale] }}</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Langue</span>
      <select id="author" v-model="language" class="form-control">
        <option></option>
        <option v-for="(language, index) in languages" :key="index" :value="language.uuid">{{ language.name[$i18n.locale] }}</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Provinces</span>
      <Multiselect v-model="province" mode="tags" :options="listProvinces" :search="true" class="form-select"/>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Prérequis</span>
      <ckeditor :editor="editor" v-model="prerequisite" :config="editorConfig" class="form-control col-10" id="prerequisite"></ckeditor>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Utilité</span>
      <ckeditor :editor="editor" v-model="utility" :config="editorConfig" class="form-control col-10" id="utility"></ckeditor>
    </div>
  </form>
  <div class="book-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from '@vueform/multiselect'
import {mapState} from "vuex";

export default {
  name: 'UniversityBook',
  components: {
    Multiselect
  },
  computed: {
    ...mapState('adminUniversity', {
        provinces: 'provinces'
      }
    )
  },
  props: {
    authors: Object,
    book: Object,
    languages: Object,
    mode: String,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'bulletedList']
      },
      listProvinces: [],
      name: null,
      author: null,
      language: null,
      prerequisite: '',
      utility: '',
      province: [],
    }
  },
  mounted() {
    this.getDatas();
    this.$store.dispatch('adminUniversity/getProvinces');
  },
  methods: {
    getDatas() {
      this.name = undefined !== this.book.name ? this.book.name[this.$i18n.locale] : null;
      this.author = undefined !== this.book.author ? this.book.author.uuid : null;
      this.language = undefined !== this.book.language ? this.book.language.uuid : null;
      this.prerequisite = undefined !== this.book.prerequisite && null !== this.book.prerequisite ? this.book.prerequisite[this.$i18n.locale] : '';
      this.utility = undefined !== this.book.utility && null !== this.book.utility ? this.book.utility[this.$i18n.locale] : '';

      let provinces = [];
      if(undefined !== this.book.provinces) {
        for(let i =0; i < this.book.provinces.length; i++) {
          provinces.push(this.book.provinces[i].uuid);
        }
      }
      this.province = provinces;
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminUniversity/addBook', {
          name: this.name,
          author: this.author,
          language: this.language,
          prerequisite: ''=== this.prerequisite ? null : this.prerequisite,
          utility: ''=== this.utility ? null : this.utility,
          provinces: this.province,
        });
      } else {
        this.$store.dispatch('adminUniversity/editBook', {
          uuid: this.book.uuid,
          name: this.name,
          author: this.author,
          language: this.language,
          prerequisite: ''=== this.prerequisite ? null : this.prerequisite,
          utility: ''=== this.utility ? null : this.utility,
          provinces: this.province,
        });
      }
    }
  },
  watch: {
    book() {
      this.getDatas();
    },
    provinces() {
      let list = {};

      for(let i = 0; i < this.provinces.length; i++) {
        list[this.provinces[i].uuid] = this.provinces[i].name +' ('+this.provinces[i].kingdom.name+')';
      }
      this.listProvinces = list;
    }

  },
}
</script>

<style>
.book-form .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.book-form .ck-editor__editable {
  min-height: 300px;
}
.book-form .multiselect-tag {
  background-color: #4091c9;
}
.book-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.book-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
