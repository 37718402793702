<template>
  <div class="row craftspeople-production">
    <div class="card">
      <div class="card-header page-title">
        {{craftspeople.name[$i18n.locale]}}
        <select class="choice-product" v-model="select" @change="changeProduct($event)">
          <option v-for="(production, index) in products" :key="index" :value="production.uuid">{{production.product.name[$i18n.locale]}} <span v-if="null !== production.note">({{production.note[$i18n.locale]}})</span></option>
        </select>
      </div>
      <div class="card-body">
        <div class=" col-6 offset-3">
          <table class="table table-bordered table-striped craftspeople-skills">
            <thead>
            <tr>
              <td>{{ $t('craftspeople.intellect') }}</td>
              <td colspan="2">{{ $t('craftspeople.skills.title') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="calc-input">
                <select class="skills-intellect" @change="changeTempsIntelligence($event)">
                  <option value="0">0</option>
                  <option v-for="n in 255" :key="n">{{n}}</option>
                </select>
              </td>
              <td class="calc-input"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table class="table table-bordered table-striped craftspeople-output">
            <thead>
            <tr>
              <td class="output-1">{{ $t('craftspeople.output') }}</td>
              <td>{{ $t('craftspeople.ingredients') }}</td>
              <td class="output-2">{{ $t('craftspeople.price_buy') }}</td>
              <td class="output-3">{{ $t('craftspeople.time_h') }}</td>
              <td class="output-3">{{ $t('craftspeople.time_pa') }}</td>
              <td class="output-4">{{ $t('craftspeople.price_sell') }}</td>
              <td class="output-5">{{ $t('craftspeople.total_gain') }}</td>
              <td class="output-5">{{ $t('craftspeople.unit_gain') }}</td>
              <td class="output-6">{{ $t('craftspeople.desired_gain') }}</td>
              <td class="output-5">{{ $t('craftspeople.unit_sale') }}</td>
            </tr>
            </thead>
            <tbody>
              <craftspeople-calc :datas="product" :intelligence="intelligence"></craftspeople-calc>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CraftspeopleCalc from "@/Open/Craftspeople/components/element/CraftspeopleCalc";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'CraftspeopleProduction',
  components: {
    CraftspeopleCalc
  },
  computed: {
    ...mapState('craftspeople', {
      productions: 'productions',
      production: 'production'
    })
  },
  props: {
    craftspeople: Object
  },
  data() {
    return {
      select: '',
      products: [],
      product: {},
      intelligence: 0,
    }
  },
  mounted() {
    this.$store.dispatch('craftspeople/getProductions');
  },
  methods: {
    changeTempsIntelligence(e) {
      this.intelligence = parseInt(e.target.value);
    },
    getProductions() {
      return this.$store.state.craftspeople.productions.filter(p => p.craftspeople.uuid === this.craftspeople.uuid);
    },
    changeProduct(e) {
      this.select = e.target.value;
      this.$store.dispatch('craftspeople/getProduction', e.target.value);
      // this.$store.dispatch('craftspeople/tracking', {
      //   page: 'open-craftspeople',
      //   component: 'open-craftspeople-production-'+this.select,
      //   visitor: cookies.get('lbo-site-visitor')
      // });
    }
  },
  watch: {
    productions() {
      this.products = this.getProductions();
      this.$store.dispatch('craftspeople/getProduction', this.products[0].uuid);
      this.select = this.products[0].uuid;
      this.product = this.products[0];
    },
    craftspeople() {
      this.products = this.getProductions();
      this.$store.dispatch('craftspeople/getProduction', this.products[0].uuid);
      this.select = this.products[0].uuid;
      this.product = this.products[0];
    },
    production() {
      this.product = this.production;
      this.$store.dispatch('craftspeople/tracking', {
        page: 'open-craftspeople',
        component: 'production|uuid:'+this.production.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>
<style>
.craftspeople-production .card {
  border: none;
}
.craftspeople-production .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.craftspeople-production .choice-product {
  width: 280px;
  height: 30px;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  right: 0;
}
.craftspeople-production .craftspeople-skills thead,
.craftspeople-production .craftspeople-output thead {
  font-size: 10px;
  color: #4091c9;
  font-weight: bolder;
  text-align: center;
}
.craftspeople-production .craftspeople-skills tbody,
.craftspeople-production .craftspeople-output tbody {
  padding: 2px;
  font-size: 12px;
}
.craftspeople-production .craftspeople-skills .calc-input {
  text-align: center;
}
.craftspeople-production .craftspeople-skills .skills-intellect {
  width: 50px;
  height: 24px;
  border-radius: 5px;
  text-align: center;
}
.craftspeople-production .craftspeople-output {
  width: 100%;
}
.craftspeople-production .craftspeople-output .output-1 {
  padding:5px 0 10px;
  min-width:70px;
}
.craftspeople-production .craftspeople-output .output-2 {
  padding:5px 0 10px;
  width:152px;
}
.craftspeople-production .craftspeople-output .output-3 {
  padding:5px 0 10px;
  min-width:51px;
}
.craftspeople-production .craftspeople-output .output-4 {
  padding:5px 0 10px;
  width:117px;
}
.craftspeople-production .craftspeople-output .output-5 {
  padding:5px 0 10px;
  min-width:65px;
}
.craftspeople-production .craftspeople-output .output-6 {
  padding:5px 0 10px;
  min-width:62px;
}
</style>
