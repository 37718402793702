<template>
  <navigation-principale></navigation-principale>
  <router-view/>
</template>

<script>

/* ********** OPEN **********
*
* NAV PRINCIPALE
*     - lien home lorsque menu clos
*     - maintenir le statut clos/open durant la navigation
* HOME
*     - Todolist
* PAYSANS
*     - elevages
* ARTISANS
*     - Compétences
* PRODUITS
*     - afficher image
* DOUANES
* ROYAUMES
*     - Royaumes
*     - Provinces
*     - Villes
*     - Ports
*     - Religion
* CABINE
*     - Femme
*     - Homme
* CABINE 3D
* ITINERAIRE
* SIGNALEMENT
* */

/* ********** ADMIN **********
*
* HOME
*     - Todolist
* CABINES
* FAQ
*     - Ckeditor : bug link
* UTILISATEURS
* */

/* ********** CONTRIBUTEUR **********
*
* ARTISAN
* CABINES
* CONTACT
* PRODUITS
* TRADUCTIONS
* */

import NavigationPrincipale from "@/NavigationPrincipale";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  components: {
    NavigationPrincipale
  },
  mounted() {
    if(null === localStorage.getItem('lbo-site-lang')) {
      localStorage.setItem('lbo-site-lang', 'fr');
    }

    if(null === cookies.get('lbo-site-visitor')) {
      cookies.set('lbo-site-visitor', new Date().getTime(), 0);
    }
  }
}
</script>

<style>
body {
  font-family: 'Poppins', sans-serif!important;
  padding-left: 78px;
}
</style>
