import { createStore } from 'vuex'
import adminCraftspeople from "@/Admin/Craftspeople/store";
import adminContacts from "@/Admin/Contact/store";
import adminFaqs from "@/Admin/Faq/store";
import adminHome from "@/Admin/Home/store"
import adminProducts from "@/Admin/Products/store";
import adminTranslates from "@/Admin/Translate/store";
import adminUniversity from "@/Admin/University/store";
import adminUsers from "@/Admin/Users/store"
import playerContacts from "@/Player/Contact/store";
import playerCraftspeople from "@/Player/Craftspeople/store";
import playerProducts from "@/Player/Products/store";
import playerTranslates from "@/Player/Translate/store";
import contacts from "@/Open/Contact/store";
import craftspeople from "@/Open/Craftspeople/store";
import faqs from "@/Open/Faq/store"
import home from "@/Open/Home/store"
import mines from "@/Open/Mines/store";
import peasants from "@/Open/Peasant/store";
import products from "@/Open/Products/store";
import university from "@/Open/University/store";
import users from "@/Open/User/store";

export default createStore({
  modules: {
    adminCraftspeople: adminCraftspeople,
    adminContacts: adminContacts,
    adminFaqs: adminFaqs,
    adminHome: adminHome,
    adminProducts: adminProducts,
    adminTranslates: adminTranslates,
    adminUniversity: adminUniversity,
    adminUsers: adminUsers,
    playerContacts: playerContacts,
    playerCraftspeople: playerCraftspeople,
    playerProducts: playerProducts,
    playerTranslates: playerTranslates,
    contacts: contacts,
    craftspeople: craftspeople,
    faqs: faqs,
    home: home,
    mines: mines,
    peasants: peasants,
    products: products,
    university: university,
    users: users,
  }
})
