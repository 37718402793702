import api from "@/System/store/api";

const actions = {
  send: ({commit}, payload) => {
    api.post('/api/contacts', payload)
      .then(function (response) {
        if(201 === response.status) {
          commit('setSuccess');
        } else {
          commit('setError');
        }
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
