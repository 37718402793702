<template>
  <div class="content container-fluid admin-faqs">
    <navigation-admin></navigation-admin>

    <faq-list></faq-list>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import FaqList from "@/Admin/Faq/components/FaqList";

export default {
  name: 'FaqView',
  components: {
    NavigationAdmin,
    FaqList
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-faqs {
  width: 95%!important;
}
</style>
