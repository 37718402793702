const state = {
  output: [
    {
      mine: 'iron',
      optimum: 10,
      output: {
        '3': {'output': 0.05, 'iron': 22, 'stone': 30},
        '4': {'output': 1.03, 'iron': 22, 'stone': 30},
        '5': {'output': 1.10, 'iron': 22, 'stone': 30},
        '6': {'output': 1.19, 'iron': 23, 'stone': 31},
        '7': {'output': 1.26, 'iron': 25, 'stone': 33},
        '8': {'output': 1.36, 'iron': 27, 'stone': 36},
        '9': {'output': 1.44, 'iron': 30, 'stone': 40},
        '10': {'output': 1.52, 'iron': 33, 'stone': 45},
        '11': {'output': 1.60, 'iron': 39, 'stone': 52},
        '12': {'output': 1.67, 'iron': 45, 'stone': 60},
        '13': {'output': 1.74, 'iron': 52, 'stone': 69},
        '14': {'output': 1.80, 'iron': 60, 'stone': 79},
        '15': {'output': 1.89, 'iron': 68, 'stone': 90},
        '16': {'output': 1.97, 'iron': 77, 'stone': 102},
        '17': {'output': 2.05, 'iron': 86, 'stone': 115},
      }
    },
    {
      mine: 'stone',
      optimum: 11,
      output: {
        '3': {'output': 0.05, 'iron': 11, 'stone': 17},
        '4': {'output': 1.37, 'iron': 13, 'stone': 20},
        '5': {'output': 1.47, 'iron': 15, 'stone': 23},
        '6': {'output': 1.58, 'iron': 17, 'stone': 26},
        '7': {'output': 1.68, 'iron': 20, 'stone': 29},
        '8': {'output': 1.79, 'iron': 27, 'stone': 40},
        '9': {'output': 1.89, 'iron': 27, 'stone': 40},
        '10': {'output': 2, 'iron': 27, 'stone': 40},
        '11': {'output': 2.1, 'iron': 27, 'stone': 40},
        '12': {'output': 2.21, 'iron': 39, 'stone': 40},
        '13': {'output': 2.31, 'iron': 50, 'stone': 70},
        '14': {'output': 2.41, 'iron': 50, 'stone': 70},
        '15': {'output': 2.52, 'iron': 50, 'stone': 70},
        '16': {'output': 2.63, 'iron': 50, 'stone': 70},
        '17': {'output': 2.73, 'iron': 50, 'stone': 70},
      }
    },
    {
      mine: 'gold',
      optimum: 10,
      output: {
        '3': {'output': 1, 'iron': 69, 'stone': 93},
        '4': {'output': 47.25, 'iron': 70, 'stone': 94},
        '5': {'output': 47.77, 'iron': 71, 'stone': 95},
        '6': {'output': 48.30, 'iron': 71, 'stone': 97},
        '7': {'output': 48.83, 'iron': 72, 'stone': 97},
        '8': {'output': 49.35, 'iron': 73, 'stone': 98},
        '9': {'output': 49.88, 'iron': 74, 'stone': 99},
        '10': {'output': 50.40, 'iron': 75, 'stone': 100},
        '11': {'output': 50.93, 'iron': 77, 'stone': 102},
        '12': {'output': 51.45, 'iron': 79, 'stone': 104},
        '13': {'output': 51.98, 'iron': 81, 'stone': 106},
        '14': {'output': 52.50, 'iron': 82, 'stone': 109},
        '15': {'output': 53.03, 'iron': 84, 'stone': 112},
        '16': {'output': 53.55, 'iron': 86, 'stone': 115},
        '17': {'output': 54.08, 'iron': 88, 'stone': 118},
      }
    },
    {
      mine: 'clay',
      optimum: 11,
      output: {
        '3': {'output': 0.25, 'iron': 11, 'stone': 17},
        '4': {'output': 6.85, 'iron': 13, 'stone': 20},
        '5': {'output': 7.35, 'iron': 15, 'stone': 23},
        '6': {'output': 7.9, 'iron': 17, 'stone': 26},
        '7': {'output': 8.4, 'iron': 20, 'stone': 29},
        '8': {'output': 8.95, 'iron': 27, 'stone': 40},
        '9': {'output': 9.45, 'iron': 27, 'stone': 40},
        '10': {'output': 10, 'iron': 27, 'stone': 40},
        '11': {'output': 10.5, 'iron': 27, 'stone': 40},
        '12': {'output': 11.05, 'iron': 39, 'stone': 40},
        '13': {'output': 11.55, 'iron': 50, 'stone': 70},
        '14': {'output': 12.05, 'iron': 50, 'stone': 70},
        '15': {'output': 12.6, 'iron': 50, 'stone': 70},
        '16': {'output': 13.15, 'iron': 50, 'stone': 70},
        '17': {'output': 13.65, 'iron': 50, 'stone': 70},
      }
    },
    {
      mine: 'salt',
      optimum: 10,
      output: {
        '3': {'output': 0.05, 'iron': 22, 'stone': 30},
        '4': {'output': 1.03, 'iron': 22, 'stone': 30},
        '5': {'output': 1.10, 'iron': 22, 'stone': 30},
        '6': {'output': 1.19, 'iron': 23, 'stone': 31},
        '7': {'output': 1.26, 'iron': 25, 'stone': 33},
        '8': {'output': 1.36, 'iron': 27, 'stone': 36},
        '9': {'output': 1.44, 'iron': 30, 'stone': 40},
        '10': {'output': 1.52, 'iron': 33, 'stone': 45},
        '11': {'output': 1.60, 'iron': 39, 'stone': 52},
        '12': {'output': 1.67, 'iron': 45, 'stone': 60},
        '13': {'output': 1.74, 'iron': 52, 'stone': 69},
        '14': {'output': 1.80, 'iron': 60, 'stone': 79},
        '15': {'output': 1.89, 'iron': 68, 'stone': 90},
        '16': {'output': 1.97, 'iron': 77, 'stone': 102},
        '17': {'output': 2.05, 'iron': 86, 'stone': 115},
      }
    }
  ],
}

export default state;
