<template>
  <div class="card craftspeople-productions">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormProduction" />
      </span>
    </div>
    <div class="card-body">
      <div class="accordion accordion-flush" id="craftspeopleAccordion">
        <div v-for="(craftspeople, index) in craftspeoples" :key="index" class="accordion-item">
          <h2 class="accordion-header accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse-'+index" aria-expanded="false" :aria-controls="'#flush-collapse-'+index">
            {{ craftspeople.name[$i18n.locale] }}
          </h2>
          <div :id="'flush-collapse-'+index" class="accordion-collapse collapse" data-bs-parent="#craftspeopleAccordion">
            <div class="accordion-body">
              <ul class="list-group">
                <li v-for="(production, index) in listCraftspeople(craftspeople)" :key="index" class="list-group-item">
                  {{ production.product.name[$i18n.locale] }} <span v-if="null !== production.note">({{production.note[$i18n.locale]}})</span>
                  <span class="actions">
                    <font-awesome-icon icon="fa-solid fa-power-off" :class="production.publish ? 'online' : 'offline'" @click="publish(!production.publish, production.uuid)" class="modal-icon-action" />&nbsp;&nbsp;&nbsp;
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', production)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormProduction" />&nbsp;&nbsp;&nbsp;
                    <font-awesome-icon icon="fa-regular fa-trash-alt" @click="form('delete', production.uuid)" class="modal-icon-action" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalFormProduction" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ labelModal }}</h1>
          </div>
          <div class="modal-body">
            <craftspeople-form v-if="'add' === mode || 'edit' === mode" :production="production" :mode="mode"></craftspeople-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CraftspeopleForm from "@/Admin/Craftspeople/components/form/CraftspeopleForm";

export default {
  name: "CraftspeopleProductions",
  components: {
    CraftspeopleForm
  },
  computed: {
    ...mapState('adminCraftspeople', {
        craftspeoples: 'craftspeoples',
      }
    ),
  },
  data() {
    return {
      mode: null,
      labelModal: '',
      production: {},
    }
  },
  mounted() {
    this.$store.dispatch('adminCraftspeople/getCraftspeoples');
    this.$store.dispatch('adminCraftspeople/getProductions');
  },
  methods: {
    listCraftspeople(craftspeople) {
      return this.$store.state.adminCraftspeople.productions.filter(p => p.craftspeople.uuid === craftspeople.uuid);
    },
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.production = {};
          this.labelModal = 'Ajouter une production';
          break;
        case 'edit':
          this.production = datas;
          this.labelModal = 'Modifier une production';
          break;
        case 'delete':
          this.$store.dispatch('adminCraftspeople/deleteProduction', {
            uuid: datas,
          });
          break;
      }
    },
    publish(state, uuid) {
      this.$store.dispatch('adminCraftspeople/publishProduction', {
        uuid: uuid,
        state: state,
      });
    },
  }
};
</script>

<style>
.craftspeople-productions {
  margin-top: 20px;
  border-radius: 0!important;
  border: none!important;
}
.craftspeople-productions .card-header {
  background-color: transparent;
}
.craftspeople-productions .card-header .actions {
  float: right;
  color: #4091c9;
}
.craftspeople-productions .card-header .actions .modal-icon-action {
  cursor: pointer;
}
.craftspeople-productions .accordion h2.accordion-header {
  color: #4091c9;
  text-transform: uppercase;
}
.craftspeople-productions .accordion h2.accordion-header.accordion-button,
.craftspeople-productions .accordion h2.accordion-header.accordion-button.collapsed {
  background-color: transparent;
}
.craftspeople-productions .accordion .list-group {
  border-radius: 0!important;
}
.craftspeople-productions .accordion .list-group .actions {
  float: right;
  color: #4091c9;
}
.craftspeople-productions .actions .modal-icon-action.online{
  color: #008000;
}
.craftspeople-productions .actions .modal-icon-action.offline {
  color: #ff0000;
}
.craftspeople-productions .accordion .list-group .actions .modal-icon-action {
  cursor: pointer;
}
.craftspeople-productions .modal-header {
  padding: 5px
}
.craftspeople-productions .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>

