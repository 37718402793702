import apiUser from "@/System/store/apiUser";

const actions = {
  getTranslates: ({commit}) => {
    apiUser.get('/close-api/translates')
      .then(function (response) {
        commit('setTranslates', response.data);
      });
  },
  editTranslate: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      en :datas.en,
      it: datas.it,
      de: datas.de,
      es: datas.es,
      bu: datas.bu,
      nl: datas.nl,
      pl: datas.pl,
      pt: datas.pt,
      da: datas.da,
      el: datas.el,
      ro: datas.ro,
      ru: datas.ru,
      tr: datas.tr,
      ct: datas.ct,
      sl: datas.sl,
      hr: datas.hr,
      hu: datas.hu,
      cz: datas.cz,
      se: datas.se,
      sr: datas.sr,
      fi: datas.fi,
      al: datas.al,
      ba: datas.ba,
      eu: datas.eu
    };

    apiUser.put('/close-api/translate/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/translates')
          .then(function (response) {
            commit('setTranslates', response.data);
          });
      });
  },
  validTranslate: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      en :datas.en,
      it: datas.it
    };

    apiUser.put('/close-api/translate/'+uuid+'/validation', payload)
      .then(function () {
        apiUser.get('/close-api/translates')
          .then(function (response) {
            commit('setTranslates', response.data);
          });
      });
  },
}

export default actions;
