<template>
  <div class="content container-fluid royaumes">
    <div class="card page-content">
      <div class="card-header page-title">
        Royaumes
      </div>
      <div class="card-body page-body">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RoyaumeView'
}
</script>
