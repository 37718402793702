<template>
  <div class="content container-fluid admin-user">
    <navigation-admin></navigation-admin>

    <users-list></users-list>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import UsersList from "@/Admin/Users/components/UsersList";

export default {
  name: 'UsersView',
  components: {
    NavigationAdmin,
    UsersList
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
  }
}
</script>

<style>
.admin-user {
  width: 95%!important;
}
</style>
