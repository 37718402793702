<template>
  <div class="faq">
    <ul class="list-group">
      <li v-for="(faq, index) in list" :key="index" @click="load(faq)" class="list-group-item d-flex justify-content-between align-items-center modal-label" data-bs-toggle="modal" data-bs-target="#faqExtensionModal">
        {{ faq.title[$i18n.locale] }}
        <font-awesome-icon icon="fa-solid fa-hand-point-up" class="modal-icon-action"/>
      </li>
    </ul>

    <div class="modal fade" id="faqExtensionModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="lessonModalLabel">{{faq.title?.[$i18n.locale]}}</h1>
          </div>
          <div class="modal-body" v-html="faq.content?.[$i18n.locale]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'FaqExtension',
  computed: {
    ...mapState('faqs', {
          faqs: 'faqs'
        }
    )
  },
  data() {
    return {
      list: [],
      faq: {}
    }
  },
  mounted() {
    this.$store.dispatch('faqs/getFaqs');
  },
  methods: {
    getExtension() {
      return this.faqs.filter(f => f.category.uuid === '33b57b01-8f5c-4e7e-ad3c-b7751fd4852e');
    },
    load(faq) {
      this.faq = faq;
      this.$store.dispatch('faqs/tracking', {
        page: 'open-faqs',
        component: 'extension|uuid:'+faq.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  },
  watch: {
    faqs() {
      this.list = this.getExtension();
    }
  }
}
</script>

<style>
.faq {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.faq .modal-label {
  color: #4091c9!important;
  text-transform: uppercase;
  cursor: pointer;
}
.faq .modal-icon-action {
  color: #4091c9!important;
  font-size: 20px;
}
.faq .modal-header {
  padding: 5px
}
.faq .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
.faq .modal-body a {
  text-decoration: underline;
  color: #4091c9;
}
</style>
