import apiUser from "@/System/store/apiUser";

const actions = {
  getUsers: ({commit}) => {
    apiUser.get('/close-api/users')
      .then(function (response) {
        commit('setUsers', response.data);
      });
  },
  editUser: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      note: datas.note,
      roles: datas.role,
      languages: datas.languages,
      modules: datas.modules,
    };

    apiUser.put('/close-api/user/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/users')
          .then(function (response) {
            commit('setUsers', response.data);
          });
      });
  },
}

export default actions;
