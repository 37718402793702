const state = {
  authors: [],
  books: [],
  categories: [],
  languages: [],
  lessons: [],
  provinces: [],
  ways: [],
}

export default state;
