import api from "@/System/store/api";

const actions = {
  getFaqs: ({commit}) => {
    api.get('/api/faqs/publish')
      .then(function (response) {
        commit('setFaqs', response.data);
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
