<template>
  <div class="tableur peasant-wheat">
    <div class="card">
      <div class="card-header">
        {{ $t('peasant.nav.wheat') }}
      </div>
      <div class="card-body">
        <div class=" col-6 offset-3">
          <table class="table table-bordered table-striped peasant-skills">
            <thead>
            <tr>
              <td colspan="3" class="calc-input">
                {{ $t('peasant.table.skills') }}
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="calc-input">
                <select-array :datas="skills.farmer" :option="''" class="select-filter" @change="changeFarmer($event)"></select-array>
              </td>
              <td class="calc-input">
                <select-array :datas="skills.pray" :option="''" class="select-filter" @change="changePray($event)"></select-array>
              </td>
              <td class="calc-input">
                <select-array :datas="skills.sense" :option="''" class="select-filter" @change="changeSense($event)"></select-array>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <table class="table table-bordered table-striped peasant-output">
          <thead>
            <tr>
              <td class="first-column"></td>
              <td>{{$t('peasant.table.field_1')}}</td>
              <td>{{$t('peasant.table.field_2')}}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{$t('peasant.table.time')}}</td>
              <td colspan="2">10 {{$t('peasant.table.days')}}</td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.raw_material')}}</td>
              <td colspan="2">1 {{$t('peasant.table.wheat_bag')}}</td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.origin')}}</td>
              <td>
                <select-array :datas="crop" :option="''" @change="activePricePurchase($event, 1)" class="select-buy"></select-array>
              </td>
              <td>
                <select-array :datas="crop" :option="''" @change="activePricePurchase($event, 2)" class="select-buy"></select-array>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.price_buy')}}</td>
              <td>
                <select-min-max :min="priceWheatMin" :max="priceWheatMax" class="price-buy-1 select-price" disabled="true" @change="setPricePurchaseByUnit($event, 1)"></select-min-max>
                <select-price-cent class="price-buy-1 select-price" disabled="true" @change="setPriceAchatByCent($event, 1)"></select-price-cent>
              </td>
              <td>
                <select-min-max :min="priceWheatMin" :max="priceWheatMax" class="price-buy-2 select-price" disabled="true" @change="setPricePurchaseByUnit($event, 2)"></select-min-max>
                <select-price-cent class="price-buy-2 select-price" disabled="true" @change="setPriceAchatByCent($event, 2)"></select-price-cent>
              </td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.wheat_1') }}</td>
            </tr>
            <tr>
              <td>{{ $t('peasant.table.employee') }}</td>
              <td>
                <select class="select-price" @change="setEmployee($event, 1,1)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price" @change="setEmployee($event, 1,2)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.salary')}}</td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 1,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 1,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.strength')}}</td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 1,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 1,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.soc')}}</td>
              <td>
                <select class="select-price" @change="setSoc($event,1)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price" @change="setSoc($event,2)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.virtue')}}</td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 1, 1)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 1, 2)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.wheat_2') }}</td>
            </tr>
            <tr>
              <td>{{ $t('peasant.table.employee') }}</td>
              <td>
                <select class="select-price" @change="setEmployee($event, 2,1)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price" @change="setEmployee($event, 2,2)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.salary')}}</td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 2,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 2,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.intellect')}}</td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 2,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 2,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.virtue')}}</td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 2, 1)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 2, 2)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.wheat_3') }}</td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.wheat_4') }}</td>
            </tr>
            <tr>
              <td>{{ $t('peasant.table.employee') }}</td>
              <td>
                <select class="select-price" @change="setEmployee($event, 3,1)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price" @change="setEmployee($event, 3,2)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.salary')}}</td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 3,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="salaryMin" :max="salaryMax" @change="setSalary($event, 3,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.strength')}}</td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 3,1)" class="select-price"></select-min-max>
              </td>
              <td>
                <select-min-max :min="0" :max="19" @change="setCaract($event, 3,2)" class="select-price"></select-min-max>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.ankou')}}</td>
              <td>
                <select class="select-price" @change="setAnkou($event, 1)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price" @change="setAnkou($event, 2)">
                  <option value="no">{{ $t('peasant.table.no') }}</option>
                  <option value="yes">{{ $t('peasant.table.yes') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.virtue')}}</td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 3, 1)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
              <td>
                <select class="select-price select-virtue" @change="setVirtue($event, 3, 2)">
                  <option></option>
                  <option value="minor">{{ $t('peasant.table.minor') }}</option>
                  <option value="major">{{ $t('peasant.table.mayor') }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.sell') }}</td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.price_sell')}}</td>
              <td>
                <select-min-max :min="priceWheatMin" :max="priceWheatMax" @change="setPriceSellByUnit($event, 1)" class="select-price"></select-min-max>
                <select-price-cent @change="setPriceSellByCent($event, 1)" class="select-price"></select-price-cent>
              </td>
              <td>
                <select-min-max :min="priceWheatMin" :max="priceWheatMax" @change="setPriceSellByUnit($event, 2)" class="select-price"></select-min-max>
                <select-price-cent @change="setPriceSellByCent($event, 2)" class="select-price"></select-price-cent>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button class="button-tableur" @click="calculate(1)">{{ $t('peasant.table.calculate') }}</button>
              </td>
              <td>
                <button class="button-tableur" @click="calculate(2)">{{ $t('peasant.table.calculate') }}</button>
              </td>
            </tr>
            <tr>
              <td class="step"></td>
              <td colspan="2" class="step">{{ $t('peasant.table.steps.result') }}</td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.investment')}}</td>
              <td>
                <p v-if="result1.calculate && 0 !== result1.buy" v-html="$t('peasant.result.buy', {product: $t('peasant.result.wheat_output'), value: result1.buy})"></p>
                <p v-if="result1.calculate && 0 === result1.nbEmployee" v-html="$t('peasant.result.no_employee')"></p>
                <p v-if="result1.calculate && 0 !== result1.nbEmployee" v-html="$t('peasant.result.nb_employee', {nb: result1.nbEmployee, value: result1.totalEmployee})"></p>
                <p v-if="result1.calculate && 0 !== result1.investment &&  0 !== result1.buy && 0 !== result1.nbEmployee" v-html="$t('peasant.result.investment', {value: result1.investment})"></p>
              </td>
              <td>
                <p v-if="result2.calculate && 0 !== result2.buy" v-html="$t('peasant.result.buy', {product: $t('peasant.result.wheat_output'), value: result2.buy})"></p>
                <p v-if="result2.calculate && 0 === result2.nbEmployee" v-html="$t('peasant.result.no_employee')"></p>
                <p v-if="result2.calculate && 0 !== result2.nbEmployee" v-html="$t('peasant.result.nb_employee', {nb: result2.nbEmployee, value: result2.totalEmployee})"></p>
                <p v-if="result2.calculate && 0 !== result2.investment &&  0 !== result2.buy && 0 !== result2.nbEmployee" v-html="$t('peasant.result.investment', {value: result2.investment})"></p>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.output')}}</td>
              <td>
                <p v-if="result1.calculate && 0 !== result1.output" v-html="$t('peasant.result.output', {nb: result1.output, product: $t('peasant.result.wheat_output'), output: result1.percent})"></p>
                <p v-if="result1.calculate && 0 < result1.bonus.length" class="bonus-infos" v-html="$t('peasant.result.bonus', {list: result1.bonus.join(', ')})"></p>
              </td>
              <td>
                <p v-if="result2.calculate && 0 !== result2.output" v-html="$t('peasant.result.output', {nb: result2.output, product: $t('peasant.result.wheat_output'), output: result2.percent})"></p>
                <p v-if="result2.calculate && 0 < result2.bonus.length" class="bonus-infos" v-html="$t('peasant.result.bonus', {list: result2.bonus.join(', ')})"></p>
                <p v-if="result2.calculate && result2.reduce" class="field-reduce" v-html="$t('peasant.result.reduce')"></p>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.benefit')}}</td>
              <td>
                <p v-if="result1.calculate && 0 !== result1.benefitBrut && 0 !== result1.nbSelfSalary" v-html="$t('peasant.result.benefit_brut', {nb: result1.benefitBrut, value: result1.benefitBrutUnit})"></p>
                <p v-if="result1.calculate && 0 !== result1.nbSelfSalary" v-html="$t('peasant.result.self_salary', {nb: result1.nbSelfSalary, value: result1.selfSalary})"></p>
                <p v-if="result1.calculate && 0 !== result1.output && 0 === result1.nbSelfSalary" v-html="$t('peasant.result.benefit_net_1', {nb: result1.benefitNet, value: result1.benefitNetUnit})"></p>
                <p v-if="result1.calculate && 0 !== result1.output && 0 !== result1.nbSelfSalary" v-html="$t('peasant.result.benefit_net_2', {nb: result1.benefitNet, value: result1.benefitNetUnit})"></p>
              </td>
              <td>
                <p v-if="result2.calculate && 0 !== result2.benefitBrut && 0 !== result1.nbSelfSalary" v-html="$t('peasant.result.benefit_brut', {nb: result2.benefitBrut, value: result2.benefitBrutUnit})"></p>
                <p v-if="result2.calculate && 0 !== result2.nbSelfSalary" v-html="$t('peasant.result.self_salary', {nb: result2.nbSelfSalary, value: result2.selfSalary})"></p>
                <p v-if="result2.calculate && 0 !== result2.output && 0 === result2.nbSelfSalary" v-html="$t('peasant.result.benefit_net_1', {nb: result2.benefitNet, value: result2.benefitNetUnit})"></p>
                <p v-if="result2.calculate && 0 !== result2.output && 0 !== result2.nbSelfSalary" v-html="$t('peasant.result.benefit_net_2', {nb: result2.benefitNet, value: result2.benefitNetUnit})"></p>
              </td>
            </tr>
            <tr>
              <td>{{$t('peasant.table.bonus')}}</td>
              <td>
                <p v-if="result1.calculate && 0 !== farmer" v-html="$t('peasant.result.skills_farmer', {value: farmer})"></p>
                <p v-if="result1.calculate && 0 !== pray" v-html="$t('peasant.result.skills_pray', {nb: pray, value: result1.output, product: $t('peasant.result.wheat_output')})"></p>
                <p v-if="result1.calculate && true === sense" v-html="$t('peasant.result.skills_sense')"></p>
                <p v-if="result1.calculate && 0 !== result1.totalSkills" v-html="$t('peasant.result.skills_total', {nb: result1.totalSkills, product: $t('peasant.result.wheat_output'), output: result1.totalSkillsPercent})"></p>
              </td>
              <td>
                <p v-if="result2.calculate && 0 !== farmer" v-html="$t('peasant.result.skills_farmer', {value: farmer})"></p>
                <p v-if="result2.calculate && 0 !== pray" v-html="$t('peasant.result.skills_pray', {nb: pray, value: result2.output, product: $t('peasant.result.wheat_output')})"></p>
                <p v-if="result2.calculate && true === sense" v-html="$t('peasant.result.skills_sense')"></p>
                <p v-if="result2.calculate && 0 !== result2.totalSkills" v-html="$t('peasant.result.skills_total', {nb: result2.totalSkills, product: $t('peasant.result.wheat_output'), output: result2.totalSkillsPercent})"></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMinMax from "@/components/element/SelectMinMax";
import SelectPriceCent from "@/components/element/SelectPriceCent";
import SelectArray from "@/components/element/SelectArray";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'FieldWheat',
  components: {
    SelectPriceCent,
    SelectMinMax,
    SelectArray
  },
  data() {
    return {
      priceWheatMin: 10,
      priceWheatMax: 25,
      salaryMin: 12,
      salaryMax: 50,
      farmer: 0,
      pray: 0,
      sense: false,
      skills: {
        farmer: [
          {
            "label": 'skills.cultivator',
            "value": 0
          },
          {
            "label": 'skills.+5%',
            "value": 5
          },
          {
            "label": 'skills.+10%',
            "value": 10
          },
          {
            "label": 'skills.+15%',
            "value": 15
          },
          {
            "label": 'skills.+20%',
            "value": 20
          },
          {
            "label": 'skills.+25%',
            "value": 25
          },
        ],
        pray: [
          {
            "label": 'skills.pray_good_saint',
            "value": 0
          },
          {
            "label": "skills.2%",
            "value": "2"
          },
          {
            "label": "skills.5%",
            "value": "5"
          }
        ],
        sense: [
          {
            "label": 'skills.good_sense',
            "value": "no"
          },
          {
            "label": "skills.yes",
            "value": "yes"
          }
        ]
      },
      crop: [
        {
          "label": "peasant.table.previous_crop",
          "value": "harvest"
        },
        {
          "label": "peasant.table.purchase_market",
          "value": "buy"
        },
      ],
      field1: {
        typeCrop: 'harvest',
        price_buy_unit: 10,
        price_buy_cent: 0,
        employee_1: 'no',
        employee_2: 'no',
        employee_3: 'no',
        salary_1: 0,
        salary_2: 0,
        salary_3: 0,
        caract_1: 0,
        caract_2: 0,
        caract_3: 0,
        soc: 'no',
        virtue_1: '',
        virtue_2: '',
        virtue_3: '',
        ankou: 'no',
        price_sell_unit: 10,
        price_sell_cent: 0
      },
      field2: {
        typeCrop: 'harvest',
        price_buy_unit: 10,
        price_buy_cent: 0,
        employee_1: 'no',
        employee_2: 'no',
        employee_3: 'no',
        salary_1: 0,
        salary_2: 0,
        salary_3: 0,
        caract_1: 0,
        caract_2: 0,
        caract_3: 0,
        soc: 'no',
        virtue_1: '',
        virtue_2: '',
        virtue_3: '',
        ankou: 'no',
        price_sell_unit: 10,
        price_sell_cent: 0
      },
      result1: {
        calculate: false,
        buy: 0,
        nbEmployee: 0,
        totalEmployee: 0,
        investment: 0,
        output: 0,
        percent: 50,
        bonus: [],
        benefitBrut: 0,
        benefitBrutUnit: 0,
        nbSelfSalary: 0,
        selfSalary: 0,
        benefitNet: 0,
        benefitNetUnit: 0,
        totalSkills: 0,
        totalSkillsPercent: 0
      },
      result2: {
        calculate: false,
        buy: 0,
        nbEmployee: 0,
        totalEmployee: 0,
        investment: 0,
        output: 0,
        percent: 50,
        bonus: [],
        reduce: false,
        benefitBrut: 0,
        benefitBrutUnit: 0,
        nbSelfSalary: 0,
        selfSalary: 0,
        benefitNet: 0,
        benefitNetUnit: 0,
        totalSkills: 0,
        totalSkillsPercent: 0
      }
    }
  },
  mounted() {
    this.$store.dispatch('peasants/tracking', {
      page: 'open-peasant',
      component: 'wheat',
      visitor: cookies.get('lbo-site-visitor')
    });
  },
  methods: {
    calculate(field) {
      this['result'+field].calculate = false;
      const datas = this['field'+field];

      this['result'+field].buy = ('buy' === datas.typeCrop) ? parseFloat(datas.price_buy_unit + '.' + datas.price_buy_cent) : 0;

      this['result'+field].nbEmployee = 0;
      this['result'+field].nbEmployee += ('yes' === datas.employee_1) ? 1 : 0;
      this['result'+field].nbEmployee += ('yes' === datas.employee_2) ? 1 : 0;
      this['result'+field].nbEmployee += ('yes' === datas.employee_3) ? 1 : 0;

      this['result'+field].totalEmployee = 0;
      const employee1 = ('yes' === datas.employee_1) ? datas.salary_1 : 0;
      const employee2 = ('yes' === datas.employee_2) ? datas.salary_2 : 0;
      const employee3 = ('yes' === datas.employee_3) ? datas.salary_3 : 0;
      this['result'+field].totalEmployee = parseInt(employee1 + employee2 + employee3);

      this['result'+field].investment = 0;
      this['result'+field].investment += parseFloat(this['result'+field].buy + this['result'+field].totalEmployee);

      this['result'+field].percent = 50;
      this['result'+field].percent += this.upCaract(datas.caract_1);
      this['result'+field].percent += this.upCaract(datas.caract_2);
      this['result'+field].percent += this.upCaract(datas.caract_3);
      this['result'+field].percent += ('yes' === datas.soc) ? 20 : 0;
      this['result'+field].percent += ('yes' === datas.ankou) ? 10 : 0;
      this['result'+field].percent += this.upVertu(datas.virtue_1);
      this['result'+field].percent += this.upVertu(datas.virtue_2);
      this['result'+field].percent += this.upVertu(datas.virtue_3);

      this['result'+field].output = 0;
      let begin = this.begin(this.farmer);
      this['result'+field].output += Math.floor(Math.round(begin  * this['result'+field].percent) / 100);

      this['result'+field].reduce = false;
      if(2 === field) {
        this['result'+field].reduce = true;
        this['result'+field].output = Math.floor(Math.round((this['result'+field].output - ((this['result'+field].output * 25) / 100)) * 100) / 100);
      }

      this['result'+field].bonus = [];
      if("yes" === datas.soc) {
        this['result'+field].bonus.push('soc');
      }
      if("yes" === datas.ankou) {
        this['result'+field].bonus.push('ankou');
      }
      if('' !== datas.virtue_1 || '' !== datas.virtue_2 || '' !== datas.virtue_3) {
        this['result'+field].bonus.push('vertu');
      }
      if(0 !== this.farmer) {
        this['result'+field].bonus.push('compétence cultivateur');
      }

      this['result'+field].benefitBrut = 0;
      this['result'+field].benefitBrutUnit = 0;
      const sellPrice = parseFloat(datas.price_sell_unit + '.' + datas.price_sell_cent);
      const gain = Math.round(((this['result'+field].output * sellPrice) * 100) / 100);
      this['result'+field].benefitBrut = Math.round(((gain - this['result'+field].investment) * 100) / 100);
      this['result'+field].benefitBrutUnit = Math.round(((this['result'+field].benefitBrut / this['result'+field].output) * 100) / 100);

      this['result'+field].nbSelfSalary = 3 - this['result'+field].nbEmployee;

      this['result'+field].selfSalary = 0;
      const noEmployee1 = ('no' === datas.employee_1) ? datas.salary_1 : 0;
      const noEmployee2 = ('no' === datas.employee_2) ? datas.salary_2 : 0;
      const noEmployee3 = ('no' === datas.employee_3) ? datas.salary_3 : 0;
      this['result'+field].selfSalary = parseInt(noEmployee1 + noEmployee2 + noEmployee3);

      this['result'+field].benefitNet = Math.round(((gain - this['result'+field].investment - this['result'+field].selfSalary) * 100) / 100);
      this['result'+field].benefitNetUnit = Math.round(((this['result'+field].benefitNet / this['result'+field].output) * 100) / 100);

      if(0 !== this.pray || true === this.sense) {
        this['result' + field].totalSkillsPercent = 0;
        this['result' + field].totalSkillsPercent = this['result' + field].percent;
        this['result' + field].totalSkillsPercent += (0 !== this.pray) ? this['result' + field].percent : 0;
        this['result' + field].totalSkillsPercent += (true === this.sense) ? 30 : 0;

        this['result' + field].totalSkills = 0;
        let begin = this.begin(this.farmer);
        this['result' + field].totalSkills += Math.floor(Math.round(begin * this['result' + field].totalSkillsPercent) / 100);
        if (2 === field) {
          this['result' + field].totalSkills = Math.floor(Math.round((this['result' + field].totalSkills - ((this['result' + field].totalSkills * 25) / 100)) * 100) / 100);
        }
      }

      this['result'+field].calculate = true;
    },
    changeFarmer(e) {
      this.farmer = parseInt(e.target.value);
    },
    changePray(e) {
      this.pray = parseInt(e.target.value);
    },
    changeSense(e) {
      this.sense = 'yes' === e.target.value;
    },
    activePricePurchase(e, field) {
      this['field'+field].typeCrop = e.target.value;
      const selects = document.getElementsByClassName('price-buy-'+field);
      Array.from(selects).forEach((select) => {
        if('harvest' === this['field'+field].typeCrop) {
          select.setAttribute('disabled', true);
        } else {
          select.removeAttribute('disabled');
        }
      });
    },
    setPricePurchaseByUnit(e,field) {
      this['field'+field].price_buy_unit = parseInt(e.target.value);
    },
    setPriceAchatByCent(e,field) {
      this['field'+field].price_buy_cent = e.target.value.padStart(2,'0');
    },
    setEmployee(e, step, field) {
      this['field'+field]['employee_'+step] = e.target.value;
    },
    setSalary(e, step, field) {
      this['field'+field]['salary_'+step] = parseInt(e.target.value);
    },
    setCaract(e, step, field) {
      this['field'+field]['caract_'+step] = parseInt(e.target.value);
    },
    setSoc(e, field) {
      this['field'+field].soc = e.target.value;
    },
    setVirtue(e, step, field) {
      this['field'+field]['virtue_'+step] = e.target.value;
    },
    setAnkou(e, field) {
      this['field'+field].ankou = e.target.value;
    },
    setPriceSellByUnit(e,field) {
      this['field'+field].price_sell_unit = parseInt(e.target.value);
    },
    setPriceSellByCent(e,field) {
      this['field'+field].price_sell_cent = e.target.value.padStart(2,'0');
    },
    upCaract(caract) {
      switch (caract) {
        case 0:
        case 1:
          return 0;
        case 2:
        case 3:
        case 4:
        case 5:
          return 1;
        case 6:
        case 7:
        case 8:
        case 9:
          return 2;
        case 10:
        case 11:
        case 12:
        case 13:
          return 3;
        case 14:
        case 15:
        case 16:
        case 17:
          return 4;
        case 18:
        case 19:
          return 5;
      }
    },
    upVertu(vertu) {
      switch (vertu) {
        case '':
          return 0;
        case 'minor':
          return 1;
        case 'major':
          return 2;
      }
    },
    begin(farmer) {
      let start = 16;
      return Math.floor(Math.round((start - ((start * (25 - farmer)) / 100)) * 100) / 100);
    },
  }
}
</script>

<style>
.peasant-wheat {
  font-size: 12px;
  text-align: center;
}
.peasant-wheat .card {
  border: none;
}
.peasant-wheat .card-header {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
}
.peasant-wheat .peasant-skills thead,
.peasant-wheat .peasant-output thead {
  font-size: 14px;
  color: #4091c9;
  font-weight: 500;
  text-transform: uppercase;
}
.peasant-wheat .peasant-skills tbody td,
.peasant-wheat .peasant-output tbody td {
  padding: 2px;
}
.peasant-wheat .peasant-output .first-column {
  width: 20%;
}
.peasant-wheat .peasant-output .step {
  background-color: #4091c9;
  font-weight: 600;
  color: #fff;
}
.peasant-wheat .peasant-output .bonus-infos {
  color: #00b9ae;
  font-weight: 500;
}
.peasant-wheat .peasant-output .field-reduce {
  color: #ff9e00;
  font-weight: 500;
}
.peasant-wheat .peasant-skills select,
.peasant-wheat .peasant-output select {
  border-radius: 5px;
  text-align: center;
}
.peasant-wheat .peasant-output .select-price {
  width: 50px;
}
.peasant-wheat .peasant-output .select-buy {
  width: 160px;
}
.peasant-wheat .peasant-output .select-virtue {
  width: 80px;
}
.peasant-wheat .peasant-output button {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.peasant-wheat .peasant-output p {
  margin-bottom: 0;
}
</style>
