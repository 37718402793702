<template>
  <div class="card home-actuality">
    <div class="card-header">
      {{ $t('home.actuality') }}
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(actuality, index) in actualities" :key="index" @click="load(actuality)" class="list-group-item" data-bs-toggle="modal" data-bs-target="#actualityModal">
          <span :class="actuality.newPublish ? 'date-new' : ''">{{date(actuality.created)}}</span> - {{ actuality.title[$i18n.locale] }}
          <font-awesome-icon icon="fa-solid fa-hand-point-up" class="modal-icon-action"/>
        </li>
      </ul>
    </div>
    <div class="modal fade" id="actualityModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="lessonModalLabel">{{actuality.title?.[$i18n.locale]}}</h1>
          </div>
          <div class="modal-body" v-html="actuality.content?.[$i18n.locale]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'HomeActuality',
  computed: {
    ...mapState('home', {
        actualities: 'actualities'
      }
    )
  },
  data() {
    return {
      actuality: {}
    }
  },
  mounted() {
    this.$store.dispatch('home/getActualities');
  },
  methods: {
    load(actuality) {
      this.actuality = actuality;
      this.$store.dispatch('home/tracking', {
        page: 'open-home',
        component: 'actuality|uuid:'+actuality.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    },
    date(date) {
      return moment(date).format("DD-MM-YY");
    }
  }
}

</script>

<style>
.home-actuality.card {
  border: none;
}
.home-actuality .card-header {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
}
.home-actuality .card-body {
  text-align: justify;
}
.home-actuality .card-body .list-group {
  cursor: pointer
}
.home-actuality .card-body .list-group .date-new {
  color: #4091c9;
}
.home-actuality .card-body .list-group .modal-icon-action {
  float: right;
  color: #4091c9;
}
.home-actuality .modal-header {
  padding: 5px
}
.home-actuality .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
.home-actuality .modal-body a {
  text-decoration: underline;
  color: #4091c9;
}
</style>
