<template>
  <form class="way-form">
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Nom de la voie</span>
      <input type="text" v-model="name" class="form-control" id="name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Categorie de la voie</span>
      <select id="category" v-model="category" class="form-control">
        <option></option>
        <option v-for="(category, index) in categories" :value="category.uuid" :key="index">{{category.name[$i18n.locale]}}</option>
      </select>
    </div>
  </form>
  <div class="way-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
export default {
  name: 'WayForm',
  props: {
    categories: Object,
    way: Object,
    mode: String,
  },
  data() {
    return {
      name: null,
      category: null
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.name = undefined !== this.way.name ? this.way.name[this.$i18n.locale] : '';
      let cat = this.way.category;
      this.category = undefined !== cat ? cat.uuid : '';
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminUniversity/addWay', {
          name: this.name,
          category: this.category,
        });
      } else {
        this.$store.dispatch('adminUniversity/editWay', {
          uuid: this.way.uuid,
          name: this.name,
          category: this.category,
        });
      }
    }
  },
  watch: {
    way() {
      this.getDatas();
    }
  }
}
</script>

<style>
.way-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.way-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
