const mutations = {
  setBooks: function (state, books) {
    state.books = books;
  },
  setLessonsByWay: function (state, lessons) {
    state.lessonsByWay = lessons;
  },
  setWays: function (state, ways) {
    state.ways = ways;
  },
}

export default mutations;
