import api from "@/System/store/api";

const actions = {
  getCraftspeoples: ({commit}) => {
    api.get('/api/craftspeoples')
      .then(function (response) {
        commit('setCraftspeoples', response.data);
      });
  },
  getCategories: ({commit}) => {
    api.get('/api/products/categories')
      .then(function (response) {
        commit('setCategories', response.data);
      });
  },
  searchProducts: ({commit}, payload) => {
    api.post('/api/products/search-publish', payload)
      .then(function (response) {
        commit('setProducts', response.data);
      });
  },
  tracking: ({commit}, payload) => {
    api.post('/api/trackings', payload);
    commit;
  },
}

export default actions;
