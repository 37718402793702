<template>
  <div class="content container-fluid admin-translate">
    <navigation-admin></navigation-admin>

    <div class="filters">
      <h2>Je cherche une traduction...</h2>

      <div class="row">
        <div class="form-group col-12">
          <label for="name">dont le texte contient :</label>
          <input type="text" v-model="text" class="form-control form-control-sm" id="name">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label for="category">dans la langue :</label>
          <select id="category" v-model="language" class="form-control form-control-sm">
            <option value="fr">{{$t('player.translates.languages.fr')}}</option>
            <option value="en">{{$t('player.translates.languages.en')}}</option>
            <option value="it">{{$t('player.translates.languages.it')}}</option>
            <option value="it">{{$t('player.translates.languages.de')}}</option>
            <option value="it">{{$t('player.translates.languages.es')}}</option>
            <option value="it">{{$t('player.translates.languages.bu')}}</option>
            <option value="it">{{$t('player.translates.languages.nl')}}</option>
            <option value="it">{{$t('player.translates.languages.pl')}}</option>
            <option value="it">{{$t('player.translates.languages.pt')}}</option>
            <option value="it">{{$t('player.translates.languages.da')}}</option>
            <option value="it">{{$t('player.translates.languages.el')}}</option>
            <option value="it">{{$t('player.translates.languages.ro')}}</option>
            <option value="it">{{$t('player.translates.languages.ru')}}</option>
            <option value="it">{{$t('player.translates.languages.tr')}}</option>
            <option value="it">{{$t('player.translates.languages.ct')}}</option>
            <option value="it">{{$t('player.translates.languages.sl')}}</option>
            <option value="it">{{$t('player.translates.languages.hr')}}</option>
            <option value="it">{{$t('player.translates.languages.hu')}}</option>
            <option value="it">{{$t('player.translates.languages.cz')}}</option>
            <option value="it">{{$t('player.translates.languages.se')}}</option>
            <option value="it">{{$t('player.translates.languages.sr')}}</option>
            <option value="it">{{$t('player.translates.languages.fi')}}</option>
            <option value="it">{{$t('player.translates.languages.al')}}</option>
            <option value="it">{{$t('player.translates.languages.ba')}}</option>
            <option value="it">{{$t('player.translates.languages.eu')}}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="category">avec le statut :</label>
          <select id="category" v-model="statut" class="form-control form-control-sm">
            <option :value="0">{{$t('player.translates.status.empty')}}</option>
            <option :value="1">{{$t('player.translates.status.wait')}}</option>
            <option :value="2">{{$t('player.translates.status.valid')}}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <button class="search " @click="search()">{{ $t('products.search') }}</button>
        </div>
      </div>
    </div>

    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <td>{{$t('player.translates.text')}}</td>
        <td class="infos"><span :title="$t('player.translates.languages.fr')">FR</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.en')">EN</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.it')">IT</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.de')">DE</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.es')">ES</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.bu')">BU</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.nl')">NL</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.pl')">PL</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.pt')">PT</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.da')">DA</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.el')">EL</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.ro')">RO</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.ru')">RU</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.tr')">TR</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.ct')">CT</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.sl')">SL</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.hr')">HR</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.hu')">HU</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.cz')">CZ</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.se')">SE</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.sr')">SR</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.fi')">FI</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.al')">AL</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.ba')">BA</span></td>
        <td class="infos"><span :title="$t('player.translates.languages.eu')">EU</span></td>
        <td class="actions">{{$t('player.translates.actions')}}</td>
      </tr>
      </thead>
      <tbody>
        <translates-list :translates="results"></translates-list>
      </tbody>
    </table>
  </div>
</template>

<script>
import NavigationAdmin from "@/Admin/NavigationAdmin";
import TranslatesList from "@/Admin/Translate/components/TranslatesList";
import { mapState } from 'vuex'

export default {
  name: 'TranslateView',
  components: {
    NavigationAdmin,
    TranslatesList
  },
  computed: {
    ...mapState('adminTranslates', {
        translates: 'translates'
      }
    )
  },
  data() {
    return {
      text: null,
      language: 'fr',
      statut: 0,
      filters: [],
      results: []
    }
  },
  mounted() {
    if('ROLE_ADMIN' !== this.$store.state.users.user.role) {
      this.$router.push('/');
    }
    this.$store.dispatch('adminTranslates/getTranslates');
  },
  methods: {
    search() {
      if(null !== this.language && (null !== this.text || null !== this.statut)) {
        this.filters = this.getByFilters();
      }
    },
    getByFilters() {
      if(null !== this.statut && null === this.text) {
        return this.translates.filter(t => t[this.language+'Status'] === this.statut);
      }
      if(null === this.statut && null !== this.text) {
        const reg = new RegExp(this.text, 'ig');
        return this.translates.filter(t => reg.test(t[this.language]));
      }
      if(null !== this.statut && null !== this.text) {
        const reg = new RegExp(this.text, 'ig');
        return this.translates.filter(t => t[this.language+'Status'] === this.statut && reg.test(t[this.language]));
      }
    }
  },
  watch: {
    filters() {
      this.results = this.filters;
    },
    translates() {
      this.filters = this.getByFilters();
    }
  }
}
</script>

<style>
.admin-translate {
  width: 95%!important;
}
.admin-translate .filters {
  margin-top: 20px;
  border-bottom: 1px solid #4091c9;
  padding-bottom: 20px;
}
.admin-translate .filters h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}
.admin-translate .filters .search {
  width: 130px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 28px;
  float: right;
}
.admin-translate table {
  margin-top: 20px;
}
.admin-translate table thead {
  font-size: 14px;
  color: #4091c9;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.admin-translate table .infos {
  width: 5%;
}
.admin-translate table .actions {
  width: 10%;
}
</style>
