<template>
  <div class="products-block card">
    <div class="card-header">
      <span class="actions">
        <font-awesome-icon icon="fa-regular fa-square-plus" @click="form('add')" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormProducts" />
      </span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li v-for="(product, index) in products" :key="index" class="list-group-item">
          <img :src="product.icon" alt="">
          {{ product.name[$i18n.locale] }}
          <span class="actions">
            <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form('edit', product)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalFormProducts" />
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal fade" id="modalFormProducts" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{$t(labelModal)}}</h1>
        </div>
        <div class="modal-body">
          <product-form v-if="'add' === mode || 'edit' === mode" :product="product" :mode="mode" :search-name="name" :search-category="category" :search-craftspeople="craftspeople"></product-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductForm from "@/Player/Products/components/form/ProductForm";

export default {
  name: "ProductsBlock",
  components: {
    ProductForm
  },
  props: {
    list: Array,
    name: {
      type: String,
      nullable: true
    },
    category: {
      type: String,
      nullable: true
    },
    craftspeople: {
      type: String,
      nullable: true
    }
  },
  data() {
    return {
      products: [],
      mode: null,
      labelModal: '',
      product: {},
      enabled: true,
      dragging: false,
    }
  },
  methods: {
    form(mode, datas) {
      this.mode = mode;
      switch (mode) {
        case 'add':
          this.product = {};
          this.labelModal = 'player.products.add_product';
          break;
        case 'edit':
          this.product = datas;
          this.labelModal = 'player.products.add_product';
          break;
      }
    },
  },
  watch: {
    list() {
      this.products = this.list;
    },
  }
};
</script>

<style>
.products-block {
  margin-top: 20px;
  border-radius: 0!important;
  border: none!important;
}
.products-block .card-header {
  background-color: transparent;
}
.products-block .card-header .actions {
  float: right;
  color: #4091c9;
}
.products-block .card-header .actions .modal-icon-action {
  cursor: pointer;
}
.products-block .actions .modal-icon-action.online {
  color: #008000;
}
.products-block .actions .modal-icon-action.offline {
  color: #ff0000;
}
.products-block .list-group {
  border-radius: 0!important;
}
.products-block .list-group .actions {
  float: right;
  color: #4091c9;
}
.products-block .list-group .actions .modal-icon-action {
  cursor: pointer;
}
</style>
