const mutations = {
  setSuccess: function (state) {
    state.success = true;
  },
  setError: function (state) {
    state.error = true;
  },
}

export default mutations;
