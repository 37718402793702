export default {
  "home": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue !"])},
    "welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plateforme dédiée à la communauté des joueurs du <a href=\"https://www.renaissancekingdoms.com\" target=\"_blank\">Renaissance Kingdoms</a>.<br><br>Succédant à l'Intendance de Silanie, la Boîte à Outils rassemble différentes fonctionnalités visant à simplifier ou agrémenter l'expérience des joueurs autour des différentes fonctionnalités du jeu.<br><br>Merci à la communauté qui contribue à son amélioration ou à son évolution."])},
    "actuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
    "deploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en lignes"])}
  },
  "nav": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Boîte à Outils"])},
    "peasant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paysans"])},
    "craftspeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisans"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
    "kingdoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaumes"])},
    "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Université"])},
    "mines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mines"])},
    "cabin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabine d'essayage"])},
    "cabin_3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabine 3D"])},
    "itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
    "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gameuse et Programmeuse"])},
    "languages": {
      "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["français"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anglais"])}
    },
    "languages_small": {
      "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
    },
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "nav_player": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
      "craftspeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisans"])},
      "cabine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabine"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
      "translates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions"])}
    }
  },
  "skills": {
    "cultivator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultivateur"])},
    "pray_good_saint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prier le bon saint"])},
    "good_sense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon sens paysan"])},
    "+5%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+5 %"])},
    "+10%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+10 %"])},
    "+15%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+15 %"])},
    "+20%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+20 %"])},
    "+25%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+25 %"])},
    "-5%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-5 %"])},
    "-10%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-10 %"])},
    "-15%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-15 %"])},
    "-20%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-20 %"])},
    "-25%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-25 %"])},
    "2%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 %"])},
    "5%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 %"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])}
  },
  "peasant": {
    "nav": {
      "wheat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de blé"])},
      "vegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de légumes"])},
      "corn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de maïs"])},
      "barley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ d'orge"])},
      "olives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ d'oliviers"])},
      "vines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ de vignes"])}
    },
    "table": {
      "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences"])},
      "field_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ 1"])},
      "field_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ 2"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de production"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
      "raw_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matière première"])},
      "wheat_bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sac de blé"])},
      "corn_bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sac de maïs"])},
      "barley_bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sac d'orge"])},
      "grapes_bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livre de raisins"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine de la matière première"])},
      "previous_crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["récolte précédente"])},
      "purchase_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["achat sur le marché"])},
      "price_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix d'achat"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je prends un employé"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force"])},
      "intellect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligence"])},
      "soc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soc"])},
      "mais_originel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sac de maïs originel"])},
      "virtue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus de la vertu"])},
      "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mineur"])},
      "mayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majeur"])},
      "ankou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faux de l'Ankou"])},
      "price_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente"])},
      "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
      "investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement"])},
      "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement"])},
      "benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéfice"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus des compétences"])},
      "steps": {
        "wheat_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 - Labourer la terre"])},
        "wheat_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 2 - Semer le blé"])},
        "wheat_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 3 à 9 - Le blé pousse"])},
        "wheat_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 10 - Récolter le blé"])},
        "vegetables_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 à 4 - les légumes poussent"])},
        "vegetables_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 5 - Récolter les légumes"])},
        "corn_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 - Labourer la terre et semer le maïs"])},
        "corn_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 2 à 6 - Le maïs pousse"])},
        "corn_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 7 - Récolter le maïs"])},
        "barley_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 - Labourer la terre"])},
        "barley_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 2 - Semer l'orge"])},
        "barley_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 3 à 8 - L'orge pousse"])},
        "barley_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 9 - Récolter l'orge"])},
        "olives_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 à 13 - les olives poussent"])},
        "olives_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 14 - Récolter les olives"])},
        "vines_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1 - Planter la vigne"])},
        "vines_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 2 - Tailler la vigne"])},
        "vines_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 3 à 29 - La vigne pousse"])},
        "vines_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 30 - Récolter le raisin"])},
        "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente de la production"])},
        "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])}
      }
    },
    "result": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez acheté <b>1</b> ", _interpolate(_named("product")), " pour <b>", _interpolate(_named("value")), "</b> écus"])},
      "no_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez embauché personne"])},
      "nb_employee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez embauché <b>", _interpolate(_named("nb")), "</b> personne(s) pour un total de <b>", _interpolate(_named("value")), "</b> écus"])},
      "investment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour un investissement total de <b>", _interpolate(_named("value")), "</b> écus"])},
      "output": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez produit <b>", _interpolate(_named("nb")), "</b> ", _interpolate(_named("product")), " (<b>", _interpolate(_named("output")), "%</b>)"])},
      "wheat_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sacs de blé"])},
      "barley_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sacs d'orge"])},
      "vegetable_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["légumes"])},
      "corn_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sacs de maïs"])},
      "vine_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livres de raisins"])},
      "olives_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livres d'olives"])},
      "bonus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre rendement est impacté par : <b>", _interpolate(_named("list")), "</b>"])},
      "reduce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le second champ a une production réduite de 25 %"])},
      "benefit_brut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous réalisez un bénéfice brut de <b>", _interpolate(_named("nb")), "</b> écus, soit <b>", _interpolate(_named("value")), "</b> écus par unité"])},
      "self_salary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez travaillé <b>", _interpolate(_named("nb")), "</b> fois, pour un total de <b>", _interpolate(_named("value")), "</b> écus"])},
      "benefit_net_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous réalisez un bénéfice net de <b>", _interpolate(_named("nb")), "</b> écus, soit <b>", _interpolate(_named("value")), "</b> écus par unité"])},
      "benefit_net_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En déduisant votre salaire, vous réalisez un bénéfice net de <b>", _interpolate(_named("nb")), "</b> écus, soit <b>", _interpolate(_named("value")), "</b> écus par unité"])},
      "skills_farmer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre rendement bénéficie d'une augmentation de <b>", _interpolate(_named("value")), "</b> %"])},
      "skills_pray": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez <b>", _interpolate(_named("nb")), "</b> % de chance de récolter <b>", _interpolate(_named("value")), "</b> ", _interpolate(_named("product")), " supplémentaires"])},
      "skills_sense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez <b>10%</b> de chance d'augmenter votre rendement de <b>15%</b> lors du labourage et du semis"])},
      "skills_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous avez de la chance, ces compétences vous permettraient de récolter <b>", _interpolate(_named("nb")), "</b> ", _interpolate(_named("product")), " (<b>", _interpolate(_named("output")), "</b> %)"])}
    }
  },
  "products": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je cherche un produit..."])},
    "filter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dont le nom contient"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans la catégorie"])},
    "filter_craftspeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit par"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix (min-max)"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encombrement"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "wiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez utiliser au moins un filtre pour obtenir un résultat ou il n'y a pas de produit correspondant à votre recherche."])}
  },
  "university": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Université"])},
    "tab": {
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etudes"])},
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres"])}
    },
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matière"])},
    "prerequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prérequis"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "utility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilité"])},
    "provinces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinces"])}
  },
  "mines": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mines"])},
    "tab": {
      "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquentation"])},
      "ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût de revient"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
      "prod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût production réelle"])}
    },
    "titles": {
      "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement minier à partir de la fréquentation"])},
      "ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation du coût de revient d'une production"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation de l'activité minière sur 7 jours"])},
      "prod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût à partir de la production réelle"])}
    },
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de mine"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de la mine"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquentation"])},
    "sales_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs marchandes"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "type_mines": {
      "iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fer"])},
      "stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierre"])},
      "gold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
      "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argile"])},
      "salt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sel"])}
    },
    "time": {
      "1h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1h"])},
      "2h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2h"])},
      "6h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6h"])},
      "10h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10h"])},
      "22h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22h"])},
      "5pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5PA"])},
      "10pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10PA"])},
      "30pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30PA"])},
      "50pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50PA"])},
      "100pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100PA"])}
    },
    "days": {
      "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 1"])},
      "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 2"])},
      "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 3"])},
      "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 4"])},
      "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 5"])},
      "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 6"])},
      "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour 7"])}
    },
    "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "cost_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût et bénéfice de la production"])},
    "time_without_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["base"])},
    "time_skills_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comp. 0%"])},
    "time_skills_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comp. 25%"])},
    "without_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement de base"])},
    "with_skills_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement minimum (compétences à 0%)"])},
    "with_skills_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement maximum (compétences à 25%)"])},
    "total_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation totale par format"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation totale"])},
    "results_ownership": {
      "quantity_iron_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de fer utilisés"])},
      "cost_iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût en fer"])},
      "quantity_stone_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de pierres utilisées"])},
      "cost_stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût en pierres"])},
      "wages_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire versé"])},
      "total_cost_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total frais de production"])},
      "quantity_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité produite"])},
      "value_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation de la production"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéfice"])},
      "investment_web_mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement total Web - Mobile"])},
      "investment_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement total"])},
      "profit_web_mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéfice total Web - Mobile"])},
      "profit_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéfice total"])}
    },
    "result_weekly": {
      "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquentation totale"])},
      "cost_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût en salaire"])},
      "maintenance_iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien en fer"])},
      "cost_maintenance_iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût entretien en fer"])},
      "maintenance_stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien en pierre"])},
      "cost_maintenance_stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût entretien en pierre"])},
      "cost_total_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût total production"])},
      "quantity_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité produite"])},
      "value_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation de la production"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéfice"])}
    }
  },
  "craftspeople": {
    "intellect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligence"])},
    "skills": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences"])}
    },
    "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement"])},
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matières premières"])},
    "price_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix d'achat"])},
    "time_h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps (h)"])},
    "time_pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps (PA)"])},
    "price_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente"])},
    "total_gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain total"])},
    "unit_gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain unité"])},
    "desired_gain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain souhaité"])},
    "unit_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente à l'unité"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "intro_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez me contacter directement sur le jeu"])},
    "intro_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou par le biais de ce formulaire"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "placeholder": {
      "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du mode de réponse"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom IG"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet du message"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message"])}
    },
    "options": {
      "letter_ig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courrier IG"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
      "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de réponse"])},
      "suggest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion de fonctionnalité"])},
      "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un élément"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'informations"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "errors": {
      "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez choisir un mode de réponse"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez indiquer votre nom IG"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez indiquer une adresse email"])},
      "email_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format est incorrect"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez préciser l'objet de votre message"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas votre message"])}
    },
    "status": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a bien été envoyé"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi de votre message"])}
    }
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "tabs": {
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension"])}
    }
  },
  "user": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription - Connexion"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je m'inscris"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je me connecte"])},
    "status": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre inscription a bien été enregistrée, vous êtes désormais connecté"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi de votre inscription"])}
    }
  },
  "player": {
    "home": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenu dans l'espace Contribution.<br>Je vous remercie de votre aide pour faire vivre le site."])}
    },
    "craftspeople": {
      "add_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une production"])},
      "edit_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une production"])},
      "time_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps (h)"])},
      "time_pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps (PA)"])},
      "quantity_output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité produite"])},
      "ingredient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrédient"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
      "craftspeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot clé"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "quantity_ingredient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité ingrédient"])},
      "nb_desynchro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de désynchronisation"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
    },
    "products": {
      "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un produit"])},
      "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un produit"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "name_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom pluriel"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
      "price_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix min"])},
      "price_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix max"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encombrement"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icône"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "wiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
    },
    "translates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je cherche une traduction..."])},
      "search_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dont le texte contient"])},
      "search_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans la langue"])},
      "languages": {
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["français"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anglais"])},
        "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["italien"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allemand"])},
        "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["espagnol"])},
        "bu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulgare"])},
        "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["néerlandais"])},
        "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polonais"])},
        "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["portugais"])},
        "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["danois"])},
        "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grec"])},
        "ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roumain"])},
        "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["russe"])},
        "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["turque"])},
        "ct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalan"])},
        "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slovène"])},
        "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["croate"])},
        "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hongrois"])},
        "cz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tchèque"])},
        "se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suédois"])},
        "sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serbe"])},
        "fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finnois"])},
        "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["albanais"])},
        "ba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ba"])},
        "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basque"])}
      },
      "search_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec le statut"])},
      "status": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à traiter"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en validation"])},
        "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validé et publié"])}
      },
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
    }
  }
}