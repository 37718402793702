<template>
  <div class="content container-fluid admin-translate">
    <navigation-player></navigation-player>

    <div class="filters">
      <h2>{{$t('player.translates.title')}}</h2>

      <div class="row">
        <div class="form-group col-12">
          <label for="name">{{$t('player.translates.search_text')}} :</label>
          <input type="text" v-model="text" class="form-control form-control-sm" id="name">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label for="category">{{$t('player.translates.search_language')}} :</label>
          <select id="category" v-model="language" class="form-control form-control-sm">
            <option value="fr" v-if="-1 !== user.languages.indexOf('fr')">{{$t('player.translates.languages.fr')}}</option>
            <option value="en" v-if="-1 !== user.languages.indexOf('en')">{{$t('player.translates.languages.en')}}</option>
            <option value="it" v-if="-1 !== user.languages.indexOf('it')">{{$t('player.translates.languages.it')}}</option>
            <option value="de" v-if="-1 !== user.languages.indexOf('de')">{{$t('player.translates.languages.de')}}</option>
            <option value="es" v-if="-1 !== user.languages.indexOf('es')">{{$t('player.translates.languages.es')}}</option>
            <option value="bu" v-if="-1 !== user.languages.indexOf('bu')">{{$t('player.translates.languages.bu')}}</option>
            <option value="nl" v-if="-1 !== user.languages.indexOf('nl')">{{$t('player.translates.languages.nl')}}</option>
            <option value="pl" v-if="-1 !== user.languages.indexOf('pl')">{{$t('player.translates.languages.pl')}}</option>
            <option value="pt" v-if="-1 !== user.languages.indexOf('pt')">{{$t('player.translates.languages.pt')}}</option>
            <option value="da" v-if="-1 !== user.languages.indexOf('da')">{{$t('player.translates.languages.da')}}</option>
            <option value="el" v-if="-1 !== user.languages.indexOf('el')">{{$t('player.translates.languages.el')}}</option>
            <option value="ro" v-if="-1 !== user.languages.indexOf('ro')">{{$t('player.translates.languages.ro')}}</option>
            <option value="ru" v-if="-1 !== user.languages.indexOf('ru')">{{$t('player.translates.languages.ru')}}</option>
            <option value="tr" v-if="-1 !== user.languages.indexOf('tr')">{{$t('player.translates.languages.tr')}}</option>
            <option value="ct" v-if="-1 !== user.languages.indexOf('ct')">{{$t('player.translates.languages.ct')}}</option>
            <option value="sl" v-if="-1 !== user.languages.indexOf('sl')">{{$t('player.translates.languages.sl')}}</option>
            <option value="hr" v-if="-1 !== user.languages.indexOf('hr')">{{$t('player.translates.languages.hr')}}</option>
            <option value="hu" v-if="-1 !== user.languages.indexOf('hu')">{{$t('player.translates.languages.hu')}}</option>
            <option value="cz" v-if="-1 !== user.languages.indexOf('cz')">{{$t('player.translates.languages.cz')}}</option>
            <option value="se" v-if="-1 !== user.languages.indexOf('se')">{{$t('player.translates.languages.se')}}</option>
            <option value="sr" v-if="-1 !== user.languages.indexOf('sr')">{{$t('player.translates.languages.sr')}}</option>
            <option value="fi" v-if="-1 !== user.languages.indexOf('fi')">{{$t('player.translates.languages.fi')}}</option>
            <option value="al" v-if="-1 !== user.languages.indexOf('al')">{{$t('player.translates.languages.al')}}</option>
            <option value="ba" v-if="-1 !== user.languages.indexOf('ba')">{{$t('player.translates.languages.ba')}}</option>
            <option value="eu" v-if="-1 !== user.languages.indexOf('eu')">{{$t('player.translates.languages.eu')}}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="category">{{$t('player.translates.search_status')}} :</label>
          <select id="category" v-model="statut" class="form-control form-control-sm">
            <option></option>
            <option :value="0">{{$t('player.translates.status.empty')}}</option>
            <option :value="1">{{$t('player.translates.status.wait')}}</option>
            <option :value="2">{{$t('player.translates.status.valid')}}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <button class="search " @click="search()">{{ $t('products.search') }}</button>
        </div>
      </div>
    </div>

    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <td>{{$t('player.translates.text')}}</td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('fr')"><span :title="$t('player.translates.languages.fr')">FR</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('en')"><span :title="$t('player.translates.languages.en')">EN</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('it')"><span :title="$t('player.translates.languages.it')">IT</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('de')"><span :title="$t('player.translates.languages.de')">DE</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('es')"><span :title="$t('player.translates.languages.es')">ES</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('bu')"><span :title="$t('player.translates.languages.bu')">BU</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('nl')"><span :title="$t('player.translates.languages.nl')">NL</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('pl')"><span :title="$t('player.translates.languages.pl')">PL</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('pt')"><span :title="$t('player.translates.languages.pt')">PT</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('da')"><span :title="$t('player.translates.languages.da')">DA</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('el')"><span :title="$t('player.translates.languages.el')">EL</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('ro')"><span :title="$t('player.translates.languages.ro')">RO</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('ru')"><span :title="$t('player.translates.languages.ru')">RU</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('tr')"><span :title="$t('player.translates.languages.tr')">TR</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('ct')"><span :title="$t('player.translates.languages.ct')">CT</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('sl')"><span :title="$t('player.translates.languages.sl')">SL</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('hr')"><span :title="$t('player.translates.languages.hr')">HR</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('hu')"><span :title="$t('player.translates.languages.hu')">HU</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('cz')"><span :title="$t('player.translates.languages.cz')">CZ</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('se')"><span :title="$t('player.translates.languages.se')">SE</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('sr')"><span :title="$t('player.translates.languages.sr')">SR</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('fi')"><span :title="$t('player.translates.languages.fi')">FI</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('al')"><span :title="$t('player.translates.languages.al')">AL</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('ba')"><span :title="$t('player.translates.languages.ba')">BA</span></td>
        <td class="infos" v-if="-1 !== user.languages.indexOf('eu')"><span :title="$t('player.translates.languages.eu')">EU</span></td>
        <td class="actions">{{$t('player.translates.actions')}}</td>
      </tr>
      </thead>
      <tbody>
        <translates-list :translates="results"></translates-list>
      </tbody>
    </table>
  </div>
</template>

<script>
import NavigationPlayer from "@/Player/NavigationPlayer";
import TranslatesList from "@/Player/Translate/components/TranslatesList";
import { mapState } from 'vuex'

export default {
  name: 'TranslateView',
  components: {
    NavigationPlayer,
    TranslatesList
  },
  computed: {
    ...mapState('playerTranslates', {
        translates: 'translates'
      }
    )
  },
  data() {
    return {
      user: this.$store.state.users.user,
      text: null,
      language: null,
      statut: null,
      filters: [],
      results: []
    }
  },
  mounted() {
    if('ROLE_CONTRIBUTOR' !== this.user.role) {
      this.$router.push('/');
    }
    this.language = this.user.languages[0]
    this.$store.dispatch('playerTranslates/getTranslates');
  },
  methods: {
    search() {
      if(null !== this.language && (null !== this.text || null !== this.statut)) {
        this.filters = this.getByFilters();
      }
    },
    getByFilters() {
      if(null !== this.statut && null === this.text) {
        return this.translates.filter(t => t[this.language+'Status'] === this.statut);
      }
      if(null === this.statut && null !== this.text) {
        const reg = new RegExp(this.text, 'ig');
        return this.translates.filter(t => reg.test(t[this.language]));
      }
      if(null !== this.statut && null !== this.text) {
        const reg = new RegExp(this.text, 'ig');
        return this.translates.filter(t => t[this.language+'Status'] === this.statut && reg.test(t[this.language]));
      }
    }
  },
  watch: {
    filters() {
      this.results = this.filters;
    },
    translates() {
      this.filters = this.getByFilters();
    }
  }
}
</script>

<style>
.admin-translate {
  width: 95%!important;
}
.admin-translate .filters {
  margin-top: 20px;
  border-bottom: 1px solid #4091c9;
  padding-bottom: 20px;
}
.admin-translate .filters h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}
.admin-translate .filters .search {
  width: 130px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 28px;
  float: right;
}
.admin-translate table {
  margin-top: 20px;
}
.admin-translate table thead {
  font-size: 14px;
  color: #4091c9;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.admin-translate table .infos {
  width: 5%;
}
.admin-translate table .actions {
  width: 10%;
}
</style>
