<template>
  <div class="content container-fluid contact">
    <div class="card page-content">
      <div class="card-header page-title">
        {{$t('contact.title')}}
      </div>
      <div class="card-body">
        <p class="intro">{{$t('contact.intro_1')}} :
          <a href="https://www.renaissancekingdoms.com/FichePersonnage.php?login=silanie" target="_blank" id="pseudo">Silanie</a>
        </p>
        <p class="intro">{{$t('contact.intro_2')}}</p>

        <div class="form col-8 offset-2">
          <div>
            <p v-if="success" class="alert alert-success">{{$t('contact.status.success')}}</p>
            <p v-if="error" class="alert alert-danger">{{$t('contact.status.error')}}</p>
          </div>
          <div class="form-element">
            <select class="form-select form-select-sm" v-model="response" :class="errorResponse">
              <option value="off">-- {{$t('contact.placeholder.response')}} --</option>
              <option value="ig">{{$t('contact.options.letter_ig')}}</option>
              <option value="email">{{$t('contact.options.email')}}</option>
            </select>
            <span class="error-message" v-if="null !== errorResponse">{{$t(errors.response)}}</span>
          </div>
          <div class="form-element">
            <input v-if="'ig' === response" class="form-control form-control-sm" type="text" v-model="login" :class="errorLogin" :placeholder="'-- '+$t('contact.placeholder.login')+' --'">
            <span class="error-message" v-if="null !== errorLogin">{{$t(errors.login)}}</span>
          </div>
          <div class="form-element">
            <input v-if="'email' === response" class="form-control form-control-sm" type="email" v-model="email" :class="errorEmail" :placeholder="'-- '+$t('contact.placeholder.email')+' --'">
            <span class="error-message" v-if="null !== errorEmail">{{$t(errors.email)}}</span><br v-if="null!== errorEmail && null !== errorEmailFormat">
            <span class="error-message" v-if="null !== errorEmailFormat">{{$t(errors.emailFormat)}}</span>
          </div>
          <div class="form-element">
            <select class="form-select form-select-sm" v-model="subject" :class="errorSubject">
              <option value="off">-- {{$t('contact.placeholder.reason')}} --</option>
              <option value="suggest">{{$t('contact.options.suggest')}}</option>
              <option value="fix">{{$t('contact.options.fix')}}</option>
              <option value="informations">{{$t('contact.options.info')}}</option>
              <option value="other">{{$t('contact.options.other')}}</option>
            </select>
            <span class="error-message" v-if="null !== errorSubject">{{$t(errors.subject)}}</span>
          </div>
          <div class="form-element">
            <textarea class="form-control" v-model="message" :class="errorMessage" :placeholder="'-- '+$t('contact.placeholder.message')+' --'" style="height: 100px"></textarea>
            <span class="error-message" v-if="null !== errorMessage">{{$t(errors.message)}}</span>
          </div>
          <input type="hidden" name="date" v-model="date">
          <div class="form-element">
            <button class="col-3 offset-9" @click="send">{{$t('contact.send')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'ContactView',
  components: {},
  computed: {},
  data() {
    return {
      response: 'off',
      login: null,
      email: null,
      subject: 'off',
      message: null,
      date: null,
      success: false,
      error: false,
      errorResponse: null,
      errorLogin: null,
      errorEmail: null,
      errorEmailFormat: null,
      errorSubject: null,
      errorMessage: null,
      errors: {
        response: null,
        login: null,
        email: null,
        emailFormat: null,
        subject: null,
        message: null,
        date: null
      },
      countErrors: 0,
    }
  },
  mounted() {
    this.$store.dispatch('contacts/tracking', {
      page: 'open-contact',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  },
  methods: {
    send() {
      this.check();

      if(0 === this.countErrors) {
        let payload = {
          response: this.response,
          login: this.login,
          email: this.email,
          subject: this.subject,
          message: this.message
        };

        this.$store.dispatch('contacts/send', payload);

        setTimeout(() => {
          this.response = 'off';
          this.login = null;
          this.email = null;
          this.subject = 'off';
          this.message = null;

          this.success = this.$store.state.contacts.success;
          this.error = this.$store.state.contacts.error;
        }, 1000);
      }
    },
    check() {
      this.countErrors = 0;

      this.checkResponse();
      this.checkLogin();
      this.checkEmail();
      this.checkSubject();
      this.checkMessage();
      this.checkDate();
    },
    checkResponse() {
      this.errorResponse = null;
      this.errors.response = null;
      if('off' === this.response) {
        this.countErrors++;
        this.errorResponse = 'error';
        this.errors.response = 'contact.errors.response';
      }
    },
    checkLogin() {
      this.errorLogin = null;
      this.errors.login = null;
      if('ig' === this.response && (null === this.login || '' === this.login)) {
        this.countErrors++;
        this.errorLogin = 'error';
        this.errors.login = 'contact.errors.login';
      }
    },
    checkEmail() {
      this.errorEmail = null;
      this.errors.email = null;
      if('email' === this.response && (null === this.email || '' === this.email)) {
        this.countErrors++;
        this.errorEmail = 'error';
        this.errors.email = 'contact.errors.email';
      }

      this.errorEmailFormat = null;
      this.errors.emailFormat = null;
      const ex = new RegExp(/^[a-z0-9.-]+@[a-z0-9._-]{2,}\.[a-z]{2,8}$/);
      if('email' === this.response && (null !== this.email || '' !== this.email) && false === ex.test(this.email)) {
        this.countErrors++;
        this.errorEmailFormat = 'error';
        this.errors.emailFormat = 'contact.errors.email_format';
      }
    },
    checkSubject() {
      this.errorSubject = null;
      this.errors.subject = [];
      if('off' === this.subject) {
        this.countErrors++;
        this.errorSubject = 'error';
        this.errors.subject = 'contact.errors.subject';
      }
    },
    checkMessage() {
      this.errorMessage = null;
      this.errors.message = [];
      if(null === this.message || '' === this.message) {
        this.countErrors++;
        this.errorMessage = 'error';
        this.errors.message = 'contact.errors.message';
      }
    },
    checkDate() {
      this.errors.date = [];
      if(null !== this.date) {
        this.countErrors++;
        this.errors.date = 'Je suis un robot';
      }
    }
  },
  watch: {
    success() {
      setTimeout(() => {
        location.reload()
      }, 2000);
    },
    error() {
      setTimeout(() => {
        location.reload()
      }, 2000);
    }
  }
}
</script>

<style>
.contact {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.contact .page-content {
  border: none;
}
.contact .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.contact .card-body .intro {
  text-align: center;
}
.contact .card-body .intro #pseudo {
  color: #4091c9;
  font-weight: 600;
}
.contact .card-body .form {
  border: 1px solid #4091c9;
  padding: 10px;
}
.contact .card-body .form-element {
  margin-top: 20px;
}
.contact .card-body .form input::placeholder,
.contact .card-body .form textarea::placeholder {
  color: #212529;
}
.contact .card-body .form button {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.contact .form .error {
  border: 1px solid #ff0000;
}
.contact .form .error-message {
  color: #ff0000;
  font-size: 10px;
  font-weight: 600;
}
</style>
