<template>
  <div class="content container-fluid user">
    <div class="card page-content">
      <div class="card-header page-title">
        {{ $t('user.title') }}
      </div>
      <div class="card-body page-body">
        <div class="row">
          <user-inscription></user-inscription>
          <user-connexion></user-connexion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInscription from "@/Open/User/components/UserInscription";
import UserConnexion from "@/Open/User/components/UserConnexion";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UserView',
  components: {
    UserInscription,
    UserConnexion
  },
  mounted() {
    this.$store.dispatch('users/tracking', {
      page: 'open-users',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  }
}
</script>

<style>
.user {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.user .page-content {
  border: none;
}
.user .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
</style>
