const mutations = {
  setActualities: function (state, actualities) {
    state.actualities = actualities;
  },
  setDeploys: function (state, deploys) {
    state.deploys = deploys;
  },
  /*setCategories: function (state, categories) {
    state.categories = categories;
  },
  setLanguages: function (state, languages) {
    state.languages = languages;
  },
  setLessons: function (state, lessons) {
    state.lessons = lessons;
  },
  setProvinces: function (state, provinces) {
    state.provinces = provinces;
  },
  setWays: function (state, ways) {
    state.ways = ways;
  },*/
}

export default mutations;
