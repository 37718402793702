import actions from "@/Open/Contact/store/modules/actions";
import state from "@/Open/Contact/store/modules/states";
import mutations from "@/Open/Contact/store/modules/mutations";

const contacts = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
}
export default contacts;
