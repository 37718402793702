import state from "@/Open/Craftspeople/store/modules/states";
import mutations from "@/Open/Craftspeople/store/modules/mutations";
import actions from "@/Open/Craftspeople/store/modules/actions";

const craftspeople = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
}
export default craftspeople;
