<template>
  <div class="content container-fluid faqs">
    <div class="card page-content">
      <div class="card-header page-title">
        {{$t('faq.title')}}
      </div>
      <div class="card-body page-body">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button @click="track('site')" class="nav-link active" id="nav-etudes-tab" data-bs-toggle="tab" data-bs-target="#nav-etudes" type="button" role="tab" aria-selected="true">
            {{ $t('faq.tabs.site') }}
          </button>
          <button @click="track('extension')" class="nav-link" id="nav-livres-tab" data-bs-toggle="tab" data-bs-target="#nav-livres" type="button" role="tab" aria-selected="true">
            {{ $t('faq.tabs.extension') }}
          </button>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="separator"></div>
          <div class="tab-pane fade show active" id="nav-etudes" role="tabpanel" tabindex="0">
            <faq-site></faq-site>
          </div>
          <div class="tab-pane fade show" id="nav-livres" role="tabpanel" tabindex="0">
            <faq-extension></faq-extension>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FaqSite from "@/Open/Faq/components/FaqSite";
import FaqExtension from "@/Open/Faq/components/FaqExtension";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'FaqView',
  components: {
    FaqSite,
    FaqExtension
  },
  mounted() {
    this.$store.dispatch('faqs/tracking', {
      page: 'open-faqs',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
    this.track('site');
  },
  methods: {
    track(tab) {
      this.$store.dispatch('faqs/tracking', {
        page: 'open-faqs',
        component: tab,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>

<style>
.faqs {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.faqs .page-content {
  border: none;
}
.faqs .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.faqs .nav-link {
  color: #000000;
}
.faqs .nav-link.active {
  color: #4091c9!important;
}
</style>
