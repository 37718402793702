<template>
  <div class="content container-fluid player-home">
    <navigation-player></navigation-player>

    <div class="intro" v-html="$t('player.home.intro')"></div>
  </div>
</template>

<script>
import NavigationPlayer from "@/Player/NavigationPlayer";

export default {
  name: 'HomeView',
  components: {
    NavigationPlayer,
  }
}
</script>

<style>
.player-home {
  width: 95%!important;
}
.player-home .intro {
  margin-top: 20px;
  text-align: center;
}
</style>
