<template>
  <div class="content container-fluid university">
    <div class="card page-content">
      <div class="card-header page-title">
        {{ $t('university.title') }}
      </div>
      <div class="card-body page-body">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button @click="track('lessons')" class="nav-link active" id="nav-etudes-tab" data-bs-toggle="tab" data-bs-target="#nav-etudes" type="button" role="tab" aria-selected="true">
            {{ $t('university.tab.lessons') }}
          </button>
          <button @click="track('books')" class="nav-link" id="nav-livres-tab" data-bs-toggle="tab" data-bs-target="#nav-livres" type="button" role="tab" aria-selected="true">
            {{ $t('university.tab.books') }}
          </button>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="separator"></div>
          <div class="tab-pane fade show active" id="nav-etudes" role="tabpanel">
            <university-lessons></university-lessons>
          </div>
          <div class="tab-pane fade show" id="nav-livres" role="tabpanel">
            <university-books></university-books>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UniversityLessons from "@/Open/University/components/UniversityLessons";
import UniversityBooks from "@/Open/University/components/UniversityBooks";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'UniversityView',
  components: {
    UniversityLessons,
    UniversityBooks
  },
  mounted() {
    this.$store.dispatch('university/tracking', {
      page: 'open-university',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
    this.track('open-university-lessons');
  },
  methods: {
    track(tab) {
      this.$store.dispatch('university/tracking', {
        page: 'open-university',
        component: tab,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>

<style>
.university {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.university .page-content {
  border: none;
}
.university .page-title {
  border-radius: unset!important;
  border-bottom: 2px solid #4091c9;
  background-color: transparent;
  color: #4091c9;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  position: relative;
}
.university .nav-link {
  color: #000000;
}
.university .nav-link.active {
  color: #4091c9!important;
}
</style>
