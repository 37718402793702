import ContactView from "@/Admin/Contact/views/ContactView";
import CraftspeopleView from "@/Admin/Craftspeople/views/CraftspeopleView";
import FaqView from "@/Admin/Faq/views/FaqView";
import HomeView from "@/Admin/Home/views/HomeView";
import TranslateView from "@/Admin/Translate/views/TranslateView";
import ProductsView from "@/Admin/Products/views/ProductsView";
import UniversityView from "@/Admin/University/views/UniversityView";

import AdminCabineView from "@/Admin/Cabine/view/CabineView";
import AdminUsersView from "@/Admin/Users/views/UsersView";


export default [
  {
    path: '/admin-contact',
    name: 'admin-contact',
    component: ContactView
  },
  {
    path: '/admin-artisan',
    name: 'admin-artisan',
    component: CraftspeopleView
  },
  {
    path: '/admin-faq',
    name: 'admin-faq',
    component: FaqView
  },
  {
    path: '/admin',
    name: 'admin',
    component: HomeView
  },
  {
    path: '/admin-products',
    name: 'admin-products',
    component: ProductsView
  },
  {
    path: '/admin-translate',
    name: 'admin-translate',
    component: TranslateView
  },
  {
    path: '/admin-university',
    name: 'admin-university',
    component: UniversityView
  },



  {
    path: '/admin-cabine',
    name: 'admin-cabine',
    component: AdminCabineView
  },
  {
    path: '/admin-users',
    name: 'admin-users',
    component: AdminUsersView
  },
];
