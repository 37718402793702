<template>
  <form class="actuality-form">
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-4">Titre</span>
      <input type="text" v-model="title" class="form-control" id="name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-2">Prérequis</span>
      <ckeditor :editor="editor" v-model="content" :config="editorConfig" class="form-control col-10" id="prerequis"></ckeditor>
    </div>
  </form>
  <div class="actuality-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'ActualityForm',
  props: {
    actuality: Object,
    mode: String,
  },
  components: {
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
      },
      title: null,
      content: '',
    }
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.title = undefined !== this.actuality.title ? this.actuality.title[this.$i18n.locale] : null;
      this.content = undefined !== this.actuality.content && null !== this.actuality.content ? this.actuality.content[this.$i18n.locale] : '';
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminHome/addActuality', {
          title: this.title,
          content: this.content,
        });
      } else {
        this.$store.dispatch('adminHome/editActuality', {
          uuid: this.actuality.uuid,
          title: this.title,
          content: this.content,
        });
      }
    }
  },
  watch: {
    actuality() {
      this.getDatas();
    }
  },
}
</script>

<style>
.actuality-form .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.actuality-form .ck-editor__editable {
  min-height: 300px;
}
.actuality-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.actuality-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
