<template>
  <div v-if="0 === products.length" class="list-products alert alert-info" role="alert">{{$t('products.alert')}}</div>
  <div class="card list-products" v-for="(product, index) in products" :key="index">
    <div class="row g-0">
      <div class="col-1 icon-catalog">
        <img :src="product?.icon" class="img-fluid rounded-start" alt="">
      </div>
      <div class="col-11">
        <div class="row first">
          <h4 class="col-11">{{ product?.name[$i18n.locale] }}</h4>
          <p class="col-1">
            <a class="link-show-more" :href="product.wiki?.[$i18n.locale]" target="_blank">
              {{ $t('products.wiki') }}
            </a>
          </p>
        </div>
        <hr>
        <div class="row second">
          <p class="col-4 category">{{ product?.category.name[$i18n.locale] }}</p>
          <p class="col-4 text-muted"><strong>{{ $t('products.price') }} :</strong> {{ product?.priceMin }} - {{ product?.priceMax }}.95</p>
          <p class="col-4 text-muted"><strong>{{ $t('products.weight') }} :</strong> {{ product?.weight }}</p>
        </div>
        <div class="row third">
          <p class="title-description">{{ $t('products.description') }}</p>
          <p class="description" v-if="null !== product.description">{{ product?.description[$i18n.locale] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsList",
  props: {
    list: Array
  },
  data() {
    return {
      products: []
    }
  },
  watch: {
    list() {
      this.products = this.list;
    }
  }
};
</script>

<style>
.list-products {
  margin-top: 20px;
}
.list-products .icon-catalog {
  position: relative;
}
.list-products .icon-catalog img {
  position: absolute;
  top: 40%;
  left: 40%;
}
.list-products hr {
  width: 95%;
  margin: auto;
}
.list-products h4 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  height: 20px;
  padding-top: 5px;
}
.list-products p {
  margin: 0;
}
.list-products .link-show-more {
  font-size: 12px;
  font-weight: 700;
  color: #4091c9;
  cursor: pointer;
  text-decoration: none;
}
.list-products .second {
  margin-top: 10px;
}
.list-products .second .category {
  font-size: 14px;
  text-transform: uppercase;
  color: #4091c9!important;
}
.list-products .second .text-muted {
  font-size: 12px;
}
.list-products .second .text-muted strong {
  font-weight: 600;
}
.list-products .third {
  width: 95%;
  margin: 10px auto auto;
}
.list-products .third .title-description {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #6c757d;
}
.list-products .description {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
}
</style>
