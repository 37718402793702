<template>
  <div class="users-list card">
    <div class="card-body">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <td>Nom</td>
            <td class="size">Role</td>
            <td>Modules</td>
            <td>Langues</td>
            <td>Création</td>
            <td>Actif</td>
            <td>Supprimé</td>
            <td class="size">Actions</td>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>{{user.login}}</td>
          <td>{{getRole(user.roles)}}</td>
          <td v-html="user.modules?.join('<br>')"></td>
          <td>{{user.languages?.join(' - ')}}</td>
          <td>{{date(user.created)}}</td>
          <td>
            <font-awesome-icon icon="fa-solid fa-power-off" :class="!user.closed ? 'online' : 'offline'" />
          </td>
          <td>{{date(user.deleted)}}</td>
          <td class="actions">
            <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="form(user)" class="modal-icon-action" data-bs-toggle="modal" data-bs-target="#modalUserForm" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" id="modalUserForm" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Editer un utilisateur</h1>
        </div>
        <div class="modal-body">
          <user-form :user="user"></user-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from "@/Admin/Users/components/form/UserForm";
import { mapState } from 'vuex'
import moment from "moment";

export default {
  name: "FaqList",
  components: {
    UserForm
  },
  computed: {
    ...mapState('adminUsers', {
        users: 'users'
      }
    )
  },
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    this.$store.dispatch('adminUsers/getUsers');
  },
  methods: {
    form(datas) {
      this.user = datas;
    },
    getRole(role) {
      switch (role) {
        case 'ROLE_ADMIN':
          return 'Admin';
        case 'ROLE_CONTRIBUTOR':
          return 'Contributeur';
        default:
          return 'User'
      }
    },
    date(date) {
      if(null === date) {
        return null;
      }
      return moment(date).format("DD-MM-YY");
    },
  }
}
</script>

<style>
.users-list {
  border: none!important;
}
.users-list table {
  margin-top: 20px;
  text-align: center;
}
.users-list table thead {
  font-size: 14px;
  color: #4091c9;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}
.users-list table .size {
  width: 10%;
}
.users-list .actions {
  color: #4091c9!important;
}
.users-list .online {
  color: #008000;
}
.users-list .offline {
  color: #ff0000;
}
.users-list .actions .modal-icon-action {
  cursor: pointer;
}
#modalUserForm .modal-header {
   padding: 5px
 }
#modalUserForm .modal-title {
  margin: auto;
  text-transform: uppercase;
  color: #4091c9;
  font-weight: 400;
}
</style>
