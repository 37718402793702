<template>
  <div class="content container-fluid peasant">
    <div class="row">
      <div>
        <select v-model="select" @change="change($event)" id="change-peasant">
          <option value="wheat">{{ $t('peasant.nav.wheat') }}</option>
          <option value="vegetables">{{ $t('peasant.nav.vegetables') }}</option>
          <option value="corn">{{ $t('peasant.nav.corn') }}</option>
          <option value="barley">{{ $t('peasant.nav.barley') }}</option>
          <option value="olives">{{ $t('peasant.nav.olives') }}</option>
          <option value="vines">{{ $t('peasant.nav.vines') }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <field-wheat v-if="select === 'wheat'"></field-wheat>
      <field-vegetable v-if="select === 'vegetables'"></field-vegetable>
      <field-corn v-if="select === 'corn'"></field-corn>
      <field-barley v-if="select === 'barley'"></field-barley>
      <field-olive v-if="select === 'olives'"></field-olive>
      <field-vine v-if="select === 'vines'"></field-vine>
    </div>
  </div>
</template>

<script>
import FieldWheat from "@/Open/Peasant/components/FieldWheat";
import FieldVegetable from "@/Open/Peasant/components/FieldVegetable";
import FieldCorn from "@/Open/Peasant/components/FieldCorn";
import FieldBarley from "@/Open/Peasant/components/FieldBarley";
import FieldOlive from "@/Open/Peasant/components/FieldOlive";
import FieldVine from "@/Open/Peasant/components/FieldVine";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'PeasantView',
  components: {
    FieldWheat,
    FieldVegetable,
    FieldCorn,
    FieldBarley,
    FieldOlive,
    FieldVine
  },
  data() {
    return {
      select: 'wheat'
    }
  },
  mounted() {
    let hash = location.hash.substring(1);
    if('' !== hash && undefined !== hash) {
      this.select = hash;
    } else {
      location.hash = this.select;
    }
    this.$store.dispatch('peasants/tracking', {
      page: 'open-peasant',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  },
  methods: {
    change(e) {
      this.select = e.target.value;
      location.hash = this.select;
    }
  }
}
</script>
<style>
.peasant {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.peasant #change-peasant {
  float: right;
  border-radius: 10px;
  width: 210px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
</style>
