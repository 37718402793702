<template>
  <div class="content container-fluid craftspeople">
    <div class="row">
      <div>
        <select v-model="select" @change="change($event)" id="change-craft">
          <option v-for="(craftspeople, index) in craftspeoples" :key="index" :value="craftspeople.uuid">{{craftspeople.name[$i18n.locale]}}</option>
        </select>
      </div>
    </div>
    <craftspeople-production v-if="'' !== select" :craftspeople="craftspeople"></craftspeople-production>
  </div>
</template>

<script>
import CraftspeopleProduction from "@/Open/Craftspeople/components/CraftspeopleProduction";
import { mapState } from 'vuex'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: 'CraftspeopleView',
  components: {
    CraftspeopleProduction
  },
  computed: {
    ...mapState('craftspeople',{
        craftspeoples: 'craftspeoples',
      }
    )
  },
  data() {
    return {
      hash: '',
      craftspeople: {},
      select: '',
    }
  },
  mounted() {
    this.$store.dispatch('craftspeople/getCraftspeoples');
    this.$store.dispatch('craftspeople/tracking', {
      page: 'open-craftspeople',
      component: '',
      visitor: cookies.get('lbo-site-visitor')
    });
  },
  methods: {
    change(e) {
      this.craftspeople = this.getCraftspeopleByUuid(e.target.value);
      location.hash = this.craftspeople.slug;
      this.$store.dispatch('craftspeople/tracking', {
        page: 'open-craftspeople',
        component: 'craftspeople|uuid:'+this.craftspeople.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    },
    getCraftspeopleByUuid(uuid) {
      return this.$store.state.craftspeople.craftspeoples.filter(c => c.uuid === uuid)[0];
    },
    getCraftspeopleBySlug(slug) {
      return this.$store.state.craftspeople.craftspeoples.filter(c => c.slug === slug)[0];
    },
  },
  watch: {
    craftspeoples() {
      let hash = location.hash.substring(1);
      if('' !== hash && undefined !== hash) {
        this.craftspeople = this.getCraftspeopleBySlug(hash);
        this.select = this.craftspeople.uuid;
      } else {
        this.craftspeople = this.getCraftspeopleByUuid(this.craftspeoples[0].uuid);
        this.select = this.craftspeople.uuid;
        location.hash = this.craftspeople.slug;
      }
      this.$store.dispatch('craftspeople/tracking', {
        page: 'open-craftspeople',
        component: 'craftspeople|uuid:'+this.craftspeople.uuid,
        visitor: cookies.get('lbo-site-visitor')
      });
    }
  }
}
</script>
<style>
.craftspeople {
  width: 90%!important;
  min-width: 900px;
  margin-top: 30px;
}
.craftspeople #change-craft {
  float: right;
  border-radius: 10px;
  width: 150px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
</style>
