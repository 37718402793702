import api from "@/System/store/api";
import apiUser from "@/System/store/apiUser";

const actions = {
  getAuthors:({commit}) => {
    apiUser.get('/close-api/university/authors')
      .then(function (response) {
        commit('setAuthors', response.data);
      })
  },
  getBooks: ({commit}) => {
    apiUser.get('/close-api/university/books')
      .then(function (response) {
        commit('setBooks', response.data);
      })
  },
  getCategories: ({commit}) => {
    apiUser.get('/close-api/university/categories')
      .then(function (response) {
        commit('setCategories', response.data);
      })
  },
  getLanguages: ({commit}) => {
    apiUser.get('/close-api/university/languages')
      .then(function (response) {
        commit('setLanguages', response.data);
      })
  },
  getLessons: ({commit}) => {
    apiUser.get('/close-api/university/lessons/')
      .then(function (response) {
        commit('setLessons', response.data);
      })
  },
  getWays: ({commit}) => {
    apiUser.get('/close-api/university/ways')
      .then(function (response) {
        commit('setWays', response.data);
      })
  },
  /* POST */
  addBook: ({commit}, payload) => {
    apiUser.post('/close-api/university/books', payload)
      .then(function () {
        apiUser.get('/close-api/university/books')
          .then(function (response) {
            commit('setBooks', response.data);
          });
      });
  },
  addCategory: ({commit}, payload) => {
    apiUser.post('/close-api/university/categories', payload)
      .then(function () {
        apiUser.get('/close-api/university/categories')
          .then(function (response) {
            commit('setCategories', response.data);
          });
      });
  },
  addLesson: ({commit}, payload) => {
    apiUser.post('/close-api/university/lessons', payload)
      .then(function () {
        apiUser.get('/close-api/university/lessons')
          .then(function (response) {
            commit('setLessons', response.data);
          });
      });
  },
  addWay: ({commit}, payload) => {
    apiUser.post('/close-api/university/ways', payload)
      .then(function () {
        apiUser.get('/close-api/university/ways')
          .then(function (response) {
            commit('setWays', response.data);
          });
      });
  },
  /* PUT */
  editBook: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      name: datas.name,
      author: datas.author,
      language: datas.language,
      prerequisite: datas.prerequisite,
      utility: datas.utility,
      provinces: datas.provinces,
    };

    apiUser.put('/close-api/university/book/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/university/books')
          .then(function (response) {
            commit('setBooks', response.data);
          });
      });
  },
  editCategory: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {name: datas.name};

    apiUser.put('/close-api/university/category/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/university/categories')
          .then(function (response) {
            commit('setCategories', response.data);
          });
      });
  },
  editLesson: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      name: datas.name,
      way: datas.way,
      prerequisite: datas.prerequisite,
    };

    apiUser.put('/close-api/university/lesson/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/university/lessons')
          .then(function (response) {
            commit('setLessons', response.data);
          });
      });
  },
  editWay: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      name: datas.name,
      category: datas.category,
    };

    apiUser.put('/close-api/university/way/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/university/ways')
          .then(function (response) {
            commit('setWays', response.data);
          });
      });
  },
  /* DELETE */
  deleteBook: ({commit}, datas) => {
    apiUser.delete('/close-api/university/book/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/university/books')
          .then(function (response) {
            commit('setBooks', response.data);
          });
      });
  },
  deleteCategory: ({commit}, datas) => {
    apiUser.delete('/close-api/university/category/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/university/categories')
          .then(function (response) {
            commit('setCategories', response.data);
          });
      });
  },
  deleteLesson: ({commit}, datas) => {
    apiUser.delete('/close-api/university/lesson/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/university/lessons')
          .then(function (response) {
            commit('setLessons', response.data);
          });
      });
  },
  deleteWay: ({commit}, datas) => {
    apiUser.delete('/close-api/university/way/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/university/ways')
          .then(function (response) {
            commit('setWays', response.data);
          });
      });
  },
  /* AUTRES */
  getProvinces: ({commit}) => {
    api.get('/api/kingdoms/provinces')
      .then(function (response) {
        commit('setProvinces', response.data);
      })
  },
  setOrder: ({commit}, datas) => {
    let object = datas.object;
    let payload = {
      uuids: datas.uuids
    };

    apiUser.put('/close-api/university/order/'+object, payload)
      .then(function () {
        switch (object) {
          case 'categories':
            apiUser.get('/close-api/university/categories')
              .then(function (response) {
                commit('setCategories', response.data);
              });
            break;
          case 'ways':
            apiUser.get('/close-api/university/ways')
              .then(function (response) {
                commit('setWays', response.data);
              });
            break;
          case 'lessons':
            apiUser.get('/close-api/university/lessons')
              .then(function (response) {
                commit('setLessons', response.data);
              });
            break;
          case 'books':
            apiUser.get('/close-api/university/books')
              .then(function (response) {
                commit('setBooks', response.data);
              });
            break;
        }
      });
  },
}

export default actions;
