import apiUser from "@/System/store/apiUser";

const actions = {
  getActualities:({commit}) => {
    apiUser.get('/close-api/actualities')
      .then(function (response) {
        commit('setActualities', response.data);
      })
  },
  addActuality: ({commit}, payload) => {
    apiUser.post('/close-api/actualities', payload)
      .then(function () {
        apiUser.get('/close-api/actualities')
          .then(function (response) {
            commit('setActualities', response.data);
          });
      });
  },
  editActuality: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      title: datas.title,
      content: datas.content
    };

    apiUser.put('/close-api/actuality/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/actualities')
          .then(function (response) {
            commit('setActualities', response.data);
          });
      });
  },
  deleteActuality: ({commit}, datas) => {
    apiUser.delete('/close-api/actuality/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/actualities')
          .then(function (response) {
            commit('setActualities', response.data);
          });
      });
  },
  publishActuality: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/actuality/'+uuid+'/publish', payload)
      .then(function () {
        apiUser.get('/close-api/actualities')
          .then(function (response) {
            commit('setActualities', response.data);
          });
      });
  },
  newPublishActuality: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/actuality/'+uuid+'/new-publish', payload)
      .then(function () {
        apiUser.get('/close-api/actualities')
          .then(function (response) {
            commit('setActualities', response.data);
          });
      });
  },

  getDeploys:({commit}) => {
    apiUser.get('/close-api/deploys')
      .then(function (response) {
        commit('setDeploys', response.data);
      })
  },
  addDeploy: ({commit}, payload) => {
    apiUser.post('/close-api/deploys', payload)
      .then(function () {
        apiUser.get('/close-api/deploys')
          .then(function (response) {
            commit('setDeploys', response.data);
          });
      });
  },
  editDeploy: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      title: datas.title,
      content: datas.content
    };

    apiUser.put('/close-api/deploy/'+uuid, payload)
      .then(function () {
        apiUser.get('/close-api/deploys')
          .then(function (response) {
            commit('setDeploys', response.data);
          });
      });
  },
  deleteDeploy: ({commit}, datas) => {
    apiUser.delete('/close-api/deploy/'+datas.uuid)
      .then(function () {
        apiUser.get('/close-api/deploys')
          .then(function (response) {
            commit('setDeploys', response.data);
          });
      });
  },
  publishDeploy: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/deploy/'+uuid+'/publish', payload)
      .then(function () {
        apiUser.get('/close-api/deploys')
          .then(function (response) {
            commit('setDeploys', response.data);
          });
      });
  },
  newPublishDeploy: ({commit}, datas) => {
    let uuid = datas.uuid;
    let payload = {
      state: datas.state
    };

    apiUser.put('/close-api/deploy/'+uuid+'/new-publish', payload)
      .then(function () {
        apiUser.get('/close-api/deploys')
          .then(function (response) {
            commit('setDeploys', response.data);
          });
      });
  },
}

export default actions;
