<template>
  <form class="product-form">
    <div v-if="product.user" class="alert alert-info text-center">
      Cette production a été traitée par : {{product.user.login}}
    </div>
    <div class="row">
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Nom</span>
          <input type="text" v-model="name" class="form-control" id="name">
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Nom pluriel</span>
          <input type="text" v-model="plural" class="form-control" id="name">
        </div>
      </div>
      <div class="col-4">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Slug</span>
          <input type="text" v-model="slug" class="form-control" id="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Prix min</span>
          <input type="number" v-model="priceMin" class="form-control" id="name">
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Prix max</span>
          <input type="number" v-model="priceMax" class="form-control" id="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Category</span>
          <select id="domain" v-model="category" class="form-control">
            <option></option>
            <option v-for="(category, index) in categories" :key="index" :value="category.uuid">{{ category.name[$i18n.locale]}}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Encombrement</span>
          <input type="number" v-model="weight" class="form-control" id="name">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Icone</span>
          <input type="text" v-model="icon" class="form-control" id="name">
        </div>
      </div>
      <div class="col-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-4">Image</span>
          <input type="text" v-model="image" class="form-control" id="name">
        </div>
      </div>
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-4">Wiki</span>
      <input type="text" v-model="wiki" class="form-control" id="name">
    </div>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text col-4">Description</span>
      <input type="text" v-model="description" class="form-control" id="name">
    </div>
  </form>
  <div class="product-form modal-footer">
    <button type="button" class="save" @click="save()" data-bs-dismiss="modal">Save changes</button>
    <button type="button" class="cancel" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'ProductForm',
  props: {
    product: Object,
    mode: String,
    searchName: {
      type: Object,
      nullable: true
    },
    searchCategory: {
      type: Object,
      nullable: true
    },
    searchCraftspeople: {
      type: Object,
      nullable: true
    }
  },
  data() {
    return {
      name: null,
      plural: null,
      slug: null,
      priceMin: null,
      priceMax: null,
      category: null,
      weight: null,
      icon: null,
      image: null,
      wiki: null,
      description: null,
      benefice: null,
    }
  },
  computed: {
    ...mapState('adminProducts', {categories: 'categories'})
  },
  mounted() {
    this.$store.dispatch('adminProducts/getCategories');
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.name = undefined !== this.product.name ? this.product.name[this.$i18n.locale] : null;
      this.plural = undefined !== this.product.plural ? this.product.plural[this.$i18n.locale] : null;
      this.slug = undefined !== this.product.slug ? this.product.slug : null;
      this.priceMin = undefined !== this.product.priceMin ? this.product.priceMin : null;
      this.priceMax = undefined !== this.product.priceMax ? this.product.priceMax : null;
      this.category = undefined !== this.product.category ? this.product.category.uuid : null;
      this.weight = undefined !== this.product.weight ? this.product.weight : null;
      this.icon = undefined !== this.product.icon ? this.product.icon : null;
      this.image = undefined !== this.product.image ? this.product.image : null;
      this.wiki = undefined !== this.product.wiki ? this.product.wiki?.[this.$i18n.locale] : null;
      this.description = undefined !== this.product.description ? this.product.description?.[this.$i18n.locale] : null;
    },
    save() {
      if('add' === this.mode) {
        this.$store.dispatch('adminProducts/addProduct', {
          name: this.name,
          plural: this.plural,
          slug: this.slug,
          category: this.category,
          priceMin: this.priceMin,
          priceMax: this.priceMax,
          weight: this.weight,
          icon: this.icon,
          image: this.image,
          wiki: this.wiki,
          description: this.description
        });
      } else {
        this.$store.dispatch('adminProducts/editProduct', {
          uuid: this.product.uuid,
          name: this.name,
          plural: this.plural,
          slug: this.slug,
          category: this.category,
          priceMin: this.priceMin,
          priceMax: this.priceMax,
          weight: this.weight,
          icon: this.icon,
          image: this.image,
          wiki: this.wiki,
          description: this.description,
          searchName: this.searchName,
          searchCategory: this.searchCategory,
          searchCraftspeople: this.searchCraftspeople
        });
      }
    }
  },
  watch: {
    product() {
      this.getDatas();
    }
  },
}
</script>
<style>
.product-form.modal-footer button.save {
  width: 90px;
  height: 24px;
  text-transform: uppercase;
  background-color: #4091c9;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
}
.product-form.modal-footer button.cancel {
  background-color: #808080;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 90px;
  height: 24px;
  font-weight: 500;
}
</style>
